import Logo from "../Assets/Logo.png"
import staticdata from "../Assets/Data"
import NavButton from "../Utils/SignButton"
import {useNavigate} from "react-router-dom"
import { useContext } from "react"
import UserContext from "./UserContext"
import { useSelector } from "react-redux"
import Hamburger from "../Assets/Hamburger.png"
import navup from "../Assets/navup.png"
import navdown from "../Assets/navdown.png"
import MenuListComposition from "./MenuBar"
import Login from "./Home/Login"
import SignUp from "./Home/SignUp"
import VerifyOTPSent from "./Home/VerifyOTP"
const NavbarAll=({booknowref})=>
{
    const navigate=useNavigate()
    const{openlogin,setopenlogin}=useContext(UserContext)
    const storedata=useSelector((storedata)=>storedata)
    const name=storedata?.userReducer?.userData?.name
    
    return(
        <div style={{fontFamily:"Poppins"}} className='flex justify-between items-center bg-[#0F0E0A] lg:pl-[100px] sm:pl-[30px] lg:pr-[50px] sm:pr-[30px] py-[20px]'>
            
            <div className="w-[40px] h-[40px] cursor-pointer">
                <img className="" src={Logo} onClick={()=>navigate("/")} />
            </div>
            <div className="sm:hidden lg:flex justify-center items-center">
                <MenuListComposition /> 
                {/* <div id="activeeffect" className="flex items-center gap-[16px] mr-[44px] cursor-pointer">
                    
                    <NavButton  disabled={name?true:false} onClick={()=>{
                        setopenlogin(!openlogin)
                        navigate("/login")
                    }} name={name?`Hi ${name}`:"LOGIN/SIGNUP"} classname={`transition ease-in-out delay-150 hover:bg-[#C69130] duration-300 text-white text-[16px] font-[600] leading-[20px] p-[10px]`} />
                    {name?
                        <img className="" src={navdown}/>:<></>
                    }
                </div> */}
                {/* <NavButton onClick={()=>booknowref.current.scrollIntoView({ behavior: 'smooth'})} id="activeeffect" name="BOOK NOW" classname="transition ease-in-out delay-150 hover:bg-[#C69130] border-2 border-white text-white px-[22px] py-[11px] text-[13px] font-[600] leading-[22px] tracking-[2px] ml-[22px]" /> */}
            </div>
            <div className="sm:flex lg:hidden justify-center items-center">
                {/* <NavButton id="activeeffect" disabled={name?true:false} onClick={()=>{
                    setopenlogin(!openlogin)
                    navigate("/login")
                }} name={name?`Hi ${name}`:"LOGIN"} classname={`transition ease-in-out delay-150 hover:bg-[#C69130] duration-300 text-white text-[14px] font-[600] leading-[20px] mr-[44px] p-[10px]`} /> */}
                <MenuListComposition />
                {/* <div className="cursor-pointer">
                    <img id="activeeffect" src={Hamburger} />
                </div> */}
                {/* <NavButton onClick={()=>booknowref.current.scrollIntoView({ behavior: 'smooth'})} id="activeeffect" name="BOOK NOW" classname="transition ease-in-out delay-150 hover:bg-[#C69130] border-2 border-white text-white px-[22px] py-[11px] text-[13px] font-[600] leading-[22px] tracking-[2px] ml-[22px]" /> */}
            </div>
            
        </div>
        
    )
}
export default NavbarAll