import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DropDownArrow from '../Assets/DropDownArrow.svg';
import Copy from "../Assets/Copy.svg"
import { GetAllCoupons, ValidateCoupon } from '../Redux/Actions/api';
import { toast } from 'react-toastify';
import Loading from './Loading';
import { CircularProgress } from '@mui/material';

const CouponCard = ({ title, font, discount, setdiscount, coupon, setCoupon, couponType, setCouponType }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [coupondata, setallcoupondata] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [openModals, setOpenModals] = useState([]);
  const [copiedMessages, setCopiedMessages] = useState([]);
  const [tapapply, settapapply] = useState([]);
  const [couponload, setcouponload] = useState([]);
  const [couponcode, setcouponcode] = useState("");
  const [successmessage, setsuccessmessage] = useState("");
  let booking_detail = JSON.parse(localStorage.getItem("booking_detail"));

  useEffect(() => {
    localStorage.setItem('discount', 0);
    localStorage.setItem('coupon', "");
    localStorage.setItem('couponType', "");
    GetAllCoupons(booking_detail?.spv_id)
      .then((res) => {
        setisloading(false);
        if (res?.coupons?.length) {
          setallcoupondata(res.coupons);
          setOpenModals(new Array(res.coupons.length).fill(false));
          setCopiedMessages(new Array(res.coupons.length).fill(false));
          settapapply(new Array(res.coupons.length).fill(""));
          setcouponload(new Array(res.coupons.length).fill(false));
        } else {
        
        }
      })
      .catch((err) => {
        setisloading(false);
        toast.error("Something went wrong");
      });
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      setCopiedMessages(Array(coupondata?.length).fill(false));
      setsuccessmessage("");
    }, 5000);
    return () => clearTimeout(id);
  }, [copiedMessages, successmessage, coupondata?.length]);

  const openModal = (index) => {
    const newOpenModals = [...openModals];
    newOpenModals[index] = true;
    setOpenModals(newOpenModals);
  };

  const closeModal = (index) => {
    const newOpenModals = [...openModals];
    newOpenModals[index] = false;
    setOpenModals(newOpenModals);
  };

  const toggleModal = (index) => {
    const newOpenModals = [...openModals];
    newOpenModals[index] = !newOpenModals[index];
    setOpenModals(newOpenModals);
  };

  const toggleCopiedMessage = (index) => {
    const newCopiedMessages = [...copiedMessages];
    newCopiedMessages[index] = !newCopiedMessages[index];
    setCopiedMessages(newCopiedMessages);
  };

  const toggletapMessage = (index) => {
    const newCopiedMessages = [...tapapply];
    newCopiedMessages[index] = !newCopiedMessages[index];
    settapapply(newCopiedMessages);
  };

  const handleSubmit = async (values, { setErrors }) => {
    try {
      const response = await ValidateCoupon(values?.couponCode, booking_detail?.spv_id);
      console.log(response);

      if (response?.valid) {
        setdiscount(response?.coupon_value);
        localStorage.setItem('discount', response?.coupon_value);
        setCoupon(response?.coupon_code);
        localStorage.setItem('coupon', response?.coupon_code);
        setCouponType(response?.coupon_type);
        localStorage.setItem('couponType', response?.coupon_type);
        setsuccessmessage("Coupon applied successfully");
      } else {
        setErrors({
          couponCode: response?.error || 'Invalid coupon',
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Invalid Coupon');
    }
  };

  const validatecouponfunc = async (couponcode, index) => {
    try {
      const load = [...couponload];
      load[index] = true;
      setcouponload(load);
      const response = await ValidateCoupon(couponcode, booking_detail?.spv_id);

      if (response?.valid) {
        const load = [...couponload];
        load[index] = false;
        setcouponload(load);
        setdiscount(response?.coupon_value);
        localStorage.setItem('discount', response?.coupon_value);
        setCoupon(response?.coupon_code);
        localStorage.setItem('coupon', response?.coupon_code);
        setCouponType(response?.coupon_type);
        localStorage.setItem('couponType', response?.coupon_type);
        const data = [...tapapply];
        data[index] = "Success";
        settapapply(data);
      } else {
        const load = [...couponload];
        load[index] = false;
        setcouponload(load);
        const data = [...tapapply];
        data[index] = "Invalid";
        settapapply(data);
      }
    } catch (error) {
      const load = [...couponload];
      load[index] = false;
      setcouponload(load);
      console.error(error);
      toast.error('Invalid Coupon');
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopied(true);
        setcouponcode(text);

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        console.error('Unable to copy to clipboard', error);
      });
  };

  return (
    isloading ? <CircularProgress /> : (
      <div>
        <div style={{ fontFamily: 'Poppins' }} className="flex justify-between sm:text-[14px] lg:text-[16px] leading-[19px] tracking-[1.5px] mb-[12px]">
          <p className={`text-[#0C0C0C] font-[${font}]`}>{title}</p>
        </div>
        <Formik initialValues={{ couponCode: '' }} onSubmit={handleSubmit}>
          <Form style={{ fontFamily: 'Poppins' }} className="sm:text-[14px] lg:text-[16px] leading-[20px] tracking-[3%] font-[400] flex flex-col gap-[10px]">
            <div>
              <div className="flex justify-between bg-[#CACACA] px-[10px] py-[8px] w-full mt-[7px] rounded-[6px]">
                <Field type="text" name="couponCode" className="bg-[#CACACA] p-2" placeholder="Type Coupon Code" required />
                <button type="submit" className="p-2 hover:bg-[#5A5A5A] hover:text-white">Apply</button>
              </div>
              <ErrorMessage className='text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]' name='couponCode' component="div" />
              <div className='text-green-600'>{successmessage}</div>
            </div>
          </Form>
        </Formik>

        {/* Check if there are coupons available */}
        {coupondata.length ? (
          <div>
            <div className="pt-[10px] text-[#0C0C0C]" style={{ fontFamily: 'Poppins' }}>
              <p className='font-[500]'>Best Offers For You</p>
            </div>
            <div>
              {coupondata.map((e, i) => (
                <div className='' key={i}>
                  <div className="mt-[12px] border rounded-xl shadow-lg">
                    <div className="flex px-[12px] py-[6px] gap-[12px] w-full">
                      <div className='w-full'>
                        <div className="font-[600] w-[100%]">{e?.coupon_name}</div>
                        <div className="text-[12px] mt-[3px]">
                          <p>{e?.description}</p>
                          <div className="w-[100%] flex justify-between text-[12px] mt-[2%] mb-[2%]">
                            <div className='flex'>
                              <button className="border-[0.5px] border-[#C69131] rounded-[6px] font-[500] px-[6px] py-[3px]" onClick={() => {
                                copyToClipboard(e?.coupon_code);
                                toggleCopiedMessage(i);
                              }}>
                                {e?.coupon_code}
                              </button>
                              <p className='text-[8px]'>{copiedMessages[i] ? 'Copied!' : 'Tap To Copy'}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button className="text-[12px] font-[500] text-center px-[8px] py-[6px] border rounded-[6px] border-[#C69131]" onClick={() => validatecouponfunc(e?.coupon_code, i)}>
                          {couponload[i] ? <CircularProgress size={18} /> : 'Apply'}
                        </button>
                        <div className={`text-center ${tapapply[i] === "Success" ? "text-green-600" : "text-red-600"} text-[12px] font-[500]`}>
                          {tapapply[i] && tapapply[i] === "Success" ? "Applied" : tapapply[i] === "Invalid" ? "Invalid Coupon" : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>No coupons available at the moment.</div>
        )}
      </div>
    )
  );
};

export default CouponCard;
