import { SET_SESSIONID, SET_TOKEN, SET_USER } from "../Constants"

export const SetSessionIdAction=(data)=>
{
    return{
        type:SET_SESSIONID,
        payload:data
    }
}
export const SetUserDataAction=(data)=>
{
    return{
        type:SET_USER,
        payload:data
    }
}
export const SetUserTokenAction=(data)=>
{
    return{
        type:SET_TOKEN,
        payload:data
    }
}