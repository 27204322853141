import Label from "../../Utils/Label"
import NormalText from "../../Utils/NormalText"
import PropCard from "../../Utils/PropCard"
import Dummy from "../../Assets/Dummy.png"
import {useEffect, useState, useRef} from "react"
import {useDispatch, useSelector} from "react-redux"
import { GetAllProperties } from "../../Redux/Actions/api"
import { GetAllPropertiesAction } from "../../Redux/Actions/PropertyAction"
import CircularProgress from '@mui/material/CircularProgress';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { toast } from "react-toastify"
import Loading from "../../Utils/Loading"
import PropCardMain from "../../Utils/PropCardMain"
import ScrollRight from "../../Assets/ScrollRight.png"
import ScrollLeft from "../../Assets/ScrollLeft.png"
import LeftScroll from "../../Assets/leftImage.png"
import RightScroll from "../../Assets/rightImage.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from "@mui/material"

const Properties=({propdetailnavbar,...props})=>
{
    const[propdata,setpropdata]=useState([])
    const storedata=useSelector((storedata)=>storedata)
    const[isloading,setisloading]=useState(true)
    const dispatch=useDispatch()
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    // const swiper = useSwiper();

    useEffect(()=>
    {
        if(storedata?.propertyreducer?.allproperties?.length!=0)
        {
            setpropdata(storedata?.propertyreducer?.allproperties)
            setisloading(false)
        }
        else
        {
            GetAllProperties().then((res)=>
            {
                console.log(res)
                setpropdata(res)
                dispatch(GetAllPropertiesAction(res))
                setisloading(false)
            }).catch((err)=>
            {
                setisloading(false)
                toast.error("Something went wrong")
            })
        }
    },[])

    function NextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div className={className} style={{ ...style, display: 'block', position: 'absolute', right: '-15px'}} onClick={onClick}>
          <img src={ScrollRight} style={{ width: "40px", height: "40px", maxWidth: 'none' }} alt="Previous" />
        </div>
      );
    };
 
    function PrevArrow(props) {
      const { className, style, onClick } = props;
return (
  <div className={className} style={{ ...style, display: 'block', position: 'absolute', left: '-35px', zIndex: 1}} onClick={onClick}>
    <img src={ScrollLeft} style={{ width: "40px", height: "40px", maxWidth: 'none'}}/>
  </div>
);
};
    
var settings = {
  dots: true,
 infinite: true,
 autoplay: true,
 // swipeToSlide: true,
 centerPadding: "15px",           
 speed: 500,
 autoplaySpeed: 8000,
 adaptiveHeight: true,
 slidesToShow: 4,
 slidesToScroll: 1,
 
responsive: [
{
 breakpoint: 3000,
 settings: {
   slidesToShow: 4,
   slidesToScroll: 1,
   infinite: true,
   dots: true,
   prevArrow: <PrevArrow/>,
   nextArrow: <NextArrow/>,
 draggable: false,

 }
},
{
breakpoint: 1330,
settings: {
 slidesToShow: 3,
 slidesToScroll: 1,
 infinite: true,
 dots: true,
 prevArrow: <PrevArrow/>,
 nextArrow: <NextArrow/>,
 draggable: false,
}
},
{
breakpoint: 900,
settings: {
 slidesToShow: 2,
 slidesToScroll: 1,
 swipeToSlide: true,
 dots: true,
}
},
{
breakpoint: 540,
settings: {
 slidesToShow: 1,
 slidesToScroll: 1,
 swipeToSlide: true,
}
}
]          
};
const [dotColor, setDotColor] = useState('');
  const [activeDotColor, setActiveDotColor] = useState('#C69130');
    
    return(
        <div style={{fontFamily:"Poppins"}} className="bg-white sm:px-[16px] lg:px-[80px] sm:pt-[57px] lg:py-[80px]">
            {/* <div className="bg-white absolute w-[50%] h-[95%] left-[5%] top-[4%]">
            </div> */}
            <Label text="Our Properties" />
            <NormalText className="mt-[25px] sm:px-[16px] lg:px-[180px] z-10" text="Our properties are located in a Holiday destinations where nature meets luxury, peace & adventure."></NormalText>
            <p className="sm:text-[14px] lg:text-[18px] font-[600] sm:leading-[20px] lg:leading-[35px] text-center text-[#656565] z-10">A True sign to luxurious living environment</p>
            {
                isloading?
                <Loading />
                :
                //     <>
                //         <div className="sm:hidden 2xl:block xl:hidden md:hidden w-full h-full z-10">
                //             {
                //                 propdata?.length<3?
                //                 <div className="flex px-[80px] gap-[40px] justify-around">
                //                     {
                //                         propdata?.map((e,i)=>
                //                         {
                //                             let {property_name,property_area,brik_cover_image,brik_id,spv_id,bedroom,accomadation,per_night_inr,property_view,location}=e?.brik_data
                //                             return(
                //                                 <PropCardMain key={i} id={brik_id} spvid={spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location ={location}/>
                //                             )
                //                         })
                //                         }
                //                         </div>
                //                 :
                //                <div style={{width:"100%"}}>
                //                  <Swiper                
                //         modules={[Pagination, Navigation, Autoplay]}
                //         className="myswiper"
                //         direction='horizontal'
                //         style={{width:"90%"}}
                //         navigation={{
                //             prevEl: navigationPrevRef.current,
                //             nextEl: navigationNextRef.current,

                //         }}
                //         onBeforeInit={(swiper) => {
                //             swiper.params.navigation.prevEl = navigationPrevRef.current;
                //             swiper.params.navigation.nextEl = navigationNextRef.current;
                //         }}
                //         pagination= {{
                //             el: '.swiper-custom-pagination',
                //             clickable:true
                //         }}            
                        
                //         slidesPerView="4"
                //         slidesPerGroup="4"
                //         spaceBetween={15}
                //         loop={true}
                //         autoplay={{
                //             delay: 8000,
                //             disableOnInteraction: false,
                //         }}>
                //         <div className="w-full h-full">
                //         {
                //             propdata?.map((e,i)=>
                //             {
                //                 let {property_name,property_area,brik_cover_image,brik_id,spv_id,bedroom,accomadation,per_night_inr,property_view,location}=e?.brik_data
                //                 return(
                //                     <SwiperSlide className='' key={i}>
                //                         <PropCardMain key={i} id={brik_id} spvid={spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location={location}/>
                //                     </SwiperSlide>
                //                 )
                //             })
                //         }
                //         </div>
                //         <div className='w-[100%] absolute top-[50%] z-20 flex justify-between'>
                //            <div id='activeeffect' className='opacity-80' ref={navigationPrevRef}><img src={ScrollLeft} /></div>
                //            <div id='activeeffect' className='opacity-80' ref={navigationNextRef}><img src={ScrollRight} /></div>
                // </div>
                //             <div className="swiper-custom-pagination"/>
                //         </Swiper>
                //         <div style={{backgroundColor:"red"}} className='absolute top-[50%] left-0' ref={navigationPrevRef}>
                //                 <img src={LeftScroll} alt="Left Scroll" />
                //             </div>
                            
                //             <div style={{backgroundColor:"red"}} className='absolute top-[50%] right-0' ref={navigationNextRef}>
                //                 <img src={RightScroll} alt="Right Scroll" />
                //             </div>
                //                </div>
                //         }
                //     </div>
                //     <div className="sm:hidden 2xl:hidden xl:block md:hidden w-full h-full z-10">
                //             {
                //                <div style={{width:"100%"}}>
                //                  <Swiper                
                //         modules={[Pagination, Navigation, Autoplay]}
                //         className="myswiper"
                //         direction='horizontal'
                //         style={{width:"90%"}}
                //         navigation={{
                //             prevEl: navigationPrevRef.current,
                //             nextEl: navigationNextRef.current,

                //         }}
                //         onBeforeInit={(swiper) => {
                //             swiper.params.navigation.prevEl = navigationPrevRef.current;
                //             swiper.params.navigation.nextEl = navigationNextRef.current;
                //         }}
                //         pagination= {{
                //             el: '.swiper-custom-pagination',
                //             clickable:true
                //         }}            
                //         onReachEnd={(swiper) => {
                //             const lastIndex = propdata.length - 1;
                //             const activeIndex = swiper.realIndex;
                //             if (activeIndex === lastIndex) {
                //               swiper.slideTo(0);
                //             }
                //         }}
                //         slidesPerView="3"
                //         slidesPerGroup="3"
                //         spaceBetween={15}
                //         // loop={true}
                //         autoplay={{
                //             delay: 8000,
                //             disableOnInteraction: false,
                //         }}>
                //         <div className="w-full h-full">
                //         {
                //             propdata?.map((e,i)=>
                //             {
                //                 let {property_name,property_area,brik_cover_image,brik_id,spv_id,bedroom,accomadation,per_night_inr,property_view,location}=e?.brik_data
                //                 return(
                //                     <SwiperSlide className='' key={i}>
                //                         <PropCardMain key={i} id={brik_id} spvid={spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location={location}/>
                //                     </SwiperSlide>
                //                 )
                //             })
                //         }
                //         </div>
                //         <div className='w-[100%] absolute top-[50%] z-20 flex justify-between'>
                //            <div id='activeeffect' className='opacity-80' ref={navigationPrevRef}><img src={ScrollLeft} /></div>
                //            <div id='activeeffect' className='opacity-80' ref={navigationNextRef}><img src={ScrollRight} /></div>
                // </div>
                //             <div className="swiper-custom-pagination"/>
                //         </Swiper>
                //                </div>
                //         }
                //     </div>
                //     {/* <div className="sm:hidden md:block xl:hidden w-full h-full z-10 px-[40px]">
                //         <Swiper                
                //         modules={[Pagination, Navigation, Autoplay]}
                //         className="myswiper"
                //         direction='horizontal'
                //         pagination= {{
                //             el: '.swiper-custom-pagination',
                //             clickable:true
                //         }}            
                //         slidesPerView="2"
                //         spaceBetween={15}
                //         loop="true"
                //         autoplay={{
                //             delay: 8000,
                //             disableOnInteraction: false,
                //         }}>
                //         <div className="w-full h-full">
                //         {
                //             propdata?.map((e,i)=>
                //             {
                //                 let {property_name,property_area,brik_cover_image,brik_id,spv_id,bedroom,accomadation,per_night_inr,property_view,location}=e?.brik_data
                //                  return(
                //                     <SwiperSlide className='' key={i}>
                //                         <PropCardMain key={i} id={brik_id} spvid={spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location= {location} />
                //                     </SwiperSlide>
                //                 )
                //             })
                //         }
                //         </div>
                //         <div className="swiper-custom-pagination"/>
                //         </Swiper>
                //     </div> */}
                //     {/* <div className="sm:block md:hidden w-full h-full z-10 px-[16px]">
                //         <Swiper                
                //         modules={[Pagination, Navigation, Autoplay]}
                //         className="myswiper"
                //         direction='horizontal'
                //         pagination= {{
                //             el: '.swiper-custom-pagination',
                //             clickable:true
                //         }}            
                //         slidesPerView="1"
                //         spaceBetween={15}
                //         loop="true"
                //         autoplay={{
                //             delay: 8000,
                //             disableOnInteraction: false,
                //         }}>
                //         <div className="w-full h-full">
                //         {
                //             propdata?.map((e,i)=>
                //             {
                //                 let {property_name,property_area,brik_cover_image,brik_id,spv_id,bedroom,accomadation,per_night_inr,property_view,location}=e?.brik_data
                //                 return(
                //                     <SwiperSlide className='' key={i}>
                //                         <PropCardMain key={i} id={brik_id} spvid={spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location= {location}/>
                //                     </SwiperSlide>
                //                    )
                //             })
                //         }
                //         </div>
                //         <div className="swiper-custom-pagination"/>
                //         </Swiper>
                //     </div> */}

                //     </>
                
      <div className="mt-[20px] pb-[20px]">
        
          <Slider {...settings}>
         
            { 
            propdata?.map((e, i) => {
              let {property_name, property_area, brik_cover_image, bedroom, accomadation, per_night_inr, property_view, location} = e?.brik_data;
              return (
                <div key={i} className="pl-[7.5px] pr-[7.5px]">
                  <PropCardMain key={i} id={e?.brik_data?.brik_id} spvid={e?.brik_data?.spv_id} bed={`${bedroom} Bedrooms`} rating={e.average_review} url={brik_cover_image} price={per_night_inr} guests={`${accomadation?.[0]} Guests`} area={`${property_area} sqft.`} view={`${property_view}`} title={property_name} location={location}/>
                </div>
              );
            })}             
          </Slider> 
          <style>
        {`
        .slick-dots {
          display: flex !important;
          list-style: none;
          justify-content: center; 
        }
          .slick-dots li button:before {
            font-size: 10px;
          }
          .slick-dots li.slick-active button:before {
            color: ${activeDotColor};
            font-size: 10px;
          }
          .slick-dots li button:hover:before{
            color: ${activeDotColor};
          }
          .slick-dots {
            text-align: center;
          }
          .slick-dots li {
              display: none;
            margin: 0 5px;
          }
          .slick-dots li.slick-active,
          .slick-dots li.slick-active + li,
          .slick-dots li.slick-active + li + li {
              display: inline-block;
          }
          
          .slick-dots li:nth-last-child(1),
          .slick-dots li:nth-last-child(2),
          .slick-dots li:nth-last-child(3) {
              display: inline-block;
          }
          
          .slick-dots li.slick-active ~ li:nth-last-child(1),
          .slick-dots li.slick-active ~ li:nth-last-child(2),
          .slick-dots li.slick-active ~ li:nth-last-child(3) {
              display: none;
          }
          .slick-dots li.slick-active + li + li:nth-last-child(3),
          .slick-dots li.slick-active + li + li:nth-last-child(2),
          .slick-dots li.slick-active + li + li:nth-last-child(1),
          .slick-dots li.slick-active + li:nth-last-child(3),
          .slick-dots li.slick-active + li:nth-last-child(2),
          .slick-dots li.slick-active + li:nth-last-child(1){
              display: inline-block;
          }
        `}
      </style>
        </div>
            }
        </div>
    )
}
export default Properties