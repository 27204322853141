
import Down from "../Assets/Down.svg"
import { useContext, useEffect, useState } from 'react';
import dateFormat from "dateformat"
// import DatePicker from "react-datepicker"
// import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { DateObject } from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"
import UserContext from "../Screens/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { Box, Fade, Modal } from "@mui/material";
import { CalendarModalAction, CountModalAction } from "../Redux/Actions/ModalAction";
import { addDays } from 'date-fns';

const CalendarCombined=({type})=>
{
    const UserData=useContext(UserContext)
    // console.log(UserData)
    // let currentDate = new Date();
    // let tomorrow=currentDate.setDate(currentDate.getDate() + 1);
    // let dateformatfun=(date)=>
    // {
    //     return dateFormat(date,'mmmm dd, yyyy')
    // }
    // const [date,setselectdate]=useState({"checkin":dateformatfun(new Date()),"checkout":dateformatfun(tomorrow)})
    const{date,setselectdate}=UserData
    const [value,setvalue]=useState([])
    const defaultrange={
        startDate: localStorage.getItem("datevalue")?.[0]||new Date(), // Set the default start date to today
        endDate: localStorage.getItem("datevalue")?.[1]||addDays(new Date(), 1), // Set the default end date to 7 days from today
      }
    const [display,setdisplay]=useState(false)
    const storedata=useSelector((storedata)=>storedata)
    const{calendarmodal}=storedata?.modalreducer
    const dispatch=useDispatch()
    useEffect(()=>
    {
        localStorage.setItem("datevalue",value)
        if(value.length===2)
        {
            let checkin=value[0].month.name+" "+value[0].day+", "+value[0].year
            let checkout=value[1].month.name+" "+value[1].day+", "+value[1].year
            setselectdate({checkin:checkin, checkout:checkout})
            localStorage.setItem("checkin",checkin)
            localStorage.setItem("checkout",checkout)
            dispatch(CalendarModalAction(false))
            dispatch(CountModalAction(true))
        }
    },[value])
    
    
    return(
        <>
            <div style={{fontFamily:"Poppins"}} onClick={()=>dispatch(CalendarModalAction(true))} className={type=="single"?"sm:rounded-xl lg:rounded-xl flex flex-col w-full gap-[20px] cursor-pointer relative":"sm:rounded-xl lg:rounded-xl flex justify-between w-full gap-[21px] cursor-pointer bg-white px-[25px] py-[15px] relative"}>
                    <div className={type=="single"?"bg-white px-[25px] sm:py-[8px] lg:py-[15px]":""}>
                        <p className='text-[#8F8F8F] text-16px leading-[27px] text-black font-[600] text-transform: uppercase'>Check In</p>
                        <div className='flex items-center gap-[10px] mt-[3px]'>
                            <p className='sm:block 2xl:block lg:hidden text-black text-[13px] leading-[22px] font-[500] tracking-[1.5px]'>{date.checkin}</p>
                            <p className='lg:block 2xl:hidden sm:hidden w-full text-black text-[13px] leading-[22px] font-[500] tracking-[0.8px]'>{dateFormat(date.checkin,"mmm dd, yy")}</p>
                            <div>
                                <img className='cursor-pointer' src={Down} />
                            </div>
                        </div>
                    </div>
                    <div className={type=="single"?"bg-white px-[25px] sm:py-[8px] lg:py-[15px]":""}>
                        <p className='text-[#8F8F8F] text-16px leading-[27px] text-black font-[600] text-transform: uppercase'>Check Out</p>
                        <div className='flex items-center gap-[10px] mt-[3px]'>
                            <p className='sm:block 2xl:block lg:hidden text-black text-[13px] leading-[22px] font-[500] tracking-[1.5px]'>{date.checkout}</p>
                            <p className='lg:block 2xl:hidden sm:hidden w-full text-black text-[13px] leading-[22px] font-[500] tracking-[0.8px]'>{dateFormat(date.checkout,"mmm dd, yy")}</p>
                            <div>
                                <img className='cursor-pointer' src={Down} />
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div style={{display:calendarmodal?"block":"none"}}>
                    <Modal
                    style={
                        { margin: "auto", width:"100%" ,marginTop:"5%",display: "flex",
                        justifyContent: "center",alignItems: "center"
                    }
                    }
                    onClose={() =>dispatch(CalendarModalAction(false)) }
                    open={calendarmodal}
                > 
                    <Fade in={calendarmodal}>
                        <Box>
                            <div className="sm:hidden md:block">
                                <Calendar value={value} onChange={setvalue} range rangeHover numberOfMonths={2} minDate={new Date()} maxDate={new DateObject().add(1,"year")}/>
                            </div>
                            <div className="sm:block md:hidden">
                                <Calendar value={value} onChange={setvalue} range rangeHover numberOfMonths={1} minDate={new Date()} maxDate={new DateObject().add(1,"year")}/>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
                </div>
                {/* <div style={{display:display?"block":"none",fontFamily:"Inter"}} className='absolute top-[20%] left-[25%]'>
                    <Calendar className="" value={value} onChange={setvalue} range rangeHover numberOfMonths={2} minDate={new Date()} maxDate={new DateObject().add(1,"year")}/>
                    
                </div> */}
                
        </>
    )
}
export default CalendarCombined