import { Box, Fade, Modal } from "@mui/material"
import FeedBack from "../../Assets/FeedBack.svg"
import { useEffect } from "react"
const ReviewSuccessModal=({zoomimagemodal,setzoomimagemodal})=>
{
    useEffect(()=>
    {
        const id=setTimeout(() => {
            setzoomimagemodal(false)
        }, 2000);
        return()=>clearTimeout(id)
    },[zoomimagemodal,setzoomimagemodal])
    return(
        <Modal className="modalforsuccess" style={{ margin: "auto",display:zoomimagemodal?"block":"none" }}
                    open={zoomimagemodal}
                    onClose={()=>setzoomimagemodal(false)}  
        >
            <Box sx={{height:"100%",backgroundColor:"white"}}>
                <Fade in={zoomimagemodal}>
                    <div style={{fontFamily:"Playfair Display"}} className="flex flex-col items-center justify-center sm:p-[19px] lg:p-[90px]">
                        <div className="sm:w-[50px] sm:h-[50px] lg:w-auto lg:h-auto">
                            <img src={FeedBack} />
                        </div>
                        <p className="mt-[37px] lg:text-[34px] sm:text-[20px] font-[600] text-[#0C0C0C] text-center sm:leading-[30px] lg:leading-[45px]">Your feedback has been successfully submitted</p>
                    </div>
                </Fade>
            </Box>
        </Modal>
    )
}
export default ReviewSuccessModal