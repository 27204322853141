import { MuiOtpInput } from "mui-one-time-password-input";
import bg from "../../Assets/contourbg.png";
import cal from "../../Assets/Calendar.png";
import NavButton from "../../Utils/SignButton";
import BBack from "../../Assets/BBack.png";
import Close from "../../Assets/Close.png";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../UserContext";
import { LoginUser, VerifyOTP } from "../../Redux/Actions/api";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSessionIdAction,
  SetUserDataAction,
  SetUserTokenAction,
} from "../../Redux/Actions/UserActions";
import { toast } from "react-toastify";
import { debounce } from "@mui/material";

const VerifyOTPSent = () => {
  let spanclass = "font-[600] text-[14px] leading-[24px] cursor-pointer";
  const dispatch = useDispatch();
  const phoneRegex = /^\+?[1-9][0-9]{7,14}$/;

  const navigate = useNavigate();
  const [otpvalue, setotpvalue] = useState("");
  const [edit, setedit] = useState(false);
  const { code, input, setinput, setopensignup, otpdisplay, setotpdisplay } =
    useContext(UserContext);

  const storedata = useSelector((storedata) => storedata);
  let toastId = null
  const debouncedHandleVerify = debounce((value) => {
    let session_id = storedata?.userReducer?.session_id;
    if (session_id) {
      VerifyOTP({
        otp: value,
        session_id: session_id,
        mobile_number: input,
      })
        .then((res) => {
          toast.success("You have successfully logged in");
          dispatch(SetUserDataAction(res.user));
          dispatch(SetUserTokenAction(res.token));
          localStorage.setItem("token", res.token);
          setotpvalue("");
          setotpdisplay(!otpdisplay);
          // navigate(-2)
        })
        .catch((err) => {
         
          if (toastId) {
            toast.update(toastId, { render: "Invalid OTP", type: toast.TYPE.ERROR });
        } else {
            toastId = toast.error("Invalid OTP", {
                onClose: () => (toastId = null) // Reset toastId when toast is closed
            });
        }
          setotpvalue("");
        });
    } else {
      toast.error("Session expired");
      setotpvalue("");
    }
  }, 900);

  const debouncedResendOTP = debounce(() => {
    LoginUser({ mobile_number: input })
      .then((res) => {
       if (toastId) {
          toast.update(toastId, { render: "Whatsapp OTP sent successfully", type: toast.TYPE.SUCCESS });
      } else {
          toastId = toast.success("Whatsapp OTP sent successfully", {
              onClose: () => (toastId = null) // Reset toastId when toast is closed
          });
      }
        dispatch(SetSessionIdAction(res?.session_id));
      })
      .catch((err) => {
        if (toastId) {
            toast.update(toastId, { render: "User is not registered", type: toast.TYPE.ERROR });
        } else {
            toastId = toast.error("User is not registered", {
                onClose: () => (toastId = null) // Reset toastId when toast is closed
            });
        }
      });
  }, 900);

  const handleNavigate = () => {
    setotpdisplay(false);
    setopensignup(true);
    // navigate("/signup")
  };

  const changephonenumber = () => {
    if (!edit) {
      document.querySelector("#inputfield").focus();
    }
    if (input) {
      if (phoneRegex.test(input)) {
        setedit(!edit);
      } else {
        toast.error("Please enter a valid Phone number");
      }
    } else {
      toast.error("Something went wrong. Please try again");
    }
  };

  const handleOtpChange = (value) => {
    if (value !== undefined && /^\d*$/.test(value)) {
      // Only update if the value is defined and numeric
      setotpvalue(value);
    } else if (value !== undefined) {
      // Show error if value is defined but not numeric
     if (toastId) {
        toast.update(toastId, { render: "Only numeric characters are allowed", type: toast.TYPE.ERROR });
    } else {
        toastId = toast.error("Only numeric characters are allowed", {
            onClose: () => (toastId = null) // Reset toastId when toast is closed
        });
    }

    }
  };

  return (
    <div className={otpdisplay ? "after" : "before"}>
      <div
        style={{ fontFamily: "Inter", display: otpdisplay ? "flex" : "none" }}
        className="flex-cols justify-center sm:h-full lg:h-auto lg:mt-[150px]"
      >
        <div
          style={{ background: `url(${bg})` }}
          className="sm:hidden lg:block w-[255px] px-[31px] py-[56px] rounded-l-lg"
        >
          <p className="text-[32px] font-[500] leading-[24px] text-white mb-[28px]">
            Login
          </p>
          <p className="text-[16px] font-[400] leading-[24px] text-white mb-[80px]">
            Book our luxuries, Enjoy your vacation
          </p>
          <img src={cal} alt="Calendar" />
        </div>

        <div
          style={{ fontFamily: "Open Sans" }}
          className="sm:w-full lg:w-[400px] sm:px-[16px] sm:py-[15px] lg:px-[35px] lg:pt-[100px] lg:pb-[90px] bg-white lg:rounded-r-lg text-center"
        >
          <div className="sm:block lg:hidden cursor-pointer">
            <img
              src={BBack}
              alt="Back"
              onClick={() => {
                setotpdisplay(false);
                // navigate(-2)
              }}
            />
          </div>
          <p className="text-[#14191F] text-[16px] sm:mt-[100px] lg:mt-[0px] font-[600] leading-[22px]">
            Please enter Whatsapp OTP sent to
          </p>

          <input
            type="tel"
            id="inputfield"
            defaultValue={input}
            inputMode="numeric"
            autoCorrect="off"
            autoCapitalize="off"
            onChange={(e) => setinput(e.target.value)}
            className="lg:w-[60%] md: w-[25%] sm: w-[40%] text-[#14191F] text-[16px] leading-[22px] font-[700] sm:mb-[100px] lg:mb-[60px]"
          />
          <span
            className="text-[#C69130] text-[16px] leading-[22px] font-[700] cursor-pointer"
            onClick={changephonenumber}
          >
            {" "}
            Change
          </span>
          <MuiOtpInput
            className="MuiOtpInput"
            inputMode="numeric"
            autoCorrect="off"
            autoCapitalize="off"
            onComplete={debouncedHandleVerify}
            length={6}
            value={otpvalue}
            onChange={(value, index) => handleOtpChange(value, index)}
          />
          <NavButton
            name="VERIFY"
            onClick={debouncedHandleVerify}
            classname="w-full text-[16px] text-white bg-[#C69130] font-[700] leading-[20px] tracking-[0.8px] px-[100px] py-[13px] my-[43px]"
          />
          <p className="text-[#14191F] text-[14px] font-[400] leading-[19px]">
            Not received your code?
            <span
              className="cursor-pointer text-[#C69130] font-[700]"
              onClick={debouncedResendOTP}
            >
              {" "}
              Resend code
            </span>
          </p>
          <p
            onClick={handleNavigate}
            className="text-[#14191F] text-center text-[14px] font-[400] leading-[19px] sm:mt-[70px] lg:mt-[30px]"
          >
            Don’t have account yet?
            <span className="cursor-pointer text-[#C69130] font-[700]">
              {" "}
              Signup
            </span>
          </p>
        </div>

        <div className="lg:block sm:hidden">
          <img
            className="cursor-pointer ml-[20px]"
            src={Close}
            alt="Close"
            onClick={() => {
              setotpdisplay(false);
              // navigate(-2)
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VerifyOTPSent;
