const smoothScrolltoTop=()=>
{
    const startY = window.scrollY;
    const startTime = Date.now();

   const scroll=()=>
    {
        const currentTime = Date.now();
        const time = Math.min(1, ((currentTime - startTime) / 1000));

        window.scrollTo(0, easeInOutCubic(time, startY, -startY, 1));

        if (time < 1) requestAnimationFrame(scroll);
    }

    const easeInOutCubic=(t, b, c, d)=>
    {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t + b;
        t -= 2;
        return c / 2 * (t * t * t + 2) + b;
    }

    scroll();

}
export default smoothScrolltoTop