import { useContext } from "react";
import UserContext from "./Screens/UserContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PrivateRoute=({children})=>
{
    const session_id=localStorage.getItem('session_id');
    const {setopenlogin}=useContext(UserContext)
    const navigate=useNavigate()
    if(session_id!=="")
    {
        return children
    }
    else
    {
        navigate("/")
        setopenlogin(true)
    }
}
export default PrivateRoute