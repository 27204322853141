import { HIT_DETAIL_VIEW_API, UPDATE_CALENDAR_MODAL, UPDATE_COUNT_MODAL, UPDATE_SEARCH_QUERY } from "../Constants"

let initialstate={
    calendarmodal:false,
    countmodal:false,
    searchquery:false,
    hitdetailviewapi:false
}

const ModalReducer=(state=initialstate,action)=>
{
    switch(action.type)
    {
        case UPDATE_CALENDAR_MODAL:
            return {...state,calendarmodal:action.payload}
        case UPDATE_COUNT_MODAL:
            return {...state,countmodal:action.payload}
        case UPDATE_SEARCH_QUERY:
            return {...state,searchquery:action.payload}
        case HIT_DETAIL_VIEW_API:
            return {...state,hitdetailviewapi:action.payload}
        default:
            return state
    }
}
export default ModalReducer