import ContactUs from "../ContactUs"
import Footer from "../Footer"
import NavbarAll from "../NavbarAll"
import TitleBar from "../TitleBar"
import PriceSummary from "./PriceSummary"
import Fade from "react-reveal/Fade"

const CheckOut=()=>
{
    return(
        <div>
            <Fade>
                <TitleBar text="Payment & contact info"/>
            </Fade>
            <Fade>
                <PriceSummary />
            </Fade>
            
        </div>
    )
}
export default CheckOut