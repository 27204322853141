import { Grow, Modal } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import Rating from '@mui/material/Rating'
import NavButton from "../../Utils/SignButton"
import modalclose from "../../Assets/modalclose.png"
import LeftScroll from "../../Assets/LeftScroll.png"
import RightScroll from "../../Assets/RightScroll.png"
import BBack from "../../Assets/BBack.png"
import { useNavigate } from "react-router"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, FreeMode, Thumbs, Autoplay } from 'swiper'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useRef } from "react"
import ImageTextCard from "./ImageTextCard"

const ModalForAmenities=({zoomimagemodal,setzoomimagemodal})=>
{
    let storedata=useSelector((storedata)=>storedata)
    let {propertydetails}=storedata?.propertyreducer
    let {amenities, brik_image}=propertydetails
    const navigationPrevRef=useRef(null)
    const navigationNextRef=useRef(null)
    const navigate=useNavigate()

    return(
        <div >
            <div className="lg:block sm:hidden">
                <Modal style={{ width: "80%", height: "80%", margin: "auto", marginTop:"5%",display:zoomimagemodal["lg"]?"block":"none" }}
                    open={zoomimagemodal["lg"]}
                    onClose={()=>setzoomimagemodal({...zoomimagemodal,lg:false})}  
                >
                    <Box sx={{overflow:"auto", height:"100%", backgroundColor:"white", borderRadius: "10px", 
                    "&::-webkit-scrollbar": {width: "12px",},"&::-webkit-scrollbar-thumb": {backgroundColor: "rgb(193,193,193)",borderRadius: "6px",},}}>
                        <Grow in={zoomimagemodal["lg"]}>
                        <div style={{fontFamily:"Poppins"}} className="bg-white px-[70px] py-[50px] w-full h-full">
                            <div className="flex justify-between items-center mb-[70px]">
                                <div>
                                    <p style={{fontFamily:"Playfair Display"}} className="text-[34px] font-[600] leading-[45px] text-[#0C0C0C]">Room Amenities</p>
                                    
                                </div>
                                <div className="flex gap-[24px]">
                                    {/* <NavButton onClick={()=>navigate("/checkout")} id="activeeffect" name="BOOK NOW" classname="px-[25px] py-[15px] bg-[#C69130] text-white text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#976e23]" /> */}
                                    <div id="activeeffect" onClick={()=>setzoomimagemodal({...zoomimagemodal,lg:false})} className="cursor-pointer flex gap-[8px] rounded-lg items-center border border-[2px] border-[#C69130] px-[32px] py-[15px] bg-white text-[#46484D] text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#C69130]">
                                        <img src={modalclose}></img>
                                        <p>CLOSE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-[40px]">
                                {
                                    amenities?.map((e,i)=>
                                    {
                                        return(
                                            <div key={i}>
                                                <ImageTextCard key={i} url={e?.aminety_icon} text={e?.amenity_feature} imgClassName="h-[35px]" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        </Grow>
                    </Box>
                </Modal>
            </div>
            <div className="lg:hidden sm:block">
            <Modal style={{ width: "100%", height: "100%" ,margin: "auto", marginTop:"0%",display:zoomimagemodal["sm"]?"block":"none" }}
                    open={zoomimagemodal["sm"]}
                    onClose={()=>setzoomimagemodal({...zoomimagemodal,sm:false})}  
                >
            
            <Box sx={{overflow:"auto", height:"100%", backgroundColor:"white", borderRadius: "10px", 
                    "&::-webkit-scrollbar": {width: "12px",},"&::-webkit-scrollbar-thumb": {backgroundColor: "rgb(193,193,193)",borderRadius: "6px",},}}>
                            <Grow in={zoomimagemodal["sm"]}>
                            <div className='w-[100%] h-full'>
                                <div id='activeeffect' onClick={()=>setzoomimagemodal({...zoomimagemodal,sm:false})} className='w-full flex gap-[12px] items-center px-[13px] pt-[13px] pb-[14px]'>
                                    <div>
                                        <img src={BBack}/>
                                    </div>
                                    <p style={{fontFamily:"Playfair Display"}} className="text-[20px] font-[600] leading-[27px] text-[#0C0C0C]">Room Amenities</p>
                                </div>
                                <hr className="mb-[28px]" />
                                <div className="grid grid-cols-1 gap-[30px] px-[30px]">
                                {
                                    amenities?.map((e,i)=>
                                    {
                                        return(
                                            <div key={i}>
                                                <ImageTextCard key={i} url={e?.aminety_icon} text={e?.amenity_feature} imgClassName="h-[35px]" />
                                            </div>
                                        )
                                    })
                                }
                                </div>
                                {/* <div className="w-full h-[50%]" onClick={()=>setzoomimagemodal({...zoomimagemodal,sm:false})}>
                                    
                                </div> */}
                            </div>
                            </Grow>
                </Box>
            </Modal>
            </div>
        </div>
    )
}
export default ModalForAmenities