const AddOnCard=({url,title,text,highlighter})=>
{
    return(
        <div className="flex flex-col items-center justify-center">
            <div>
                <img src={url} />
            </div>
            <p style={{fontFamily:"Poppins"}} className="text-[#383838] text-center text-[25px] leading-[30px] font-[600] mt-[24px] mb-[24px]">{title}</p>
            <p style={{fontFamily:"Poppins",whiteSpace:"pre-line"}} className="text-[14px] font-[400] text-center text-[#656565] leading-[20px] tracking-[1px] italic">{text.replace(/\n/g, "\n")}</p>
            <p style={{fontFamily:"Poppins"}} className="mt-[10px] text-[14px] font-[600] text-center text-[#656565] leading-[20px] tracking-[1px]">{highlighter}</p>
        </div>
    )
}
export default AddOnCard