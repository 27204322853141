
import TitleBar from "../TitleBar"
import SearchProperties from "./SearchProperties"
import Fade from "react-reveal/Fade"

const Search=()=>
{
    return(
        <div>
            <Fade>
                <TitleBar text="Search results" />
            </Fade>
            <Fade>
                <SearchProperties />
            </Fade>
        </div>
    )
}
export default Search