import { HIT_DETAIL_VIEW_API, UPDATE_CALENDAR_MODAL, UPDATE_COUNT_MODAL, UPDATE_SEARCH_QUERY } from "../Constants"

export const CalendarModalAction=(data)=>
{
    return{
        type:UPDATE_CALENDAR_MODAL,
        payload:data
    }
}
export const CountModalAction=(data)=>
{
    return{ 
        type:UPDATE_COUNT_MODAL,
        payload:data
    }
}
export const UpdateSearchQueryAction=(data)=>
{
    return{ 
        type:UPDATE_SEARCH_QUERY,
        payload:data
    }
}

export const HitDetailViewApiAction=(data)=>
{
    return{ 
        type:HIT_DETAIL_VIEW_API,
        payload:data
    }
}