import { MuiOtpInput } from "mui-one-time-password-input";
import bg from "../../Assets/contourbg.png";
import cal from "../../Assets/Calendar.png";
import NavButton from "../../Utils/SignButton";
import Close from "../../Assets/Close.png";
import BBack from "../../Assets/BBack.png";
import { useContext, useEffect, useState } from "react";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useFormik,
  useFormikContext,
} from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../UserContext";
import { SignUpUser } from "../../Redux/Actions/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SetSessionIdAction } from "../../Redux/Actions/UserActions";
import { debounce } from "@mui/material";

const schema = yup.object().shape({
  phonenumber: yup
    .string()
    .matches(/^\d+$/, "Phone number must only contain digits")
    .max(10, "Phone number must be of 10 digits")
    .min(10, "Phone number must be of 10 digits")
    .required("Phone Number is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email"),
  name: yup
    .string()
    .matches(/^[a-z ,.'-]+$/i, "Name must not contain special characters")
    .required("Name is required"),
});
const SignUp = () => {
  const {
    input,
    setinput,
    opensignup,
    setopensignup,
    setopenlogin,
    setotpdisplay,
  } = useContext(UserContext);
  let spanclass = "font-[600] text-[14px] leading-[24px] cursor-pointer";

  const navigate = useNavigate();
  const [code, setcode] = useState("+91");
  // const[navigatetologin,setnavigatetologin]=useState(false)
  // useEffect(()=>
  // {
  //     setTimeout(()=>
  //     {
  //         navigate("/login")
  //     },5000)
  // },[navigatetologin])
  const dispatch = useDispatch();
  const initialValues = {
    phonenumber: "",
    email: "",
    name: "",
  };
  let toastId = null
  const handleSubmit = debounce((values) => {
    SignUpUser({
      name: values.name,
      email: values.email,
      mobile_number: values.phonenumber,
    })
      .then((res) => {
        dispatch(SetSessionIdAction(res?.session_id));
        toast.success("OTP sent successfully on the mobile number");
        setotpdisplay(true);
        setinput(values?.phonenumber);
        setopensignup(false);
        // navigate("/verifyotp")
      })
      .catch((err) => {
       
        if (toastId) {
            toast.update(toastId, { render: "The user is already registered", type: toast.TYPE.ERROR });
        } else {
            toastId = toast.error("The user is already registered", {
                onClose: () => (toastId = null) // Reset toastId when toast is closed
            });
        }
        
      });
  }, 900);
  return (
    <div className={opensignup ? "after" : "before"}>
      <div
        style={{ fontFamily: "Inter", display: opensignup ? "flex" : "none" }}
        className="sm:h-full lg:h-auto w-full justify-center lg:mt-[100px]"
      >
        <div
          style={{ background: `url(${bg})` }}
          className="lg:block sm:hidden w-[255px] px-[31px] py-[56px] rounded-l-lg"
        >
          <p className="text-[32px] font-[500] leading-[24px] text-white mb-[28px]">
            Signup
          </p>
          <p className="text-[16px] font-[400] leading-[24px] text-white mb-[80px]">
            Book our luxuries, Enjoy your vacation
          </p>
          <img src={cal} />
        </div>
        <div className="overflow-auto sm:w-full h-full lg:w-[400px] sm:px-[16px] sm:py-[20px] lg:px-[35px] lg:pt-[50px] lg:pb-[20px] bg-white lg:rounded-r-lg">
          <div style={{ fontFamily: "Inter" }} className="sm:block lg:hidden">
            <div className="">
              <img
                src={BBack}
                onClick={() => {
                  setopensignup(false);
                  // navigate(-2)
                }}
              />
            </div>
            <p className="text-[34px] font-[600] leading-[24px] text-[#14191F] mt-[63px] mb-[19px]">
              Sign Up
            </p>
            <p className="text-[16px] font-[400] leading-[24px] text-[#14191F] mb-[80px]">
              Book our luxuries, Enjoy your vacation
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="">
                <p className="sm:text-[14px] lg:text-[16px] font-[400] leading-[20px] text-[#14191F] sm:mb-[10px] lg:mb-[10px]">
                  Mobile number
                </p>
                <div className="flex items-center gap-[25px] bg-[#F0F0F0]">
                  <select
                    className="bg-[#F0F0F0] p-[15px] text-[16px]"
                    onChange={(e) => setcode(e.target.value)}
                  >
                    <option value="+91">+91</option>
                  </select>
                  <Field
                    type="text"
                    name="phonenumber"
                    className="bg-[#F0F0F0] text-[14px] p-[15px] w-full"
                    placeholder="Enter mobile number"
                  />
                </div>
                <ErrorMessage
                  className="text-[#FF0000] text-[14px] text-center font-[400] leading-[20px]"
                  name="phonenumber"
                  component="div"
                />

                <p className="sm:text-[14px] lg:text-[16px] font-[400] leading-[20px] text-[#14191F] sm:mb-[10px] lg:mb-[10px] sm:mt-[15px] lg:mt-[20px]">
                  Full name
                </p>
                <Field
                  type="text"
                  name="name"
                  className="bg-[#F0F0F0] text-[14px] p-[15px] w-full"
                  placeholder="Enter full name"
                />
                <ErrorMessage
                  className="text-[#FF0000] text-[14px] text-center font-[400] leading-[20px]"
                  name="name"
                  component="div"
                />

                <p className="sm:text-[14px] lg:text-[16px] font-[400] leading-[20px] text-[#14191F] sm:mb-[10px] lg:mb-[10px] sm:mt-[15px] lg:mt-[20px]">
                  Email Id
                </p>
                <Field
                  type="text"
                  name="email"
                  className="bg-[#F0F0F0] text-[14px] p-[15px] w-full"
                  placeholder="Enter email"
                />
                <ErrorMessage
                  className="text-[#FF0000] text-[14px] text-center font-[400] leading-[20px]"
                  name="email"
                  component="div"
                />

                <p className="text-[14px] font-[400] leading-[24px] text-[#59626E] my-[30px]">
                  By sign in you agree to the{" "}
                  <span className={spanclass}>
                    <a  target="_blank" rel="noopener noreferrer" href="https://brikkit-prod-docs.s3.amazonaws.com/media/brik/terms_conditions/ownerTERMS__CONDITIONS_FOR_BRIKITT_HOLIDAY_HOME.pdf">Terms & Conditions</a>
                  </span>{" "}
                  and{" "}
                  <span className={spanclass}>
                    <a  target="_blank" rel="noopener noreferrer" href="https://brikitt.com/privacy-policy/">Privacy Policy</a>
                  </span>{" "}
                  of Brikitt
                </p>
                <button
                  type="submit"
                  className="cursor-pointer w-full text-[16px] text-white bg-[#C69130] font-[700] leading-[20px] tracking-[0.8px] px-[80px] py-[13px]"
                >
                  SIGNUP
                </button>
                <p
                  onClick={() => {
                    // navigate("/login")
                    setopenlogin(true);
                    setopensignup(false);
                  }}
                  className="text-[#14191F] text-center text-[14px] font-[400] leading-[19px] mt-[30px]"
                >
                  Already have an account?{" "}
                  <span className="cursor-pointer text-[#C69130] font-[700]">
                    {" "}
                    Login
                  </span>
                </p>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="lg:block sm:hidden">
          <img
            className="cursor-pointer ml-[20px]"
            src={Close}
            onClick={() => {
              setopensignup(false);
              // navigate(-2)
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default SignUp;
