
import TitleBar from "../TitleBar"
import Booking from "./Booking"
import Fade from "react-reveal/Fade"

const MyBookings = () => {
    return (
        <div>
            <Fade>
                <TitleBar text="My bookings" />
            </Fade>
            <Fade>
                <Booking />
            </Fade>
        </div>
    )
}
export default MyBookings