import { useNavigate } from "react-router-dom"
import Back from "../Assets/Back.png"
import smoothScrolltoTop from "./Scroll"
const BackButton=({color,url})=>
{
   const navigate=useNavigate()
    return(
        <div style={{fontFamily:"Poppins",
            outline: "none",
            boxShadow: "none",
            WebkitTapHighlightColor: "transparent"
        }} onClick={()=>{
            smoothScrolltoTop()
            // window.scrollTo({
            //     top: 0,
            //     behavior: 'smooth'
            // });
            navigate(-1)
            
            }} className={`flex items-center gap-[12px] text-${color} font-[500] text-[20px] leading-[29px] cursor-pointer sm:mb-[20px] lg:mb-[46px]`}>
                <img src={url} />
                <p className="sm:hidden lg:block">Back</p>
            </div>
    )
}
export default BackButton