
import { useContext } from "react"
import Down from "../Assets/Down.svg"
import UserContext from "../Screens/UserContext"
import { useDispatch, useSelector } from "react-redux"
import { CalendarModalAction, UpdateSearchQueryAction } from "../Redux/Actions/ModalAction"

const PlaceSelectorDetailView=({label,text,gap})=>
{
    const{place,setplace}=useContext(UserContext)
    let optionclass = "px-[10px] bg-grey"
    let optionStyle = { fontSize: '16px', backgroundColor: 'lightgray' };
    const storedata=useSelector((storedata=>storedata))
    const dispatch=useDispatch()
    // console.log(storedata)
    
    const handlechange=(e)=>
    {
        setplace(e.target.value)
        localStorage.setItem("place",e.target.value)
        dispatch(CalendarModalAction(true))
        dispatch(UpdateSearchQueryAction(true))
    }
    return(
        <div style={{fontFamily:"Inter"}} className='cursor-pointer w-full bg-white px-[25px] py-[15px] relative'>
        <p className='text-[#8F8F8F] text-16px leading-[27px] font-[500]'>{label}</p>
        <div className={`flex justify-between items-center gap-[${gap}] mt-[3px]`}>
            <select defaultValue={place} style={{backgroundImage:`url(${Down})`,backgroundRepeat:"no-repeat",backgroundPosition:'right center'}} onChange={handlechange} className={`custom-select w-full text-black text-[13px] leading-[22px] font-[500] tracking-[1.5px] pl-5`} >
                {/* <option className={optionclass} style={{ fontSize: '18px', backgroundColor: 'lightgray' }} value="">
                    <span className="option-container">Select Your Destination</span>
                    </option> */}
                <option className={optionclass} style={optionStyle} value="all">
                <span className="option-container"> ALL</span>
                    </option>
                <option className={optionclass} style={optionStyle} value="Goa">
                    <span className="option-container">GOA</span></option>
                <option className={optionclass} style={optionStyle} value="Uttarakhand">
                <span className="option-container">MUKTESHWAR</span></option>
            </select>
            {/* <p className='text-black text-[13px] leading-[22px] font-[500] tracking-[1.5px]'>{text}</p>
            <img className='cursor-pointer' src={Down} /> */}
        </div>
    </div>
       
    )
}
export default PlaceSelectorDetailView