import { Avatar } from "@mui/material";
import dateFormat from "dateformat";
import { useState } from "react";
const ReviewCard=({description,name,date,reviewnumber,rating,imagedata,type})=>
{
    const[truncatedtext,setruncatedtext]=useState(description.slice(0,200))
    let firstinitial=name?.split(' ')?.[0]?.[0];
    let lastinitial=name?.split(' ')?.[1]?.[0];
    let ratingcolor=[{"rating":"5","color":"#05C063"},
                    {"rating":"4","color":"#05C063"},
                    {"rating":"3","color":"#8B8000"},
                    {"rating":"2","color":"#8B8000"},
                    {"rating":"1","color":"red"}]
    let bgcolor=""
    ratingcolor.map((e,i)=>
    {
        if(e.rating ==rating)
        {
            bgcolor = e.color
        }
    })
    return(
        
        <div style={{boxShadow:"0px 4px 15px rgba(0, 0, 0, 0.05"}} className="bg-white p-[20px] border-1 rounded-lg">
            <div style={{fontFamily:"Inter"}} className=" flex justify-between">
                <div className="flex gap-[10px] items-start">
                    <div style={{display:type=="Review"?"none":"block"}} className="bg-[#C2C2C2] rounded-full">
                        <Avatar>{firstinitial}{lastinitial}</Avatar>
                        {/* <p className="w-full h-full text-[#46484D] text-[16px] font-[400] leading-[22px]">{firstinitial}{lastinitial}</p> */}
                    </div>
                    <div>
                        <p className="text-[#0C0C0C] text-[16px] font-[700] leading-[22px] mb-[5px]">{name}</p>
                        <p className="text-[#46484D] sm:text-[12px] lg:text-[14px] font-[400] leading-[18px]"> (Stayed {dateFormat(date,"dd mmm, yyyy")})</p>
                        {/* <p className="text-[#46484D] text-[14px] font-[400] leading-[18px] mb-[11px]">{reviewnumber} review written</p> */}
                        <p className="lg:block sm:hidden text-[#0C0C0C] sm:text-[14px] lg:text-[16px] font-[400] leading-[22px]">{description}</p>
                        {
                            description?.length<=200?
                            <p className="lg:hidden sm:block text-[#0C0C0C] sm:text-[14px] lg:text-[16px] font-[400] leading-[22px]">{truncatedtext}</p>:
                            <p className="lg:hidden sm:block text-[#0C0C0C] sm:text-[14px] lg:text-[16px] font-[400] leading-[22px]">{truncatedtext}<span className="cursor-pointer text-[#D69F56] text[14px] italic font-[500] leading-[20px]" onClick={()=>truncatedtext.length==description.length?setruncatedtext(description.slice(0,200)):setruncatedtext(description)}>{truncatedtext.length==description.length?"... read less":"... read more"}</span></p>

                        }
                        
                        <div className="flex flex-wrap gap-[11px] mt-[20px]">
                            {imagedata?.map((e,i)=>
                            {
                                return(
                                    <div className="lg:w-[114px] sm:w-[47px] lg:h-[114px] sm:h-[47px]" key={i}>
                                        <img className="w-full h-full object-cover" src={type=="Review"?e?.image:e} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div>
                        <p className={`p-[5px] text-white text-[16px] font-[700] leading-[21px] bg-[${bgcolor}]`}>{rating}/5</p>
                </div>
            </div>
            
        </div>
    )
}
export default ReviewCard