
import { useContext } from "react"
import CounterCard from "./MemberCounterCard"
import NavButton from "./SignButton"
import UserContext from "../Screens/UserContext"
import { useDispatch, useSelector } from "react-redux"
import { Box, Modal } from "@mui/material";
import { CountModalAction, HitDetailViewApiAction, UpdateSearchQueryAction } from "../Redux/Actions/ModalAction"
import Fade from '@mui/material/Fade';
import { useNavigate } from "react-router-dom"
import dateFormat from "dateformat"
import { GetPropertyDetail } from "../Redux/Actions/api"
import { PropertyDetailAction } from "../Redux/Actions/PropertyAction"
import { toast } from "react-toastify"
import smoothScrolltoTop from "./Scroll"

const MembersCountDetailView=({setconfirmbuttonstate,setdetaildata,maxAdult})=>{
    
    const {count,setcount,place,date}=useContext(UserContext)
    const checkin=dateFormat(date.checkin,'yyyymmdd')
    const checkout=dateFormat(date.checkout,'yyyymmdd')
    const storedata=useSelector((storedata)=>storedata)
    const navigate=useNavigate()
    
    const{countmodal,searchquery}=storedata?.modalreducer
    const dispatch=useDispatch()
    const spv_id=localStorage.getItem("spv_id")
    const brik_id=localStorage.getItem("brik_id")
    let adult=count?.adults
    let children=count?.children
    const checkindetail=dateFormat(date.checkin,'yyyy-mm-dd')
    const checkoutdetail=dateFormat(date.checkout,'yyyy-mm-dd')
    return(
                <Modal
                className="modal"    
                style={{ height: "100%", margin: "auto", marginTop:"20%" }}
                    onClose={() =>dispatch(CountModalAction(false)) }
                    open={countmodal}
                > 
                    <Fade in={countmodal}>
                    <Box
                        className="dropin-parent"
                        id="drop_in_container"
                        >
                        <div style={{fontFamily:"Inter"}} className="text-[16px] leading-[19px] px-[20px] py-[14px] bg-white text-[#282827]">
                            <p className="font-[700] mb-[20px]">Total Members</p>
                            <div className="flex md:flex-row sm:flex-col sm:gap-[50px] md:gap-[70px] items-center">
                            <CounterCard title="Adults" count={count} setcount={setcount} maxAdult={maxAdult} />
                            <CounterCard title="Kids" count={count} setcount={setcount} maxAdult={maxAdult} />
                            </div>
                            <div style={{fontFamily:"Open Sans"}} className="flex flex-cols justify-end mt-[30px]">
                                <NavButton onClick={()=>{
                                    dispatch(CountModalAction(false))
                                    if(searchquery){
                                        setTimeout(() => {
                                            navigate(`/search/?q=${place}&date_check_in=${checkin}&date_check_out=${checkout}&children=${count.children}&adult=${count.adults}&page=1&perPage=4`)
                                            // window.scrollTo({
                                            //     top: 0,
                                            //     behavior: 'smooth'
                                            // });
                                            smoothScrolltoTop()
                                        }, 1000);
                                    }
                                    else
                                    {
                                        GetPropertyDetail(spv_id,brik_id,checkindetail,checkoutdetail,adult,children).then((res)=>
                                            {
                                                dispatch(UpdateSearchQueryAction(false))
                                                setdetaildata(res)
                                                setconfirmbuttonstate(false)
                                            if (brik_id != res?.brik_id || spv_id != res?.spv_info?.id) {
                                                console.log('setting brik_id in localstorage:', res?.brik_id)
                                                    localStorage.setItem("spv_id", res?.spv_info?.id)
                                                    localStorage.setItem("brik_id", res?.brik_id)
                                                    // setspv_id(res?.spv_info?.id)
                                                    // setbrik_id(res?.brik_id)
                                                }
                                                localStorage.setItem("totalprice",res?.total_payable_inr)
                                                localStorage.setItem("cgst",res?.cgst_on_total_payable_inr)
                                                localStorage.setItem("gst",res?.gst_on_total_payable_inr)
                                                localStorage.setItem("grandtotal",res?.grand_total)
                                                // localStorage.setItem("spv_id",res?.spv_info?.id)
                                                // localStorage.setItem("brik_id",res?.brik_id)
                                                dispatch(PropertyDetailAction(res))
                                                if(!res?.brik_availability)
                                                {
                                                    toast.error("You just missed it! We are sold out on your dates!")
                                                }
                                                
                                            }).catch((error)=>{
                                                toast.error(error?.response?.data?.error)
                                            })
                                            
                                    }
                                }} name="Confirm" classname="bg-[#C69130] text-white text-[16px] px-[38px] py-[16px] font-[600] leading-[19px] tracking-[2px]" />
                            </div>
                        </div>
                        </Box>
                    </Fade>
                </Modal>
        
    )
}
export default MembersCountDetailView