
import { useContext } from "react"
import CounterCard from "./MemberCounterCard"
import NavButton from "./SignButton"
import UserContext from "../Screens/UserContext"
import { useDispatch, useSelector } from "react-redux"
import { Box, Modal } from "@mui/material";
import { CountModalAction } from "../Redux/Actions/ModalAction"
import Fade from '@mui/material/Fade';
import { useNavigate } from "react-router-dom"
import dateFormat from "dateformat"

const MembersCount=()=>{
    
    const {count,setcount,setsearchdata,place,date}=useContext(UserContext)
    const checkin=dateFormat(date.checkin,'yyyymmdd')
    const checkout=dateFormat(date.checkout,'yyyymmdd')
    const storedata=useSelector((storedata)=>storedata)
    const navigate=useNavigate()
    
    const{countmodal}=storedata?.modalreducer
    const dispatch=useDispatch()
    return(
                <Modal
                    className="modal"
                    style={{ height: "100%", margin: "auto", marginTop:"20%" }}
                    onClose={() =>dispatch(CountModalAction(false)) }
                    open={countmodal}
                > 
                    <Fade in={countmodal}>
                    <Box
                        className="dropin-parent"
                        id="drop_in_container"
                        >
                        <div style={{fontFamily:"Inter"}} className="text-[16px] leading-[19px] px-[20px] py-[14px] bg-white text-[#282827]">
                            <p className="font-[700] mb-[20px]">Total Members</p>
                            <div className="flex md:flex-row sm:flex-col sm:gap-[50px] md:gap-[70px] items-center">
                                <CounterCard title="Adults" count={count} setcount={setcount}/>
                                <CounterCard title="Kids" count={count} setcount={setcount}/>
                            </div>
                            <div style={{fontFamily:"Open Sans"}} className="flex flex-cols justify-end mt-[30px]">
                                <NavButton onClick={()=>{
                                    dispatch(CountModalAction(false))
                                    setsearchdata(true)
                                    setTimeout(() => {
                                        navigate(`/search/?q=${place}&date_check_in=${checkin}&date_check_out=${checkout}&children=${count.children}&adult=${count.adults}&page=1&perPage=4`)
                                        setsearchdata(false)
                                    }, 2000);
                                }} name="Confirm" classname="bg-[#C69130] text-white text-[16px] px-[38px] py-[16px] font-[600] leading-[19px] tracking-[2px] rounded-md" />
                            </div>
                        </div>
                        </Box>
                    </Fade>
                </Modal>
        
    )
}
export default MembersCount