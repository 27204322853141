
import AddOns from "./AddOns"
import BookingStay from "./Booking"
import Properties from "./Properties"
import Fade from "react-reveal/Fade"
import whatsapp from "../../Assets/whatsapp.svg"

const Home=()=>
{
    return(
        <div>
            <Fade>
                <BookingStay />
            </Fade>
            <Fade>
                <Properties/>
            </Fade>
            <Fade>
                <AddOns />
            </Fade>
            <div id="whatsapp-button">
                <a href="https://api.whatsapp.com/send?phone=9219965827" target="_blank">
                    <div>
                        <img className="w-full h-full" src={whatsapp} alt="WhatsApp" />
                    </div>
                </a>
            </div>
        </div>
        
    )
}
export default Home