import { GET_ALL_PROPERTIES, PROPERTY_DETAIL, RATING, SEARCH_PROPERTIES } from "../Constants"

export const GetAllPropertiesAction=(data)=>
{
    return{
        type:GET_ALL_PROPERTIES,
        payload:data
    }
}

export const SearchPropertiesAction=(data)=>
{
    return{
        type:SEARCH_PROPERTIES,
        payload:data
    }
}

export const PropertyDetailAction=(data)=>
{
    return{
        type:PROPERTY_DETAIL,
        payload:data
    }
}

export const RatingAction=(data)=>
{
    return{
        type:RATING,
        payload:data
    }
}