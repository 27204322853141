import { useEffect, useState } from "react"
import ImageTextCard from "./ImageTextCard"
import Up from "../../Assets/Up.svg"
import Down from "../../Assets/Down.svg"
import Star from "../../Assets/Star.png"
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import ReviewCard from "./ReviewCard"
import Bullet from "../../Assets/Bullet.png"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import dateFormat from "dateformat"

const Reviews=({detaildata,sortbyrating,setsortbyrating,sortbydate,setsortbydate,reviewdata,page,setpage})=>
{
    
    let{average_rating,total_rating,total_review}=detaildata
    let{total_pages,avg_rating}=reviewdata
    let{rating_dict}=reviewdata
    let maxvalue=0
    let sliderdata=[{"title":"Excellent","value":rating_dict?.["5"]},
    {"title":"Very Good","value":rating_dict?.["4"]},
    {"title":"Average","value":rating_dict?.["3"]},
    {"title":"Poor","value":rating_dict?.["2"]},
    {"title":"Terrible","value":rating_dict?.["1"]}]
    sliderdata.forEach((e)=>maxvalue+=(parseInt(e.value)))
    const handlechange=(e,value)=>
    {
        setpage(value)
    }
    return(
        <div style={{fontFamily:"Poppins"}} className="flex flex-col gap-[25px]">
            <div className="flex justify-between items-center">
                <p style={{fontFamily:"Poppins"}} className="text-[#0C0C0C] text-[22px] font-[600] leading-[33px]">Reviews</p>
                {
                    reviewdata?.reviews?.length>1?
                    <div className="flex sm:gap-[10px] lg:gap-[30px] items-center">
                        <p className="sm:text-[12px] lg:text-[18px] font-[600] leading-[22px]">Sort By:</p>
                        <ImageTextCard onclick={()=>sortbyrating=="desc"?setsortbyrating("asc"):setsortbyrating("desc")} text="Rating" type="sort" url={sortbyrating=="desc"?Down:Up} />
                        <ImageTextCard onclick={()=>sortbydate=="desc"?setsortbydate("asc"):setsortbydate("desc")} text="Date" type="sort" url={sortbydate=="desc"?Down:Up} />
                    </div>:<></>
                }
                
            </div>

            <div className=" w-full flex lg:flex-row sm:flex-col items-start gap-[20px]">
                <div className="w-full h-full flex items-start sm:gap-[17px] lg:gap-[20px]">
                    <div className="w-full h-full flex flex-col gap-[10px] justify-center items-center bg-[#D7D7D7] rounded-md sm:py-[16px] sm:px-[30px] lg:py-[25px] lg:px-[18px]">
                            <div>
                                <img className="w-[12px] h-[12px]" src={Star}></img>
                            </div>
                            <p className="sm:text-[22px] lg:text-[28px] font-[800] text-[#212630] leading-[33px] tracking-[-2%]">{avg_rating?avg_rating:"0"} </p>
                    </div>
                    <div className="w-full h-full flex flex-col gap-[10px] justify-center items-center bg-[#D7D7D7] rounded-md sm:py-[16px] sm:px-[30px] lg:py-[25px] lg:px-[18px]">
                        <p className="text-[#2D2D2D] sm:text-[16px] lg:text-[20px] font-[700] leading-[24px] tracking-[-2%]">{total_rating}</p>
                        <p className="text-[#2D2D2D] sm:text-[13px] lg:text-[16px] font-[600] leading-[20px] tracking-[-2%]">Rating</p>
                    </div>
                    <div className="w-full h-full flex flex-col gap-[10px] justify-center items-center  bg-[#D7D7D7] rounded-md sm:py-[16px] sm:px-[30px] lg:py-[25px] lg:px-[18px]">
                        <p className="text-[#2D2D2D] sm:text-[16px] lg:text-[20px] font-[700] leading-[24px] tracking-[-2%]">{total_review}</p>
                        <p className="text-[#2D2D2D] sm:text-[13px] lg:text-[16px] font-[600] leading-[20px] tracking-[-2%]">Reviews</p>
                    </div>
                </div>
                <div className="w-full flex flex-col leading-[25px] gap-[0px]">
                    {
                        sliderdata?.map((e,i)=>
                        {
                            return(
                                <div key={i} className="flex items-center gap-[10px]">
                                    <div className="sm:w-[30%] sm1:w-[20%]">
                                        <p className="text-[14px] p-[0px] m-[0px]">{e.title}</p>
                                    </div>
                                    <div className="w-full flex items-center gap-[10px]">
                                        <div style={{ width: "100%" }}>
                                            <Slider className="custom-slider" disabled step="10" defaultValue={e.value} max={maxvalue} min="0" />
                                        </div>
                                        <p className="w-[5%]">{e.value}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                

            </div>
            {/* <div className="flex flex-col bg-white">
                    <p className="text-[#141823] text-[14px] font-[700] p-[10px] leading-[20px] border-2 border-[#E5E5E5]">What our guests say?</p>
                    <div className="grid grid-cols-2 p-[10px] gap-[6px] border-2 border-[#E5E5E5]">
                        <div className="border-2 border-[#8F714C] px-[10px] py-[5px]">
                            <p>Helpful Staff (62)</p>
                        </div>
                    </div>
            </div> */}
            
            <div className="flex flex-col gap-[20px]">
                {
                    reviewdata?.reviews?.map((e,i)=>
                    {
                        return <ReviewCard description={e?.review}
                        name={e?.name} 
                        date={dateFormat(e?.date,"dd mmmm, yyyy")}
                        reviewnumber={e?.review?e?.review:"0"}
                        rating={e?.rating}
                        imagedata={e?.images}/>
                    })
                }
            </div>
            {
            total_pages!=1?
                <Stack className="flex flex-col items-center mt-[60px]" spacing={2}>
                    <Pagination style={{fontFamily:"Inter"}} page={page} shape="rounded" count={total_pages} onChange={handlechange} showFirstButton showLastButton />
                </Stack>:<></>
            }
        </div>
    )
}
export default Reviews