import { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

const NavScroll=({propdetailnavbar})=>
{
    const storedata = useSelector((storedata) => storedata)
    console.log('storedata:', storedata)

    const data=[
        {"title":"About","to":"","ref":propdetailnavbar[0]["About"]},
        { "title": "Room Amenities", "to": "", "ref": propdetailnavbar[0]["RoomAmenities"] },
        storedata?.propertyreducer?.propertydetails?.add_on_services && storedata?.propertyreducer?.propertydetails?.add_on_services.length > 0 ? {"title":"Add on services","to":"","ref":propdetailnavbar[0]["AddOn"]}:null,
        storedata?.propertyreducer?.propertydetails?.pets_allowed ? { "title": "Pets Allowed", "to": "", "ref": propdetailnavbar[0]["PetsAllowed"] }: null,
        {"title":"Reviews","to":"","ref":propdetailnavbar[0]["Review"]},
        // storedata?.propertyreducer?.propertydetails?.safety_and_property?.safetyAndProperty && storedata?.propertyreducer?.propertydetails?.safety_and_property?.safetyAndProperty.length > 0 ? 
        // {"title":"Things To Know","to":"","ref":propdetailnavbar[0]["SafetyAndProperty"]}:null,
        (storedata?.propertyreducer?.propertydetails?.hotel_rules?.rule && storedata?.propertyreducer?.propertydetails?.hotel_rules?.rule.length) > 0 ||
        (storedata?.propertyreducer?.propertydetails?.cancellation_policy?.cancellationPolicy && storedata?.propertyreducer?.propertydetails?.cancellation_policy?.cancellationPolicy.length) > 0 ||
        (storedata?.propertyreducer?.propertydetails?.safety_and_property?.safetyAndProperty && storedata?.propertyreducer?.propertydetails?.safety_and_property?.safetyAndProperty.length) > 0||
        (storedata?.propertyreducer?.propertydetails?.pets_friendly?.petsFriendly && storedata?.propertyreducer?.propertydetails?.pets_friendly?.petsFriendly.length) > 0
         ?
            { "title": "Things To Know", "to": "", "ref": propdetailnavbar[0]["ThingsToKnow"]}: null,
        {"title":"Location","to":"","ref":propdetailnavbar[0]["Location"]},
        // {"title":"More properties","to":"","ref":propdetailnavbar[0]["MoreProperties"]}
    ]
    const [active,setactive]=useState(0)
    const location=useLocation()
    const navigate=useNavigate()
    const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));
    
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            data.forEach((item, index) => {
                if (item && item.ref.current) {
                    const elementPosition = item.ref.current.offsetTop;
                    const elementHeight = item.ref.current.offsetHeight;

                    if (scrollPosition >= elementPosition - 200 && scrollPosition < elementPosition + elementHeight - 200) {
                        setactive(index);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [data]);
    // useEffect(() => {
    //     console.log(propdetailnavbar)
    //     const spv_id=localStorage.getItem('spv_id')
    //     const brik_id=localStorage.getItem('brik_id')
    //     const newSearchParams = new URLSearchParams(location.search);
    
    //     newSearchParams.set('spv_id', spv_id);
    //     newSearchParams.set('brik_id', brik_id);
    //     setSearchParams(newSearchParams);
    
    //     navigate(`?${newSearchParams.toString()}`, { replace: true });
    //   }, [active, location.search, navigate]);

    return(
        <div style={{fontFamily:"Poppins"}} className="sm:hidden lg:flex sticky top-0 z-20 flex gap-[60px] text-[#2D2D2D] text-[18px] font-[500] leading-[20px] bg-white justify-evenly px-[150px] drop-shadow">
            {
                data?.map((e,i)=>
                {
                    return e?<Link key={i} onClick={()=>{
                        setactive(i)
                        if(e.ref.current)
                        {
                            // const offset = -50;
                            e.ref.current.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" });
                            // window.scrollBy(0);
                        }
                    }} className={active===i?"active":"inactive"} to={e.to}>{e.title}</Link>:<></>
                })
            }
        </div>
    )
}
export default NavScroll