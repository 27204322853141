import Bed from "../Assets/bedprop.png"
import Guest from "../Assets/guestprop.png"
import Forest from "../Assets/viewprop.png"
import Area from "../Assets/areaprop.png"
const Amenities=({bed,guest,area,view})=>
{
    const data=[
        {"title":"Bedrooms","description":bed,"url":Bed},
        {"title":"Max Guest","description":guest,"url":Guest},
        {"title":"Room Space","description":area,"url":Area},
        {"title":"Room View","description":view,"url":Forest},
    ]
    return(
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 items-start sm:gap-x-[10px] sm:gap-y-[20px] lg:gap-y-[40px] gap-x-[160px]">
            {
                data?.map((e,i)=>
                {
                    return(
                        <div key={i} className="flex gap-[15px]">
                            <div>
                                <img style={{marginTop: "5px"}} src={e.url}/>
                            </div>
                            <div className="flex flex-col gap-[6px]">
                                <p style={{fontFamily:"Poppins"}} className="sm:text-[16px] lg:text-[18px] leading-[29px] font-[400]">{e.title}</p>
                                <p style={{fontFamily:"Poppins"}} className="sm:text-[14px] lg:text-[16px] text-[#656565] leading-[21px] font-[400]">{e.description}</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Amenities