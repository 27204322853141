import { useState } from "react"
import { Grow, Modal } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import BBack from "../../Assets/BBack.png"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./HTMLContentStyle.css";
import modalclose from "../../Assets/modalclose.png"

const ModalForPropertyVideo=({zoomimagemodal,setzoomimagemodal})=>
{
    let storedata=useSelector((storedata)=>storedata)
    let {propertydetails}=storedata?.propertyreducer
    let {property_video}=propertydetails
    let urlForPropertyVideo = `https://www.youtube.com/embed/${property_video?.propertyVideo}?rel=0`     

    return(
        <div >
            <div className="lg:block sm:hidden">
                <Modal style={{ width: "100%", height: "100%", margin: "auto",display:zoomimagemodal["lg"]?"block":"none" }}
                    open={zoomimagemodal["lg"]}
                    onClose={()=>setzoomimagemodal({...zoomimagemodal,lg:false})}  
                >
                    <Box sx={{overflow:"auto", height:"100%", backgroundColor:"black", 
                    "&::-webkit-scrollbar": {width: "12px",},"&::-webkit-scrollbar-thumb": {backgroundColor: "rgb(193,193,193)",borderRadius: "6px",},}}>
                        <Grow in={zoomimagemodal["lg"]}>
                        <div style={{fontFamily:"Poppins"}} className="w-full h-full">
                            {()=>setzoomimagemodal({...zoomimagemodal,lg:false})}
                            <div className="flex justify-between items-center">                                
                                <div className="flex gap-[24px] p-[2%]">
                                 <div id="activeeffect" onClick={()=>setzoomimagemodal({...zoomimagemodal,lg:false})} 
                                 className="cursor-pointer flex gap-[8px] items-center px-[32px] py-[15px] bg-black text-white text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-white hover:text-black">
                                 {/* <img src={modalclose}></img> */}
                                 <p>CLOSE</p>
                                    </div>
                                </div>
                            </div>
                            {setzoomimagemodal}
                            {<iframe style={{width:"85%", height: "80%"}} className= "pl-[18%]" src= {urlForPropertyVideo} title="video" allowFullScreen/>}                    
                        </div>
                        </Grow>
                    </Box>
                </Modal>
            </div>
            <div className="lg:hidden sm:block">
            <Modal style={{ width: "100%", height: "100%", display:zoomimagemodal["sm"]?"block":"none" }}
                    open={zoomimagemodal["sm"]}
                    onClose={()=>setzoomimagemodal({...zoomimagemodal,sm:false})}  
                >
            
            <Box sx={{height:"85%", backgroundColor: "grey", borderRadius: "10px",}}>
                    <Grow in={zoomimagemodal["sm"]}>
                    <div className='w-[100%] h-[100%]'>
                                <div id='activeeffect' onClick={()=>setzoomimagemodal({...zoomimagemodal,sm:false})} className='pt-[8px] pl-[8px] pb-[8px] w-[100%] flex font-medium'>
                                    <img src={BBack}/>
                                    </div>
                                    {<iframe style={{width:"100%", height: "100%"}} src= {urlForPropertyVideo} title="video" allowFullScreen/>}
                                
                                 </div>
                    </Grow>
                </Box>
            </Modal>
            </div>
        </div>
    )
}
export default ModalForPropertyVideo