import Add from "../Assets/Add.png"
import Sub from "../Assets/Sub.png"
const CounterCard=({title,count,setcount, maxAdult=50})=>
{
    let countindividual=title==="Adults"?count.adults:count.children
    const handleAdd=()=>
    {
        title==="Adults"?setcount({...count,adults:count.adults+1}):setcount({...count,children:count.children+1})
        title==="Adults"?localStorage.setItem("adults",count.adults+1):localStorage.setItem("children",count.children+1)
    }
    const handleSubtract=()=>
    {
        title==="Adults"?setcount({...count,adults:count.adults-1}):setcount({...count,children:count.children-1})
        title==="Adults"?localStorage.setItem("adults",count.adults-1):localStorage.setItem("children",count.children-1)
    }
    return(
        <div className="flex w-full gap-[15px] items-center justify-between">
            <div className="w-[50%]">
                <p className="font-[500]">{title}</p>
                <p style={{display:title=="Adults"?"none":"block"}} className="text-[11px] text-[#565656] font-[400] leading-[13px]">Below 18 age group</p>
            </div>
            <div className="w-[50%] flex gap-[15px] items-center">
                <button disabled={countindividual==0?true:false} onClick={handleSubtract} className="cursor-pointer"><img src={Sub}/></button>
                <p>{countindividual}</p>
                <button disabled={countindividual == maxAdult ? true : false} className="cursor-pointer" onClick={handleAdd}><img src={Add}/></button>
            </div>
        </div>
    )
}
export default CounterCard