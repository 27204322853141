const NotFound=()=>
{
    return(
        <div style={{ boxShadow: "0px 10px 27px 0px rgba(0, 0, 0, 0.15)", fontFamily: "Inter" }} className="bg-[#FFF9F2] text-center lg:my-[40px] sm:my-[20px] sm:px-[16px] sm:py-[50px] lg:px-[100px] lg:pt-[120px] lg:pb-[100px] w-[80%] m-[auto]">
            <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[46px] lg:text-[60px]  font-[600] sm:leading-[62px] lg:leading-[80px] mb-[35px]">Error: 404, Not Found</p>
            
        </div>

    )
}
export default NotFound