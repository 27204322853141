import { Box, Button, Fade, Grow, Modal, Popover, Rating, Typography } from "@mui/material"
import NavButton from "../../Utils/SignButton"
import modalclose from "../../Assets/modalclose.png"
import deleteicon from "../../Assets/delete.svg"
import BBack from "../../Assets/BBack.png"
import Dots from "../../Assets/Dots.svg"
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateReview, writeReview } from "../../Redux/Actions/api"

const ReviewModal=(props)=>
{
    const{bookingdata,setopensuccessmodalupdate,setopensuccessmodal,setsubmitreview, submitreview, zoomimagemodal,setzoomimagemodal,booking_id}=props
    const [value, setValue] = useState(bookingdata?.review_allowed?.review?.rating||0);
    const[count,setcount]=useState(bookingdata?.review_allowed?.review?.review.length||0)
    const[selectedfile,setselectedfile]=useState(bookingdata?.review_allowed?.review?.images||[])
    const[alldeleteimageid,setalldeleleteimageid]=useState([])
    const[url,seturl]=useState("")
    const [anchorEl, setAnchorEl] = useState(null);
    const[selectedindex,setselectedindex]=useState(null)
    const[inputreview,setinputreview] = useState(bookingdata?.review_allowed?.review?.review||"")
    const fileinputref=useRef(null)
    const deletedimageid=alldeleteimageid

    const handleClick =(index)=> (event) => {
        setselectedindex(index)
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setselectedindex(null)
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    const handleFileChange = (event) => {
        
        const file = event.target.files;
        const maxsize=10*1024*1024
        // const url=seturl(file)
        for(let key in file)
        {
            if(typeof file[key]=="object" && file[key]["size"]>maxsize)
            {
                toast.error("The file exceeds maximum size")
                return
            }
        }
        let uploadedfiles=[...selectedfile]
        let filestosend=[]
        for(let key in file)
        {
            if(typeof file[key]=="object")
            {
                filestosend.push(file[key])
                let value=URL.createObjectURL(file[key])
                uploadedfiles.push({"image":value})
            }
        }
        seturl(filestosend)
        setselectedfile(uploadedfiles)
        // setselectedfile(URL.createObjectURL(file));
      };
      const deleteimage=(index)=>
      {
        let arrurls=[...url]
        arrurls.splice(index,1)
        seturl(arrurls)
        let arr=selectedfile.filter((e,i)=>i!==index)
        setselectedfile(arr)
        if(fileinputref.current)
        {
            fileinputref.current.value=""
        }
        
        handleClose()
      }
      const deleteimagesforupdate=(index,id)=>
      {
        let arrurls=[...url]
        let originalimagelength=bookingdata?.review_allowed?.review?.images?.length
        arrurls.splice(index-originalimagelength,1)
        seturl(arrurls)
        let arr=selectedfile.filter((e,i)=>i!==index)
        setselectedfile(arr)
        if(fileinputref.current)
        {
            fileinputref.current.value=""
        }
        deletedimageid.push(id)
        setalldeleleteimageid(deletedimageid)
        handleClose()
      }
      let fd=new FormData()
      const handleupdateReview=()=>
      {
        let body={
            "booking_id":booking_id,
            "rate":value,
            "review":inputreview,
            "remove_image_files":JSON.stringify(alldeleteimageid)
        }
        let apiData={...body}
        for (let pair of Object.entries(apiData)) {
            if (pair[1] !== null) fd.append(pair[0], pair[1]);
          }
        for(const image of url)
        {
            fd.append("image_files",image)
        }
        // for(let i=0;i<alldeleteimageid.length;i++)
        // {
        //     fd.append("remove_image_files",alldeleteimageid[i])
        // }
        updateReview({
            body:fd,
            headers: {
                "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
              }
        }).then((res)=>{
            setzoomimagemodal(false)
           setsubmitreview(!submitreview)
           setopensuccessmodalupdate(true)
        }).catch((err)=>{
            toast.error("Something went wrong")
        })
      }
      const handleSubmit=()=>
      {
        let body={
            "booking_id":booking_id,
            "rate":value,
            "review":inputreview
        }
        let apiData={...body}
        for (let pair of Object.entries(apiData)) {
            if (pair[1] !== null) fd.append(pair[0], pair[1]);
          }
        for(const image of url)
        {
            fd.append("image_files",image)
        }
          
        writeReview({
            body:fd,
            headers: {
                "Content-Type":
                  "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
              }
        }).then((res)=>{
            setzoomimagemodal(false)
           setsubmitreview(!submitreview)
           setopensuccessmodal(true)
        }).catch((err)=>{
            toast.error("Something went wrong")
        })
      }

    return(
        <Modal className="modalforreview" style={{ margin: "auto",display:zoomimagemodal?"block":"none" }}
                    open={zoomimagemodal}
                    onClose={()=>setzoomimagemodal(false)}  
        >
            <Box sx={{height:"100%",overflow:"auto",backgroundColor:"white"}}>
                <Grow in={zoomimagemodal}>
                <div style={{fontFamily:"Inter"}} className="sm:px-[9px] sm:py-[11px] lg:px-[70px] lg:py-[50px]">
                    <div className="flex lg:justify-between items-center sm:gap-[8px] lg:gap-[0px] items-center sm:mb-[14px] lg:mb-[40px]">
                        <div onClick={()=>setzoomimagemodal(false)} className="sm:block lg:hidden">
                            <img src={BBack}/>
                        </div>
                        <div>
                            <p style={{fontFamily:"Playfair Display"}} className="sm:text-[20px] lg:text-[34px] font-[600] leading-[45px] text-[#0C0C0C]">Write a Review</p>
                            
                        </div>
                        <div id="activeeffect" onClick={()=>setzoomimagemodal(false)} className="sm:hidden cursor-pointer lg:flex gap-[8px] items-center border border-[2px] border-[#C69130] px-[32px] py-[15px] bg-white text-[#46484D] text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#C69130]">
                                <img src={modalclose}></img>
                                <p>CLOSE</p>
                        </div>
                    </div>
                    <hr className="text-[#E6E6E6] sm:mb-[25px] lg:mb-[40px]" />
                    <p style={{fontFamily:"Playfair Display"}} className="sm:text-[16px] lg:text-[25px] font-[600] leading-[45px] text-[#0C0C0C]">Rate the property</p>
                    <div className="flex sm:justify-start lg:justify-center">
                        <Rating value={value} onChange={(event, newValue) => {
                        setValue(newValue);
                        }} size="large" precision={1}></Rating>
                    </div>
                    <div>
                        <div className="mt-[40px] mb-[10px] flex justify-between">
                            <p className="text-[#282827] sm:text-[14px] lg:text-[16px] font-[600] leading-[20px] ">Review</p>
                            <p className="text-[#979797] sm:text-[12px] lg:text-[14px] font-[400] leading-[20px]">{count}/10,000 characters</p>
                        </div>
                        <textarea wrap="soft" onChange={(e)=>{
                            setcount(e.target.value.length)
                            setinputreview(e.target.value)
                        }} defaultValue={bookingdata?.review_allowed?.review?.review} className="w-full px-[20px] py-[15px] sm:text-[12px] lg:text-[14px] text-[#979797] font-[400] bg-[#F5F6FA] leading-[17px]" placeholder="Write your review"  maxLength="10000"></textarea>
                    </div>
                    <p className="text-[#282827] sm:text-[14px] lg:text-[16px] font-[600] leading-[20px] sm:mt-[20px] lg:mt-[30px] mb-[10px]">Photo</p>
                    <div className="flex gap-[20px] items-center">
                        <Button className="uploadbutton" variant="contained" component="label" style={{backgroundColor:"#27AC5D",borderRadius:"35px",color:"white",padding:"12px 20px",fontWeight:"600",fontFamily:"Inter"}}>
                            Upload Photos
                            <input ref={fileinputref} onChange={handleFileChange} hidden accept="image/*" multiple type="file" />
                        </Button>
                        <p className="text-[14px] font-[400] text-[#979797] leading-[17px]">Attachment (10MB limit)</p>
                    </div>
                    <div className="grid sm:grid-cols-3 lg:grid-cols-4 h-full mt-[20px] gap-[11px]">
                        {
                            selectedfile?.map((e,i)=>
                            {
                                return (
                                    <div className="relative" key={i}>
                                        <img className="w-full h-full object-cover" src={e?.image} />
                                        <img onClick={handleClick(i)} className="cursor-pointer absolute top-0 right-0 mt-[10px] mr-[10px]" src={Dots} />
                                        <Popover
                                            id={id}
                                            open={selectedindex===i}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                            }}
                                        >
                                            <div onClick={()=>{
                                                bookingdata?.review_allowed?.review?deleteimagesforupdate(i,e?.id):deleteimage(i)
                                            }}>
                                                <Typography sx={{ p: 2,display:"flex",alignItems:"center",gap:"10px",cursor:"pointer" }}>
                                                    <img src={deleteicon} />
                                                    <p>Remove photo</p>
                                                </Typography>
                                            </div>
                                        </Popover>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex gap-[30px] mt-[60px] sm:justify-center lg:justify-end" style={{fontFamily:"Inter"}}>
                        <button onClick={()=>setzoomimagemodal(false)} id="activeeffect" className="hover:bg-[#976E23] hover:text-white px-[35px] py-[15px] border-2 border-[#C69130] sm:text-[14px] lg:text-[16px] text-[#C69130] font-[700] tracking-[0.8px] leading-[20px]">CANCEL</button>
                        <button onClick={bookingdata?.review_allowed?.review?handleupdateReview:handleSubmit} id="activeeffect" className="hover:bg-[#976E23] px-[35px] py-[15px] text-white sm:text-[14px] lg:text-[16px] bg-[#C69130] font-[700] tracking-[0.8px] leading-[20px]">SUBMIT</button>
                    </div>
                </div>
                </Grow>
            </Box>

        </Modal>
    )
}
export default ReviewModal