import { Avatar, Badge, debounce } from "@mui/material";
import TitleBar from "../TitleBar";
import { useEffect, useRef, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import editprofilepic from "../../Assets/editprofilepic.png";
import pencil from "../../Assets/pencil.svg";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";

import * as yup from "yup";
import NavButton from "../../Utils/SignButton";
import { useNavigate } from "react-router";
import { GetUserDetails, UpdateUserDetails } from "../../Redux/Actions/api";
import { toast } from "react-toastify";
import Loading from "../../Utils/Loading";
import dateFormat from "dateformat";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: "pointer",
    borderRadius: "50%",
  },
  input: {
    display: "none",
  },
}));

const schema = yup.object().shape({
  phone: yup
    .string()
    .matches(/^\d+$/, "Phone number must only contain digits")
    .max(10, "Phone number must be of 10 digits")
    .min(10, "Phone number must be of 10 digits")
    .required("Phone Number is required"),

  name: yup
    .string()
    .matches(/^[a-z ,.'-]+$/i, "Name must not contain special characters")
    .required("Name is required"),

  email: yup
    .string()
    .required("Email is required")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email"),
});

const MyProfile = () => {
  const today = new Date().toISOString().split("T")[0];
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState("");
  const [userdetails, setuserdetails] = useState([]);
  const fileInputRef = useRef(null);
  const [isloading, setisloading] = useState(true);
  const [count, setcount] = useState("0");
  const [url, seturl] = useState("");
 let toastId = null
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    const url = seturl(file);
    setSelectedFile(URL.createObjectURL(file));
  };
  const initialValues = {
    name: userdetails?.name ? userdetails?.name : "",
    phone: userdetails?.mobile_number ? userdetails?.mobile_number : "",
    email: userdetails?.email ? userdetails?.email : "",
    dob:
      userdetails?.dob != "None"
        ? dateFormat(userdetails?.dob, "yyyy-mm-dd")
        : "",
    gender: userdetails?.gender ? userdetails?.gender : "",
  };
  const handleAvatarClick = (event) => {
    fileInputRef.current.click();
  };

  const navigate = useNavigate();
  let fd = new FormData();
  const formik = useFormik({
    initialValues,
    validationSchema: schema,
  });
  const handleSubmit = debounce((values) => {
    let apiData = { ...values, profile_pic: url };
    const fd = new FormData();

    if (typeof apiData.profile_pic === "string") {
      delete apiData.profile_pic;
    } else {
      fd.append("profile_pic", apiData.profile_pic);
      apiData.profile_pic = apiData.profile_pic;
    }

    for (let pair of Object.entries(apiData)) {
      if (pair[1] !== null) fd.append(pair[0], pair[1]);
    }

    UpdateUserDetails({
      body: fd,
      id: userdetails?.id,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW;",
      },
    })
      .then((res) => {
        console.log(res);
        setuserdetails(res);
        let completedcount = 0;
        let totalcount = 0;

        for (let key in res) {
          totalcount += 1;
          if (
            key === "profile_pic" &&
            res[key] === "https://brikkit-prod-docs.s3.amazonaws.com/media/"
          ) {
            completedcount += 0;
          } else if (res[key]) {
            completedcount += 1;
          }
        }

        setcount((completedcount / totalcount) * 100);
        toast.success("User Profile Successfully Updated");
      })
      .catch((err) => {
       
        if (toastId) {
            toast.update(toastId, { render: "Something went wrong", type: toast.TYPE.ERROR });
        } else {
            toastId = toast.error("Something went wrong", {
                onClose: () => (toastId = null) // Reset toastId when toast is closed
            });
        }
      });
  }, 900);
  const handleReset = () => {
    formik.resetForm();
  };

  useEffect(() => {
    setisloading(true);
    GetUserDetails()
      .then((res) => {
        setisloading(false);
        setuserdetails(res);
        if (
          res?.profile_pic !=
          "https://brikkit-prod-docs.s3.amazonaws.com/media/"
        ) {
          setSelectedFile(res.profile_pic);
        }
        let completedcount = 0;
        let totalcount = 0;
        for (let key in res) {
          totalcount += 1;
          if (
            key == "profile_pic" &&
            res[key] == "https://brikkit-prod-docs.s3.amazonaws.com/media/"
          ) {
            completedcount += 0;
          } else if (res[key]) {
            completedcount += 1;
          }
        }
        setcount((completedcount / totalcount) * 100);
      })
      .catch((err) => {
        setisloading(false);
        toast.error("Something went wrong");
      });
  }, []);

  return isloading ? (
    <Loading />
  ) : (
    <Fade>
      <div>
        <TitleBar text="My Profile" />
        <div
          style={{ fontFamily: "Inter" }}
          className=" bg-[#FFF9F2] lg:px-[270px] lg:py-[27px]"
        >
          <div className="flex flex-col  relative">
            <div className="bg-[#C69130] sm:px-[13px] lg:px-[52px] sm:pt-[16px] sm:pb-[53px] lg:py-[34px]">
              <p className="flex justify-end text-[15px] font-[600] leading-[21px] tracking-[0.6px] text-white">
                {parseInt(count)?.toFixed(0)}% Profile Complete
              </p>
            </div>
            <div className="absolute top-0 left-0 sm:mt-[30px] lg:mt-[10px] ml-[10px] w-full h-full ">
              <input
                accept="image/*"
                className={classes.input}
                ref={fileInputRef}
                type="file"
                onChange={handleFileChange}
              />
              <label className="w-full h-full" htmlFor="profile-image-input">
                {/* <img
                            variant="soft"
                            src={selectedFile}
                            className="cursor-pointer rounded-full lg:w-[160px] lg:h-[160px] sm:w-[115px] sm:h-[115px]"
                            
                            /> */}
                <div
                  onClick={handleAvatarClick}
                  className=" cursor-pointer lg:w-[160px] lg:h-[160px] sm:w-[115px] sm:h-[115px]"
                >
                  <Badge
                    style={{ width: "100%", height: "100%" }}
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <Avatar
                        sx={{ width: "25px", height: "25px" }}
                        src={pencil}
                      />
                    }
                  >
                    <Avatar
                      sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                      src={selectedFile}
                    />
                  </Badge>
                </div>
              </label>
            </div>
            <div className="bg-[#0F0E0A] flex flex-col gap-[12px] w-full h-full sm:pl-[148px] lg:pl-[200px] sm:pt-[9px] sm:pb- lg:pt-[25px] sm:pb-[33px] lg:pb-[30px]">
              <p className="text-[18px] text-white font-[400] leading-[22px]">
                {userdetails?.name}
              </p>
              <p className="text-[14px] opacity-50 text-white font-[400] leading-[17px]">
                {userdetails?.mobile_number}
              </p>
            </div>
          </div>

          <div className="lg:mt-[25px] bg-white sm:p-[16px] lg:p-[40px]">
            <p
              style={{ fontFamily: "Playfair Display" }}
              className="text-[#0C0C0C] sm:text-[18px] lg:text-[25px] font-[600] leading-[33px] sm:mb-[20px] lg:mb-[40px]"
            >
              Personal Information
            </p>
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                <Form
                  style={{ fontFamily: "Inter" }}
                  className="sm:text-[14px] lg:text-[16px] leading-[20px] tracking-[3%] font-[400] "
                >
                  <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-[30px]">
                    <div>
                      <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px]">
                        FULL NAME<span className="text-[#FF5656]">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="name"
                        className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                        placeholder="Enter Full Name"
                        required
                      />
                      <ErrorMessage
                        className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                        name="name"
                        component="div"
                      />
                    </div>

                    <div>
                      <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px]">
                        MOBILE NUMBER<span className="text-[#FF5656]">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="phone"
                        className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                        placeholder="Mobile number"
                        required
                      />
                      <ErrorMessage
                        className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                        name="phone"
                        component="div"
                      />
                    </div>

                    <div>
                      <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px] ">
                        EMAIL ID<span className="text-[#FF5656]">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="email"
                        className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                        placeholder="Enter Email ID"
                        required
                      />
                      <ErrorMessage
                        className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                        name="email"
                        component="div"
                      />
                      {/* <p className="text-[#565656] text-[12px] font-[400] leading-[15px] mt-[9px]">We’ll send your booking information on your mobile & email.</p> */}
                    </div>

                    <div>
                      <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px] ">
                        DATE OF BIRTH
                      </label>
                      <br />
                      <Field
                        type="date"
                        name="dob"
                        className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]"
                        placeholder="Enter your DOB"
                        max={today}
                      />
                      <ErrorMessage
                        className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                        name="dob"
                        component="div"
                      />
                      {/* <p className="text-[#565656] text-[12px] font-[400] leading-[15px] mt-[9px]">We’ll send your booking information on your mobile & email.</p> */}
                    </div>

                    <div>
                      <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px] ">
                        GENDER
                      </label>
                      <br />
                      <div className="flex itmes-center gap-[55px] mt-[7px]">
                        <label className="flex gap-[10px] items-center">
                          <Field
                            type="radio"
                            name="gender"
                            className="bg-[#F5F6FA] w-full"
                            value="Male"
                          />
                          <p className="text-[#0C0C0C] font-[400] sm:text-[14px] lg:text-[16px] tracking-[0.48px] leading-[20px]">
                            Male
                          </p>
                        </label>
                        <label className="flex gap-[10px] items-center">
                          <Field
                            type="radio"
                            name="gender"
                            className="bg-[#F5F6FA] w-full"
                            value="Female"
                          />
                          <p className="text-[#0C0C0C] font-[400] sm:text-[14px] lg:text-[16px] tracking-[0.48px] leading-[20px]">
                            Female
                          </p>
                        </label>
                        <label className="flex gap-[10px] items-center">
                          <Field
                            type="radio"
                            name="gender"
                            className="bg-[#F5F6FA] w-full"
                            value="Other"
                          />
                          <p className="text-[#0C0C0C] font-[400] sm:text-[14px] lg:text-[16px] tracking-[0.48px] leading-[20px]">
                            Other
                          </p>
                        </label>
                      </div>
                      <ErrorMessage
                        className="text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]"
                        name="gender"
                        component="div"
                      />
                      {/* <p className="text-[#565656] text-[12px] font-[400] leading-[15px] mt-[9px]">We’ll send your booking information on your mobile & email.</p> */}
                    </div>
                  </div>
                  <div className="w-full flex gap-[40px] mt-[40px]">
                    <button
                      type="submit"
                      className="cursor-pointer px-[38px] py-[15px] bg-[#C69130] text-white text-[14px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#976e23]"
                    >
                      SAVE
                    </button>
                    <button
                      type="reset"
                      id="activeeffect"
                      onClick={handleReset}
                      className="cursor-pointer border border-[2px] border-[#C69130] px-[30px] py-[15px] bg-white text-[#C69130] text-[14px] font-[600] leading-[20px] tracking-[1.5px]"
                    >
                      <p>CANCEL</p>
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};
export default MyProfile;
