import { useState, useEffect, Component } from "react";
import { useSelector } from "react-redux";
import "./HTMLContentStyle.css";
import LeftScroll from "../../Assets/leftImage.png";
import RightScroll from "../../Assets/rightImage.png";
import ZoomIn from "../../Assets/zoomin.png";
import ZoomOut from "../../Assets/zoomout.png";
import ResetZoom from "../../Assets/resetzoom.png";
import modalclose from "../../Assets/modalclose.png";
import { Grow, Modal, Box } from "@mui/material";
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ModalForImageSlideShow = ({ zoomimagemodal, setzoomimagemodal, selectedImageIndex }) => {
  let storedata = useSelector((storedata) => storedata);
  let { propertydetails } = storedata?.propertyreducer;
  let { brik_image } = propertydetails;

  const [currentSlide, setCurrentSlide] = useState(selectedImageIndex);
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    setCurrentSlide(selectedImageIndex);
    resetZoom(); // Reset zoom when selected image index changes
  }, [selectedImageIndex]);

  const resetZoom = () => {
    setZoomLevel(1);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? brik_image.length - 1 : prevSlide - 1));
    resetZoom(); // Reset zoom when navigating to the previous slide
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === brik_image.length - 1 ? 0 : prevSlide + 1));
    resetZoom(); // Reset zoom when navigating to the next slide
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.5);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(1, prevZoomLevel - 0.5));
  };

  const handleCloseModal = () => {
    setzoomimagemodal({ ...zoomimagemodal, lg: false });
    resetZoom();
  };

  if (!brik_image || brik_image.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="lg:block sm:hidden">
        <Modal style={{ width: "100%", height: "100%", margin: "auto", display: zoomimagemodal["lg"] ? "block" : "none" }}
          open={zoomimagemodal["lg"]} onClose={handleCloseModal}>
          <Box sx={{ overflow: "auto",height: "100%",backgroundColor: "black"}}>
            <Grow in={zoomimagemodal["lg"]}>
              <div style={{ fontFamily: "Poppins" }} className="bg-black px-[50px] py-[30px] w-full h-full">
                                  
                    <div id="activeeffect" onClick={handleCloseModal} 
                    className="cursor-pointer flex gap-[8px] items-center px-[32px] py-[15px] bg-black text-white 
                    text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-white hover:text-black hover:w-[8%] z-80">
                      <p>CLOSE</p>
                    </div>
                    
                 
                  <div className="lg:block sm:hidden w-[100%]" style = {{display: "flex", justifyContent: "center"}}>
                  <div className="mt-[3%] relative">
                    <TransformWrapper
                      defaultScale={1}
                      defaultPositionX={0}
                      defaultPositionY={0}
                      options={{ limitToBounds: false, transformEnabled: true, maxScale :2, zoomInByDoubleTap: false, doubleClickZoom: false,
                      doubleTapZoomOutOnMaxScale: false, doubleTapZoomInOnMaxScale: false,}}>
                      {({ zoomIn, zoomOut, ...rest }) => (
                        <>
                          <TransformComponent>
                            <img className="" src={brik_image[currentSlide]?.image} style={{ transform: `scale(${zoomLevel})`, height: '500px', width: '100%' }} 
                            onLoad={() => {setZoomLevel(1);}}
                            onDoubleClick={(e) => {e.preventDefault(); e.stopPropagation();}}/>
                         </TransformComponent>                          
                    </>
                      )}                      
                    </TransformWrapper>
                    <div className="flex flex-col z-80 absolute bottom-0 right-0">  
                  <div id="activeeffect" onClick={handleZoomIn} className="cursor-pointer flex gap-[2px] items-center bg-white text-black leading-[20px] tracking-[1.5px]">
                      <img src={ZoomIn} width="30px" height="30px"/>
                    </div>
                    <div id="activeeffect" onClick={handleZoomOut} className="cursor-pointer flex gap-[2px] items-center bg-white text-black leading-[20px] tracking-[1.5px]">
                      <img src={ZoomOut} width="30px" height="30px"/>
                </div>
                    <div id="activeeffect" onClick={resetZoom} className="cursor-pointer flex gap-[2px] items-center bg-white text-black leading-[20px] tracking-[1.5px]">
                      <img src={ResetZoom} width="30px" height="30px"/>
                </div>
                </div>
                  </div>
                  
                  
                   <div className="w-full absolute top-[50%] z-10 flex justify-between">
                      <div id="activeeffect" className="left-0" onClick={handlePrevSlide}> <img src={LeftScroll} />
                      </div>
                      <div id="activeeffect" className="right-0" onClick={handleNextSlide}> <img src={RightScroll} />
                      </div>
                    </div>
                
                </div>
              </div>
            </Grow>
          </Box>
        </Modal>
      </div>
    </div>
  );
};
export default ModalForImageSlideShow;


