const ImageTextCard=({url,text,type,className,onclick, imgClassName})=>
{
    return(
        <div onClick={onclick} style={{fontFamily:"Poppins"}} className={type=="sort"?"flex cursor-pointer flex-row-reverse sm:gap-[5px] lg:gap-[20px] items-center":"flex gap-[20px] items-center"}>
            <div className="flex flex-col justify-center items-center">
                <img src={url} className={imgClassName} />
            </div>
            {
                type=="sort"?
                <p className={`text-[#656565] sm:text-[12px] lg:text-[16px] font-[400] w-full ${className}`}>{text}</p>
                :
                <p className={`text-[#656565] sm:text-[16px] lg:text-[16px] font-[400] w-full ${className}`}>{text}</p>
            }
            
        </div>
    )
}
export default ImageTextCard