import axios from "axios"
import {config as CONFIG} from "./Config"

const myInstance=axios.create()

const http = (method, endpoint, body, { isAuthenticated, ...customConfig } = {}) => {
    // const baseURL = process.env.REACT_APP_BASE_URL
    const baseURL = 'https://owners.brikitt.com/api/v1/'
    // const baseURL = 'http://localhost:8000/api/v1/'
    const headers = {
        // "Content-Type": "application/json",
        // "Accept": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept"
    }

    const token = localStorage.getItem('token')
    
    if (isAuthenticated) {
        headers.Authorization = `Token ${token}`
    }


    console.group("REQUEST_GROUP")
    console.log("method: ", method)
    console.log("endpoint: ", endpoint)
    console.log(body ? body : {})

    console.groupEnd("REQUEST_GROUP")

    const config = {
        method,
        baseURL,
        url: endpoint,
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers
        },
        responseType: "json",
    }

    if(body instanceof FormData){
        config.data = body
    }else
        config.data = {
            ...body
        }

    return myInstance(config).then((response, status) => {
        if (CONFIG.ENABLE_API_LOGS) {
            console.group("RESPONSE_GROUP")
            console.log("status: ", status)
            console.log(response)
            console.groupEnd("RESPONSE_GROUP")
        }

        return Promise.resolve(response)
    }).catch((er) => {

        if (CONFIG.ENABLE_API_LOGS) {
            console.group("RESPONSE_ERROR_GROUP")
            console.log(er)
            console.groupEnd("RESPONSE_ERROR_GROUP")
        }

        return Promise.reject(er)
    })
}


export default http;

