import { useRef } from "react"
import ContactUs from "../ContactUs"
import Footer from "../Footer"
import NavbarAll from "../NavbarAll"
import About from "./About"
import NavScroll from "./NavScroll"

const PropertyDetail=()=>
{
    const propdetailnavbar=[
        {"About":useRef(null),
        "RoomAmenities":useRef(null),
        "AddOn":useRef(null),
        "PetsAllowed":useRef(null),
        "Review":useRef(null),
        "HotelRules":useRef(null),
        "Location":useRef(null),
        "MoreProperties":useRef(null),
         "SafetyAndProperty":useRef(null),
         "CancellationPolicy":useRef(null),
         "PetsFriendly":useRef(null),
         "ThingsToKnow":useRef(null),
        
        }
    ]
    return(
        <div>
            <NavScroll propdetailnavbar={propdetailnavbar} />
            <About propdetailnavbar={propdetailnavbar} />
            
        </div>
    )
}
export default PropertyDetail