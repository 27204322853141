import homebg from "../../Assets/homebg.png";
import InputSelector from "../../Utils/InputSelector";
import MUIButon from "../../Utils/MUIButton";
import NavButton from "../../Utils/SignButton";
import Calendar from "./Calendar";
import dateFormat from "dateformat"
import search from "../../Assets/search.png"
import CalendarCombined from "../../Utils/CalendarCombined";
import MembersCount from "../../Utils/MembersCount";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../UserContext";
// import PlaceSelector from "../../Utils/PlaceSelector";
import PlaceSelectorTemp from "../../Utils/Placeselectortemp";
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from "@mui/material";


const BookingStay=()=>
{
   const[memberdisplay,setmemberdisplay]=useState(false)
   const {count,setcount,date,place,searchdata,setsearchdata}=useContext(UserContext)
//    const[count,setcount]=useState({adults:1,children:1})
    const checkin=dateFormat(date.checkin,'yyyymmdd')
    const checkout=dateFormat(date.checkout,'yyyymmdd')
    localStorage.setItem("adults",count.adults)
    localStorage.setItem("children",count.children)
    localStorage.setItem("place",place)
        
   const navigate=useNavigate()
    return(
        <div className="flex flex-col items-center sm:px-[16px] lg:px-[50px] sm:py-[52px] lg:py-[180px] relative" style={{backgroundImage:`url(${homebg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
            <p id="maintitle" style={{fontFamily:"Inter"}} className="lg:text-[76px] sm:text-[30px] text-white sm:leading-[30px] lg:leading-[115px] text-center sm:tracking-[1.04px] lg:tracking-[1px]"><span id="span1" className="lg:font-[400]">Book </span><span id="span2" className="lg:font-[400]">Your </span><span id="span3" className="lg:font-[400]">Stay</span></p>
            <p id="maintitle" style={{fontFamily:"Inter"}} className="lg:text-[37px] sm:text-[12px] text-white sm:leading-[20px] lg:leading-[63px] text-center sm:tracking-[1.04px] lg:tracking-[4px]"><span className="font-[400]">With </span><span className="lg:font-[700] sm:font-[400]">BRIK</span><span className="sm:font-[400] lg:font-[300]"> itt </span></p>
            <div style={{fontFamily:"Inter"}} className="w-full flex sm:flex-col lg:flex-row items-center sm:gap-[18px] lg:gap-[10px] sm:mt-[33px] lg:mt-[85px]">
                <PlaceSelectorTemp label="Where" text="ALL" gap="100px" />
                <CalendarCombined type="multiple" />
                
                {/* <InputSelector label="Room" text={1} gap="25px" /> */}
                <InputSelector label= "Guests" text={`ADULTS ${count.adults} CHILDREN ${count.children}`} gap="10px" memberdisplay={memberdisplay} setmemberdisplay={setmemberdisplay} />
                <MembersCount/>
                {/* <Box sx={{ backgroundColor:"#C69130",height:"100%" }}>
                <LoadingButton loading="true" variant="outlined"></LoadingButton>
                </Box> */}
                <button id="activeeffect" onClick={()=>{
                    setsearchdata(true)
                    setTimeout(() => {
                        navigate(`/search/?q=${place}&date_check_in=${checkin}&date_check_out=${checkout}&children=${count.children}&adult=${count.adults}&page=1&perPage=4`)
                        setsearchdata(false)
                    }, 2000);
                    
                    }} className="lg:block sm:hidden rounded-xl transition ease-in-out delay-150 hover:bg-[#976E23] bg-[#C69130] w-[30%] h-full flex flex-col items-center px-[20px] py-[21px] text-white text-[16px] font-[700] leading-[19px] tracking-[0.8px]">
                    {searchdata?
                    <Box sx={{height:"40px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <LoadingButton color="secondary" size="large" loading="true"></LoadingButton>
                    </Box>:
                    <img src={search}/>
                    }
                </button>
                <button id="activeeffect" onClick={()=>{
                    setsearchdata(true)
                    setTimeout(() => {
                        navigate(`/search/?q=${place}&date_check_in=${checkin}&date_check_out=${checkout}&children=${count.children}&adult=${count.adults}&page=1&perPage=4`)
                        setsearchdata(false)
                    }, 2000);
                    
                    }} className="lg:hidden sm:block rounded-lg transition ease-in-out delay-150 hover:bg-[#976E23] bg-[#C69130] w-full h-full flex flex-col items-center py-[12px] text-white text-[14px] font-[600] leading-[30px] tracking-[1.5px]">
                    {searchdata?
                    <Box sx={{height:"40px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <LoadingButton color="secondary" size="large" loading="true"></LoadingButton>
                    </Box>:
                    <p>SEARCH</p>
                    }
                </button>
            </div>
        </div>
    )
}
export default BookingStay