import Bed from "../../Assets/Bed.png"
import Guest from "../../Assets/Guest.png"
import Forest from "../../Assets/Forest.png"
import PropTypes from "../../Utils/PropTypes"
import Rating from '@mui/material/Rating'
import NavButton from "../../Utils/SignButton"
import { useNavigate, useParams } from "react-router-dom"
import smoothScrolltoTop from "../../Utils/Scroll"

const IndividualProp=({url,title,bed,guests,view,desc,rating,reviewcount,price,spv_id,brik_id})=>
{
    const imagetextdata=[
        {"image":Bed,"text":bed},
        {"image":Guest,"text":guests},
        {"image":Forest,"text":view},
    ]
    const navigate=useNavigate()
    return(
        <div style={{ fontFamily: "Inter" }} className="flex lg:flex-row sm:flex-col items-start sm:gap-[10px] lg:gap-[40px]">
            <div id="propertyimage" className="sm:w-full lg:h-[370px] lxl:h-[330px] lg:w-[40%] cursor-pointer" onClick={() => {
                // window.scrollTo({
                //     top: 0,
                //     behavior: 'smooth'
                // });
                smoothScrolltoTop()
                console.log("!!!!!!!", spv_id, brik_id)
                localStorage.setItem("spv_id", spv_id)
                localStorage.setItem("brik_id", brik_id)
                navigate(`/propertydetail/${spv_id}/${brik_id}`);
            }}>
                <img className="w-full h-full object-cover" src={url}/>
            </div>
            <div className="sm:w-full lg:w-[60%]">
                <p style={{ fontFamily: "Inter" }} className="sm:text-[26px] lg:text-[32px] text-[#0C0C0C] font-[600] sm:leading-[30px] lg:leading-[43px] cursor-pointer" onClick={() => {
                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth'
                    // });
                    smoothScrolltoTop()
                    localStorage.setItem("spv_id", spv_id)
                    localStorage.setItem("brik_id", brik_id)
                    navigate(`/propertydetail/${spv_id}/${brik_id}`);
                }} >{title}</p>
                <div className="flex flex-wrap items-center gap-[25px] text-black sm:mt-[30px] sm:mb-[15px] lxl:my-[30px]">
                    {
                        imagetextdata?.map((e,i)=>
                        {
                            return <PropTypes key={i} url={e.image} text={e.text} />
                        })
                    }
                </div>
                <p className="text-[16px] leading-[29px] font-[400] text-[#656565] overflow-ellipsis overflow-hidden max-h-[58px] whitespace-normal" style={{ display: "-webkit-box", WebkitLineClamp: 2 }}>{desc}</p>
                <div className="flex sm:my-[20px] lg:my-[30px] gap-[10px] items-end">
                    <Rating defaultValue={rating} precision={0.5} readOnly/>
                    <p style={{fontFamily:"Jost"}} className="text-[13px] font-[400] text-[#656565] leading-[22px]">{reviewcount} Reviews</p>
                </div>
                <div className="flex justify-between">
                    <NavButton onClick={()=>{
                        localStorage.setItem("spv_id",spv_id)
                        localStorage.setItem("brik_id",brik_id)
                        
                        navigate(`/propertydetail/${spv_id}/${brik_id}`)
                        // window.scrollTo({
                        //     top: 0,
                        //     behavior: 'smooth'
                        // });
                        smoothScrolltoTop()
                    }} name="BOOK NOW" classname="rounded-lg bg-[#C69130] sm:px-[20px] sm:py-[12px] lg:px-[25px] lg:py-[15px] text-white sm:text-[14px] lg:text-[16px] font-[600] leading-[19px] tracking-[1.5px] hover:bg-[#976e23]"/>
                    <div style={{fontFamily:"Inter"}} className="text-[#0C0C0C] px-[11px] py-[8px]">
                        <p className="font-[600] sm:text-[18px] lg:text-[20px] leading-[20px] mb-[6px]">From ₹{price}</p>
                        <p className="font-[400] sm:text-[16px] lg:text-[18px] leading-[15px]">per night</p>
                    </div>
                </div>
                
            </div>
        </div>

    )
}
export default IndividualProp