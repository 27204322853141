import staticdata from "../../Assets/Data"
import AddOnCard from "../../Utils/AddOnCard"
import Label from "../../Utils/Label"
import NormalText from "../../Utils/NormalText"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const AddOns=()=>
{
    let addondata=staticdata[2].addons
    
    return(
        <div style={{fontFamily:"Poppins"}} className="flex flex-col justify-center items-center bg-white sm:px-[16px] lg:px-[100px] sm:py-[30px] lg:pt-[0px] lg:pb-[30px]">
            <Label text="Experience Luxury"/>
            <p className="mt-[25px] sm:px-[16px] lg:px-[180px] sm:text-[14px] lg:text-[20px] text-[#656565] font-[600] sm:leading-[20px] lg:leading-[35px] text-center text-[#656565] z-10">The ultimate leisure for luxury</p>
            <NormalText className="sm:px-[16px] lg:px-[180px]" text="We have handpicked & curated stays designed for the luxury lifestyle living & five star hospitality team management who are 24/7 available on site for the amazing holiday experience"/>
            
            <div className="sm:hidden lg:block w-full h-full z-10 mt-[80px]">
                    <Swiper                
                    modules={[Pagination, Navigation, Autoplay]}
                    className="myswiper"
                    direction='horizontal'
                    pagination= {{
                        el: '.swiper-custom-pagination',
                        clickable:true
                    }}                
                    slidesPerView="3"
                    slidesPerGroup="3"
                    spaceBetween={100}
                    loop="true"
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}>
                
                    {
                        addondata?.map((e,i)=>
                        {
                            return( 
                                <SwiperSlide className='swiper-slide' key={i}>
                                <AddOnCard key={i} url={e.url} title={e.title} text={e.text} highlighter={e.highlighter} />
                            </SwiperSlide>)
                        })
                    }
                    <div className="swiper-custom-pagination"/>
                    </Swiper>
            </div>

            <div className="sm:block lg:hidden w-full h-full z-10 mt-[40px]">
                    <Swiper                
                    modules={[Pagination, Navigation, Autoplay]}
                    className="myswiper"
                    direction='horizontal'
                    pagination= {{
                        el: '.swiper-custom-pagination',
                        clickable:true
                    }}                
                    slidesPerView="1"
                    spaceBetween={20}
                    loop="true"
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}>
                
                    {
                        addondata?.map((e,i)=>
                        {
                            return( 
                                <SwiperSlide className='swiper-slide' key={i}>
                            <AddOnCard key={i} url={e.url} title={e.title} text={e.text} highlighter={e.highlighter} />
                            </SwiperSlide>)
                        })
                    }
                    <div className="swiper-custom-pagination"/>
                    </Swiper>
                    
                
            </div>
        </div>
    )
}
export default AddOns