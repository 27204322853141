import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './Path';
import { useState } from 'react';
import dateFormat from "dateformat"
import UserContext from './Screens/UserContext';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavbarAll from './Screens/NavbarAll';
import ContactUs from './Screens/ContactUs';
import Footer from './Screens/Footer';
import Login from './Screens/Home/Login';
import SignUp from './Screens/Home/SignUp';
import { VerifyOTP } from './Redux/Actions/api';// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { FacebookPixel } from 'react-facebook-pixel';
import React, { useEffect } from 'react';
import { initializeFacebookPixel } from './facebookPixel';

// register Swiper custom elements

function App() {
  useEffect(() => {
    initializeFacebookPixel();
  }, []);

  let currentDate = new Date();

  let tomorrow=currentDate.setDate(currentDate.getDate() + 1);
  let dateformatfun=(date)=>
    {
        return dateFormat(date,'mmmm dd, yyyy')
    }

  let checkin = dateformatfun(new Date()) 
  let checkout = dateformatfun(tomorrow) 
  // localStorage.setItem('checkin', checkin)
  // localStorage.setItem('checkout', checkout)

  const [date, setselectdate] = useState({ "checkin": checkin, "checkout": checkout})
  console.log('setselectdate:', { "checkin": checkin < currentDate ? dateformatfun(new Date()) : checkin || dateformatfun(new Date()), "checkout": checkout < tomorrow ? dateformatfun(tomorrow) : checkout || dateformatfun(tomorrow) })
  const[count,setcount]=useState({adults:+(localStorage.getItem("adults"))||1,children:+(localStorage.getItem("children")||1)})
  const[place,setplace]=useState(localStorage.getItem("place")||"Goa")
  const[roomcount,setroomcount]=useState(1)
  const[opensignup,setopensignup]=useState(false)
  const[openlogin,setopenlogin]=useState(false)
  const[otpdisplay,setotpdisplay]=useState(false)
  const[input,setinput]=useState("")
  const[searchdata,setsearchdata]=useState(false)
  const[code,setcode]=useState("+91")
  register();
  return (
    <div className="App">
      <UserContext.Provider value={
        {searchdata,setsearchdata,date,setselectdate,count,setcount,place,setplace,roomcount,setroomcount,setopensignup,opensignup,openlogin,setopenlogin,otpdisplay,setotpdisplay,input,setinput,code,setcode}
        } >
        <BrowserRouter>
          <NavbarAll />

          <AllRoutes />
          <ContactUs />
          <Footer />
          <ToastContainer autoClose={10000}/>
        </BrowserRouter>
      </UserContext.Provider>

    </div>
  );
}

export default App;

