import ImageTextCard from "./ImageTextCard"
import TV from "../../Assets/TV.png"
import { useState } from "react"

const RoomAmenities=({title,data,type,zoomimagemodal,setzoomimagemodal})=>
{
    const[id,setid]=useState("3")
    return(
        <>
            <div style={{fontFamily:"Poppins"}} className="flex flex-col gap-[25px]">
                <p style={{fontFamily:"Poppins"}} className="text-[#2D2D2D] text-[22px] font-[600] leading-[33px]">{title}</p>
                <div className="grid sm:grid-cols-1 lg:grid-cols-3 sm:gap-[15px] lg:gap-[20px] sm:mb-[30px]">
                    {
                    type=="amenities"?
                    data?.map((e,i)=>
                    {
                        if(i<id)
                        {
                            return <ImageTextCard key={i} url={e?.aminety_icon} text={e?.amenity_feature} imgClassName="h-[35px]" />
                        }
                    })
                    :
                    data?.map((e,i)=>
                    {
                        if(i<id)
                        {
                            return <ImageTextCard key={i} url={e?.add_on_icon} text={e?.add_on_feature} />
                        }
                    })

                    }
                    <div className="sm:hidden lg:block">
                        <div  style={{display:data?.length>=id?"block":"none"}} id="activeeffect" className="">
                            <button onClick={()=>setzoomimagemodal({...zoomimagemodal,lg:true})} className="text-[#D29A67] text-justify font-[600] text-[18px] leading-[21px]">View all</button>
                        </div>
                    </div>
                    
                    <div className="sm:block lg:hidden">
                        <div style={{display:data?.length>=id?"block":"none"}} id="activeeffect" className=" border-[1px] border-[#C69130] rounded-lg">
                            <button onClick={()=>setzoomimagemodal({...zoomimagemodal,sm:true})} className="w-full text-[#D29A67] py-[12px] font-[600] text-[14px] leading-[21px]">View all</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="lg:block sm:hidden text-[#E6E6E6] border-[1px] my-[40px]"/>
        </>
    )
}
export default RoomAmenities