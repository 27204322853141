import { SET_SESSIONID, SET_TOKEN, SET_USER } from "../Constants"

let initialstate = {
    userData: JSON.parse(localStorage.getItem("userData")) || {},
    session_id: localStorage.getItem("session_id") || null,
    token: localStorage.getItem("token") || null
}

const UserReducer = (state = initialstate, action) => {
    switch (action.type) {
        case SET_SESSIONID:
            localStorage.setItem("session_id", action.payload)
            return { ...state, session_id: action.payload }
        case SET_USER:
            localStorage.setItem("userData", JSON.stringify(action.payload))
            return { ...state, userData: action.payload }
        case SET_TOKEN:
            localStorage.setItem("token", action.payload)
            return { ...state, token: action.payload }
        default:
            return state
    }
}

export default UserReducer
