const ImageText=({url,line,className,type,hyperlink})=>
{
    return (
        <div style={{fontFamily:"Inter"}} className={`sm:items-start flex md:items-center gap-[20px] ${className}`}>
            <img className={type=="contactus"?"w-[25px]":""} src={url} />
            {type=="contactus"?
            <a href={hyperlink}>{line}</a>:
            <p>{line}</p>}
        </div>
    )
}
export default ImageText