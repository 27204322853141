export const SET_USER="SET_USER"
export const SET_SESSIONID="SET_SESSIONID"
export const SET_TOKEN="SET_TOKEN"
export const GET_ALL_PROPERTIES="GET_ALL_PROPERTIES"
export const SEARCH_PROPERTIES="SEARCH_PROPERTIES"
export const PROPERTY_DETAIL="PROPERTY_DETAIL"
export const RATING="RATING"
export const UPDATE_CALENDAR_MODAL="UPDATE_CALENDAR_MODAL"
export const UPDATE_COUNT_MODAL="UPDATE_COUNT_MODAL"
export const UPDATE_SEARCH_QUERY="UPDATE_SEARCH_QUERY"
export const HIT_DETAIL_VIEW_API="HIT_DETAIL_VIEW_API"