import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import PropertyReducer from "./PropertyReducer";
import ModalReducer from "./ModalReducer";

const CombineReducer=combineReducers({
    userReducer:UserReducer,
    propertyreducer:PropertyReducer,
    modalreducer:ModalReducer
})
export default CombineReducer