
import Bed from "../Assets/Bed.png"
import Guest from "../Assets/Guest.png"
import Area from "../Assets/Area.png"
import Forest from "../Assets/Forest.png"
import Star from "../Assets/whitestar.png"
import bedprop from "../Assets/bedprop.png"
import guestprop from "../Assets/guestprop.png"
import areaprop from "../Assets/areaprop.png"
import viewprop from "../Assets/viewprop.png"
import PropTypes from "./PropTypes"
import NavButton from "./SignButton"
import { useNavigate } from "react-router-dom"
import smoothScrolltoTop from "./Scroll"
import { Children, useContext, useEffect, useRef, useState } from "react"
import { GetPropertyDetail } from "../Redux/Actions/api"
import UserContext from "../Screens/UserContext"
import dateFormat from "dateformat"
import { toast } from "react-toastify"
const PropCardMain=({url,price,bed,guests,view,area,title,rating,id,spvid,location})=>
{
    const imagetextdata=[
        {"image":Bed,"text":bed},
        {"image":Guest,"text":guests},
        {"image":Area,"text":area},
        {"image":Forest,"text":view},
    ]
    const propdata=[
        {"image":bedprop,"text":bed},
        {"image":guestprop,"text":guests},
        {"image":areaprop,"text":area},
        {"image":viewprop,"text":view},
    ]
    const navigate=useNavigate()
    const [minheight,setminimumheight]=useState('auto')
    const contentRef=useRef(null)
    const {count,setcount,date,place}=useContext(UserContext)
    let adult=count?.adults
    let children=count?.children
    const checkin=dateFormat(date.checkin,'yyyy-mm-dd')
    const checkout=dateFormat(date.checkout,'yyyy-mm-dd')
    useEffect(()=>
    {
        const contentHeight = contentRef.current.offsetHeight;
        setminimumheight(`${contentHeight}px`)

    },[])
    return(
        <div  className="w-full h-full bg-white pb-[12px] mt-[20px] border rounded-xl shadow-md">
            <div>
                <div className="sm:h-[270px] lg:h-[300px] p-[10px] cursor-pointer" onClick={() => {
                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth'
                    // });
                    smoothScrolltoTop()
                    localStorage.setItem("spv_id", spvid)
                    localStorage.setItem("brik_id", id)
                    navigate(`/propertydetail/${spvid}/${id}`);
                }}>
                    <img id="propertyimage" className="w-full h-full rounded-xl object-cover" src={url}/>
                    <div className="flex relative top-[-95%] z-[50] lg:left-[78%] md:left-[83%] sm:left-[83%]">
                    {/* <div style={{ fontFamily: "Inter", background: "rgba(255, 240, 197, 0.79)" }} className=" text-[#0C0C0C] bg-[#FFF0C5] px-[11px] py-[8px] rounded-lg">
                        <p className="font-[600] sm:text-[16px] lg:text-[20px] leading-[20px]">From ₹{price}</p>
                        <p className="font-[400] sm:text-[14px] lg:text-[18px] leading-[15px]">per night</p>
                    </div> */}
                    <div style={{fontFamily:"Poppins"}} className="rounded-xl flex items-center gap-[4px] text-[#2D2D2D] bg-white px-[8px]">
                        <div>
                            <img className="w-[12px] h-[12px]" src={Star}></img>
                        </div>
                        <p className="sm:text-[12px] md:text-[14px] font-[500] text-[#2D2D2D] leading-[29px] tracking-[-2%]">{rating?rating.toFixed(1):"0"} </p>
                       </div>
                </div>
                </div>
                
            </div>
            <div className="px-[12px]">
                <p ref={contentRef} style={{ fontFamily: "Poppins", overflow: 'hidden', whiteSpace: 'nowrap',textOverflow: 'ellipsis',maxHeight: '80px' }} 
                className={`lg:text-[15px] sm:text-[13px] font-[600] text-[#2D2D2D] leading-[26px] text-black mt-[12px] cursor-pointer`} onClick={() => {
                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth'
                    // });
                    smoothScrolltoTop()
                    localStorage.setItem("spv_id", spvid)
                    localStorage.setItem("brik_id", id)
                    navigate(`/propertydetail/${spvid}/${id}`);
                }}>{title}
                <p className="font-[400]" style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{location}</p></p>
                
                <div className="grid grid-cols-2 gap-[15px] text-black mt-[20px]">
                        {
                            propdata?.map((e,i)=>
                            {
                                return <PropTypes key={i} url={e.image} text={e.text} />
                            })
                        }
                </div>
                {/* <div style={{ fontFamily: "Poppins" }} className="flex justify-between items-center sm:mt-[18px] lg:mt-[12px]"> */}
                <div className="grid grid-cols-2 gap-[15px] mt-[20px]">
                <div style={{ fontFamily: "Poppins" }} className=" text-[#2D2D2D] rounded-lg">
                        <p className="font-[600] sm:text-[15px] lg:text-[17px] leading-[20px] mb-[5px] py-[2px]">From ₹{price}</p>
                        <p className="font-[400] text-[#2D2D2D] sm:text-[12px] lg:text-[14px] leading-[15px]">per night</p>
                    </div>
                <NavButton id="activeeffect" onClick={() => {
                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth'
                    // });
                    smoothScrolltoTop()
                    localStorage.setItem("spv_id",spvid)
                    localStorage.setItem("brik_id",id)
                    GetPropertyDetail(spvid, id,checkin,checkout,adult,children).then((res)=>
                    {
                        navigate(`/propertydetail/${spvid}/${id}`);
                    })
                    .catch((e)=>
                    {
                        toast.error(e?.response?.data?.error)
                    })
                    
                }} name="BOOK NOW" classname="bg-[#C69130] hover:bg-[#976e23] text-white sm:text-[13px] lg:text-[15px] px-[10px] py-[12px] font-[600] leading-[19px] tracking-[2px] rounded-lg"/>
                </div>
            </div>
            
        </div>
    )
}
export default PropCardMain