import {Route,Routes} from "react-router-dom"
import Home from "./Screens/Home/Home"
import Search from "./Screens/Search/Search"
import PropertyDetail from "./Screens/PropertyDetail/PropertyDetail"
import CheckOut from "./Screens/Checkout/Checkout"
import Payment from "./Screens/Payment/Payment"
import Login from "./Screens/Home/Login"
import SignUp from "./Screens/Home/SignUp"
import Completion from "./Screens/Payment/Completion"
import VerifyOTPSent from "./Screens/Home/VerifyOTP"
import MyProfile from "./Screens/MyProfile/MyProfile"
import MyBookings from "./Screens/MyBookings/MyBookings"
import ReviewBooking from "./Screens/ReviewBooking/ReviewBooking"
import NotFound from "./Utils/NotFound"
import PrivateRoute from "./PrivateRoute"
const AllRoutes=()=>
{
    return(
        <>
        <Login />
        <SignUp />
        <VerifyOTPSent />
        <Routes>
            <Route path="/" element={<Home />}>
                {/* <Route path="login" element={<Login />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="verifyotp" element={<VerifyOTPSent />} /> */}
                
            </Route>
            <Route path="/search" element={<Search />}/>
            <Route path="/propertydetail/:spvid/:brikid" element={<PropertyDetail />}/>
            <Route path="/checkout" element={<CheckOut />}/> 
            <Route path="/payment" element={<Payment />}/>
            <Route path="/paymentsuccess" element={<Completion />}/>
            <Route path="/myprofile" element={<PrivateRoute>
                <MyProfile />
            </PrivateRoute>} />
            <Route path="/mybookings" element={<PrivateRoute><MyBookings /></PrivateRoute>} />
            <Route path="/reviewbooking/:id" element={<PrivateRoute><ReviewBooking /></PrivateRoute>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        </>
    )
}
export default AllRoutes