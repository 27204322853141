import { MuiOtpInput } from "mui-one-time-password-input";
import bg from "../../Assets/contourbg.png";
import cal from "../../Assets/Calendar.png";
import NavButton from "../../Utils/SignButton";
import Close from "../../Assets/Close.png";
import BBack from "../../Assets/BBack.png";
import { useContext, useState } from "react";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useFormik,
  useFormikContext,
} from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import UserContext from "../UserContext";
import { LoginUser, VerifyOTP } from "../../Redux/Actions/api";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSessionIdAction,
  SetUserDataAction,
  SetUserTokenAction,
} from "../../Redux/Actions/UserActions";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  phonenumber: yup
    .string()
    .matches(/^\d+$/, "Phone number must only contain digits")
    .max(10, "Phone number must be of 10 digits")
    .min(10, "Phone number must be of 10 digits")
    .required("Phone Number is required"),
});
export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
const Login = () => {
  let spanclass = "font-[600] text-[14px] leading-[24px] cursor-pointer";
  const dispatch = useDispatch();
  const phoneRegex = /^\+?[1-9][0-9]{7,14}$/;

  const navigate = useNavigate();

  const {
    code,
    setcode,
    setopensignup,
    openlogin,
    input,
    setinput,
    setopenlogin,
    setotpdisplay,
  } = useContext(UserContext);

  const initialValues = {
    phonenumber: "",
  };
  let toastId = null; 
  const handleSubmit = debounce((values) => {
    LoginUser({ mobile_number: values?.phonenumber })
      .then((res) => {
        toast.success("OTP sent successfully on Whatsapp");
        dispatch(SetSessionIdAction(res?.session_id));

        setinput(values?.phonenumber);
        // navigate("/verifyotp")
        setopenlogin(false);
        setotpdisplay(true);
      })
      .catch((err) => {
        if (toastId) {
            toast.update(toastId, { render: "User is not registered", type: toast.TYPE.ERROR });
        } else {
            toastId = toast.error("User is not registered", {
                onClose: () => (toastId = null) // Reset toastId when toast is closed
            });
        }
      });
  }, 900);

  const handleNavigate = () => {
    setopenlogin(false);
    setotpdisplay(false);
    setopensignup(true);
    // navigate("/signup")
  };
  return (
    <div className={openlogin ? "after" : "before"}>
      <div
        style={{ fontFamily: "Poppins", display: openlogin ? "flex" : "none" }}
        className="flex-cols justify-center sm:h-full lg:h-auto lg:mt-[150px]"
      >
        <div
          style={{ background: `url(${bg})` }}
          className="sm:hidden lg:block w-[255px] px-[31px] py-[56px] rounded-l-lg"
        >
          <p className="text-[32px] font-[500] leading-[24px] text-white mb-[28px]">
            Login
          </p>
          <p className="text-[16px] font-[400] leading-[24px] text-white mb-[80px]">
            Book our luxuries, Enjoy your vacation
          </p>
          <img src={cal} />
        </div>
        <div className="sm:w-full lg:w-[400px] sm:px-[16px] sm:py-[20px] lg:px-[35px] lg:pt-[100px] lg:pb-[155px] bg-white lg:rounded-r-lg">
          <div style={{ fontFamily: "Inter" }} className="sm:block lg:hidden">
            <div className="">
              <img
                src={BBack}
                onClick={() => {
                  setopenlogin(!openlogin);
                  setotpdisplay(false);

                  // navigate(-1)
                }}
              />
            </div>
            <p className="text-[34px] font-[600] leading-[24px] mt-[63px] text-[#656565] mb-[19px]">
              Login
            </p>
            <p className="text-[16px] font-[400] leading-[24px] text-[#656565] mb-[60px]">
              Book our luxuries, Enjoy your vacation
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="">
                <p className="sm:text-[14px] lg:text-[16px] font-[400] leading-[20px] text-[#14191F] sm:mb-[10px] lg:mb-[15px]">
                  Enter mobile number
                </p>
                <div className="flex items-center gap-[25px] bg-[#F0F0F0]">
                  <select
                    className="bg-[#F0F0F0] p-[15px] text-[16px]"
                    onChange={(e) => setcode(e.target.value)}
                  >
                    <option value="+91">+91</option>
                  </select>
                  <Field
                    type="text"
                    name="phonenumber"
                    className="text-[14px] bg-[#F0F0F0] p-[15px] w-full"
                    placeholder="Enter mobile number"
                  />
                </div>
                <ErrorMessage
                  className="text-[#FF0000] text-[14px] text-center font-[400] leading-[20px]"
                  name="phonenumber"
                  component="div"
                />
                <p className="text-[14px] font-[400] leading-[24px] text-[#59626E] sm:mt-[20px] sm:mb-[50px] lg:my-[30px]">
                  By sign in you agree to the{" "}
                  <span className={spanclass}>
                    <a  target="_blank" rel="noopener noreferrer" href="https://brikkit-prod-docs.s3.amazonaws.com/media/brik/terms_conditions/ownerTERMS__CONDITIONS_FOR_BRIKITT_HOLIDAY_HOME.pdf">Terms & Conditions</a>
                  </span>{" "}
                  and{" "}
                  <span className={spanclass}>
                    <a  target="_blank" rel="noopener noreferrer" href="https://brikitt.com/privacy-policy/">Privacy Policy</a>
                  </span>{" "}
                  of Brikitt
                </p>
                <button
                  type="submit"
                  className="cursor-pointer w-full text-[16px] text-white bg-[#C69130] font-[700] leading-[20px] tracking-[0.8px] px-[40px] py-[13px]"
                >
                  REQUEST OTP ON WHATSAPP
                </button>
                <p
                  onClick={handleNavigate}
                  className="text-[#14191F] text-center text-[14px] font-[400] leading-[19px] sm:mt-[50px] lg:mt-[30px]"
                >
                  Don’t have account yet?
                  <span className="cursor-pointer text-[#C69130] font-[700]">
                    {" "}
                    Signup
                  </span>
                </p>
              </div>
            </Form>
          </Formik>
        </div>

        <div className="sm:hidden lg:block">
          <img
            className="cursor-pointer ml-[20px]"
            src={Close}
            onClick={() => {
              setopenlogin(!openlogin);
              setotpdisplay(false);
              // navigate(-1)
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default Login;
