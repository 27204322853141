
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useContext, useEffect, useRef, useState } from 'react';
import NavButton from '../Utils/SignButton';
import { useDispatch, useSelector } from 'react-redux';
import UserContext from './UserContext';
import { useNavigate } from 'react-router';
import navup from "../Assets/navup.png"
import navdown from "../Assets/navdown.png"
import signout from "../Assets/signout.png"
import mybookings from "../Assets/mybookings.png"
import Avatar from '@mui/material/Avatar';
import { SetSessionIdAction, SetUserDataAction, SetUserTokenAction } from '../Redux/Actions/UserActions';
import { fabClasses } from '@mui/material';
import { toast } from 'react-toastify';

export default function MenuListComposition() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const storedata=useSelector((storedata)=>storedata)
    const name=storedata?.userReducer?.userData?.name
    const{openlogin,setopenlogin}=useContext(UserContext)
    const dispatch=useDispatch()
    const [showText, setShowText] = useState('');

    useEffect(() => {
      setTimeout(() => {
        setShowText(`Hi ${name}`);
      }, 500);
    }, [name]);
    
const navigate=useNavigate()
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleProfileNavigate = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    navigate("/myprofile")
    setOpen(false);
  };

  const handleBookingsNavigate=(event)=>
  {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    navigate("/mybookings")
    setOpen(false);
    
  }
  

  const handleSignOut=(event)=>
  {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
    localStorage.setItem('session_id',"")
    localStorage.setItem('token',"")
    localStorage.removeItem('userData')
    dispatch(SetSessionIdAction(""))
    dispatch(SetUserDataAction({}))
    dispatch(SetUserTokenAction(""))
    setOpen(false)
    navigate("/")
    toast.success("You have successfully logged out")
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleCloseMenu = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    document.addEventListener('mousedown', handleCloseMenu);
    return () => {
      document.removeEventListener('mousedown', handleCloseMenu);
    };
  }, [open]);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
       
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open,anchorRef]);

  return (
    <Stack fontFamily="Inter" direction="row" spacing={2} zIndex={100}>
      
      <div>
        <div className="lg:mr-[44px] cursor-pointer">
            {name?
                <div ref={anchorRef} onClick={handleToggle} className='flex items-center gap-[16px]'>
                    <button 
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true" 
                         className={`hover:bg-[#C69130] w-full text-white text-[16px] tracking-[1px] font-[600] leading-[20px] p-[10px] typewriter-button`}>
                      <span className="typewriter-text capitalize">{showText}</span>
                    
                      </button>
                    <img className="" src={open?navup:navdown}/>
                </div>
                :
                <div ref={anchorRef}>
                    <NavButton  onClick={()=>{
                    setopenlogin(!openlogin)
                    // navigate("/login")
                    }} name="LOGIN/SIGNUP" classname={`font: poppins sm:hidden lg:block hover:bg-[#C69130] text-white text-[16px] tracking-[1px] font-[600] leading-[20px] p-[10px]`} />
                    <NavButton  onClick={()=>{
                    setopenlogin(!openlogin)
                    // navigate("/login")
                    }} name="LOGIN" classname={`sm:block lg:hidden hover:bg-[#C69130] text-white text-[14px] tracking-[1px] font-[600] leading-[20px] p-[10px]`} />
                </div>
            }
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom"
          transition
          disablePortal
          style={{borderRadius:"12px",overflow:'hidden'}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-end' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem>
                        <div onClick={handleProfileNavigate} className='flex items-center gap-[10px]'>
                            <Avatar style={{backgroundColor:"#C69130"}}>{name?.[0]}</Avatar>
                            <div className='flex flex-col gap-[5px]'>
                                <p className='text-[16px] font-[600] text-[#0F0E0A] leading-[20px]'>{name}</p>
                                <p className='text-[14px] font-[400] text-[#656565] leading-[20px]'>View Profile</p>
                            </div>
                        </div>
                    </MenuItem>
                    <MenuItem>
                        <div onClick={handleBookingsNavigate} className='flex items-center gap-[10px]'>
                            <div>
                                <img src={mybookings}></img>
                            </div>
                            <div className='flex flex-col gap-[5px]'>
                                <p className='text-[16px] font-[600] text-[#0F0E0A] leading-[20px]'>My Bookings</p>
                                <p className='text-[14px] font-[400] text-[#656565] leading-[20px]'>View and rate your bookings</p>
                            </div>
                        </div>
                        </MenuItem>
                    <MenuItem >
                      <div onClick={handleSignOut} className='flex items-center gap-[10px]'>
                          <div>
                              <img src={signout}></img>
                          </div>
                          <p className='text-[16px] font-[600] text-[#727272] leading-[20px]'>Signout</p>
                      </div>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}

