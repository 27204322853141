import { Grow, Modal } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useSelector } from "react-redux"
import Rating from '@mui/material/Rating'
import NavButton from "../../Utils/SignButton"
import modalclose from "../../Assets/modalclose.png"
import LeftScroll from "../../Assets/LeftScroll.png"
import RightScroll from "../../Assets/RightScroll.png"
import BBack from "../../Assets/BBack.png"
import { useNavigate } from "react-router"
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useRef } from "react";
import ModalForImageSlideShow from "./ModalforImageSlideShow"
// import { Gallery } from "react-grid-gallery"

const ModalForImages=({zoomimagemodal,setzoomimagemodal})=>
{
    let storedata=useSelector((storedata)=>storedata)
    let {propertydetails}=storedata?.propertyreducer
    let {property_name,average_rating,total_rating,brik_image}=propertydetails
    const navigationPrevRef=useRef(null)
    const navigationNextRef=useRef(null)
    const navigate=useNavigate()
    const[zoomimageslideshowmodal,setzoomimageslideshowmodal]=useState({lg:false,sm:false})
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    // const images = brik_image?.map((e, i) => ({
    //     src: e?.image,
    //     width: 2500 ,
    //     height: 1200 ,
    //   }));
    

    return(
        <div >
            <ModalForImageSlideShow zoomimagemodal={zoomimageslideshowmodal} setzoomimagemodal={setzoomimageslideshowmodal} selectedImageIndex={selectedImageIndex}/>
            <div className="lg:block sm:hidden">
                <Modal style={{ width: "80%", height: "80%", margin: "auto", marginTop:"5%",display:zoomimagemodal["lg"]?"block":"none" }}
                    open={zoomimagemodal["lg"]}
                    onClose={()=>setzoomimagemodal({...zoomimagemodal,lg:false})}  
                >
                    <Box sx={{overflow:"auto", height:"100%", backgroundColor:"white", borderRadius: "10px", 
                    "&::-webkit-scrollbar": {width: "12px",},"&::-webkit-scrollbar-thumb": {backgroundColor: "rgb(193,193,193)",borderRadius: "6px",},}}>
                        <Grow in={zoomimagemodal["lg"]}>
                        <div style={{fontFamily:"Inter"}} className="bg-white px-[70px] py-[50px] w-full h-full">
                            <div className="flex justify-between items-center mb-[70px]">
                                <div>
                                    <p style={{fontFamily:"Playfair Display"}} className="text-[34px] font-[600] leading-[45px] text-[#0C0C0C]">{property_name}</p>
                                    <div className="flex items-center gap-[8px]">
                                        <Rating defaultValue={average_rating} precision={0.5} readOnly />
                                        <p className="text-[20px] font-[400] text-[#656565] underline">{total_rating} {total_rating==1?"review":"reviews"}</p>
                                    </div>
                                </div>
                                <div className="flex gap-[20px]">
                                    <NavButton onClick={()=>navigate("/checkout")} id="activeeffect" name="BOOK NOW" classname="px-[25px] py-[15px] rounded-lg bg-[#C69130] text-white text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#976e23]" />
                                    <div id="activeeffect" onClick={()=>setzoomimagemodal({...zoomimagemodal,lg:false})} className="cursor-pointer flex gap-[8px] items-center border rounded-lg border-[2px] border-[#C69130] px-[32px] py-[15px] bg-white text-[#46484D] text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#C69130]">
                                        <img src={modalclose}></img>
                                        <p>CLOSE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-[20px]">
                                {
                                    brik_image?.map((e,i)=>
                                    {
                                        return(
                                            <div key={i} onClick={() => { setzoomimageslideshowmodal({ ...zoomimageslideshowmodal, lg:true, sm:false });  setSelectedImageIndex(i);}} style={{ cursor: 'pointer' }} >
                                                <img className=" h-[350px] lg:w-[593px] 2xl:w-full mb-[10px]" src={e?.image} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                         {/* <Gallery images={images} enableImageSelection={false}/> */}
                         </div>
                        </Grow>
                    </Box>
                </Modal>
            </div>
            <div className="lg:hidden sm:block">
            <Modal style={{ width: "100%", height: "100%" ,margin: "auto", marginTop:"0%",display:zoomimagemodal["sm"]?"block":"none" }}
                    open={zoomimagemodal["sm"]}
                    onClose={()=>setzoomimagemodal({...zoomimagemodal,sm:false})}  
                >
            
                <Box sx={{height:"100%",backgroundColor:"white"}}>
                    <Grow in={zoomimagemodal["sm"]}>
                    <div className='w-[100%] h-full'>
                                <div id='activeeffect' onClick={()=>setzoomimagemodal({...zoomimagemodal,sm:false})} className='w-full px-[13px] pt-[13px] pb-[110px]'>
                                    <img src={BBack}/>
                                </div>
                                <Swiper 
                                modules={[Pagination, Navigation, Autoplay]}
                                direction='horizontal'
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                    
                                  }}
                                //   pagination={{
                                //     type: 'fraction',
                                //   }}
                                onBeforeInit={(swiper) => {
                                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                                    swiper.params.navigation.nextEl = navigationNextRef.current;
                               }}
                                loop="true"
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}>
                                    {
                                        brik_image?.map((e,i)=>
                                        {
                                            return(
                                                <SwiperSlide className={`relative`} key={i}>
                                                    <div className='h-[375px]'>
                                                        <img className='w-full h-full object-cover' src={e?.image}/>
                                                    </div>
                                                    
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                    <div className='w-full absolute top-[50%] z-10 flex justify-between'>
                                        <div id='activeeffect' className='' ref={navigationPrevRef}><img src={LeftScroll}/></div>
                                        <div id='activeeffect' className='' ref={navigationNextRef}><img src={RightScroll}/></div>
                                    </div>
                                </Swiper>
                                <div className="w-full h-full" onClick={()=>setzoomimagemodal({...zoomimagemodal,sm:false})}>
                                    
                                </div>
                            </div>
                    </Grow>
                </Box>
            </Modal>
            </div>
        </div>
    )
}
export default ModalForImages