import { Box, Grow, Modal } from "@mui/material"
import modalclose from "../../Assets/modalclose.png"
import deleteicon from "../../Assets/delete.svg"
import BBack from "../../Assets/BBack.png"
import { ErrorMessage, Field, Form, Formik, useFormik, useFormikContext } from "formik";
import * as yup from "yup"
import { CancelBookingrequest, updateguestDetails } from "../../Redux/Actions/api";
import { toast } from "react-toastify";
import { useState } from "react";

const schema=yup.object().shape({
    
})

const CancelBookings=({settype,setopencancelbookingmodal,zoomimagemodal,setzoomimagemodal,booking_id,order_id})=>
{
   console.log(booking_id,order_id)
    const reasons=[
        {"value":"Other Destination Preferred","title":"Other Destination Preferred"},
        {"value":"Flight Cancelled","title":"Flight Cancelled"},
        {"value":"Medical Emergency","title":"Medical Emergency"},
        {"value":"Change in Plans","title":"Change in Plans"},
        {"value":"Found Higher Price","title":"Found Higher Price"},
        {"value":"Property not matching my requirements","title":"Property not matching my requirements"},
        {"value":"I don't want to share my IDs","title":"I don't want to share my IDs"},
        {"value":"Location not suitable","title":"Location not suitable"},
        {"value":"Check-In and Check-Out time is not reasonable","title":"Check-In and Check-Out time is not reasonable"},
        {"value":"Less Amenities","title":"Less Amenities"},
    ]
    const[cancelreasons,setcancelreasons]=useState("")
    const initialValues = {
        cancelreasons:"",
        additional:""
    }
    const handleSubmit=(values)=>
    {
        console.log(values)
        if(cancelreasons=="")
        {
            toast.error("Please enter your reason to cancel")
            return
        }
        else
        {
            let body={
                "order_id" : order_id,
                "reason" : cancelreasons,
                "tell_us_more" : values?.additional
            }
            CancelBookingrequest(body,booking_id).then((res)=>
            {
                console.log(res)
                settype("success")
                setzoomimagemodal(false)
                setopencancelbookingmodal(true)
            }).catch((err)=>{
                if(err?.response?.data?.error=="You cannot cancel your booking 5 hours before your check in time.")
                {
                    settype("failure")
                    setzoomimagemodal(false)
                    setopencancelbookingmodal(true)
                    return
                }
                toast.error("Something went wrong")
            })
        }
       
        // let body={
        //     "name" : values?.name,
        //     "phone" : values?.phone,
        //     "email" : values?.email,
        //     "instructions" : values?.notes
        // }
        // updateguestDetails(body,booking_id).then((res)=>{
        //     toast.success("Details updated successfully")
        //     setTimeout(()=>{
        //         setzoomimagemodal(false)
        //     },2000)
        // }).catch((err)=>{
        //     toast.error("Something went wrong")
        // })
    }
    return(
        <Modal className="modalforreview" style={{ margin: "auto",display:zoomimagemodal?"block":"none" }}
        open={zoomimagemodal}
        onClose={()=>setzoomimagemodal(false)}>
            <Box sx={{height:"100%",overflow:"auto",backgroundColor:"white"}}>
            <Grow in={zoomimagemodal}>
            <div style={{fontFamily:"Inter"}} className="sm:px-[9px] sm:py-[11px] lg:px-[70px] lg:py-[50px]">
                    <div className="flex lg:justify-between items-center sm:gap-[8px] lg:gap-[0px] items-center sm:mb-[14px] lg:mb-[40px]">
                        <div onClick={()=>setzoomimagemodal(false)} className="sm:block lg:hidden">
                            <img src={BBack}/>
                        </div>
                        <div>
                            <p style={{fontFamily:"Playfair Display"}} className="sm:text-[20px] lg:text-[34px] font-[600] leading-[45px] text-[#0C0C0C]">Cancel your booking</p>
                            
                        </div>
                        <div id="activeeffect" onClick={()=>setzoomimagemodal(false)} className="sm:hidden cursor-pointer lg:flex gap-[8px] items-center border border-[2px] border-[#C69130] px-[32px] py-[15px] bg-white text-[#46484D] text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#C69130]">
                                <img src={modalclose}></img>
                                <p>CLOSE</p>
                        </div>
                    </div>
                    <hr className="text-[#E6E6E6] sm:mb-[25px] lg:mb-[40px]" />

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                        >
                            <Form style={{fontFamily:"Inter"}} className="sm:text-[14px] lg:text-[16px] leading-[20px] tracking-[3%] font-[400] flex flex-col gap-[20px]">
                                    <div>
                                        <label className="text-[#282827] font-[400] sm:text-[16px] lg:text-[18px]" >Tell us, what is the reason?</label><br/>
                                        <div className="flex flex-wrap gap-x-[9px] gap-y-[15px] mt-[20px]">
                                            {
                                                reasons?.map((e,i)=>
                                                {
                                                    return (
                                                        <div id="reasonactive" onClick={()=>setcancelreasons(e?.value)} className={`${cancelreasons===e.value?"bg-[#b3aeae]":"bg-[#E3E3E3]"} hover:bg-[#b3aeae] text-[14px] font-[400] cursor-pointer text-[17px] px-[20px] py-[5px] rounded-[10px]`} key={i}>
                                                            {e.title}
                                                        </div>
                                                    )
                                                })
                                            }
                                            </div>
                                        
                                    </div>
                                    <div className="bg-white sm:mt-[15px] lg:mt-[30px]">
                                        <Field as="textarea" name="additional" className="sm:text-[14px] lg:text-[16px] bg-[#F5F6FA] p-[13px] w-full mt-[7px]"  placeholder="Tell us more......(Optional)"/>
                                    </div>
                                    <div className="flex gap-[30px] mt-[30px] sm:justify-center lg:justify-end" style={{fontFamily:"Inter"}}>
                                        <button onClick={()=>setzoomimagemodal(false)} id="activeeffect" className="hover:bg-[#976E23] hover:text-white px-[35px] py-[15px] border-2 border-[#C69130] sm:text-[14px] lg:text-[16px] text-[#C69130] font-[700] tracking-[0.8px] leading-[20px]">CANCEL</button>
                                        <button type="submit" id="activeeffect" className="hover:bg-[#976E23] px-[35px] py-[15px] text-white sm:text-[14px] lg:text-[16px] bg-[#C69130] font-[700] tracking-[0.8px] leading-[20px]">SUBMIT</button>
                                    </div>
                            </Form>
                        </Formik>
                    
            </div>
            </Grow>
            </Box>
    </Modal>
    )
}
export default CancelBookings