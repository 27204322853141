import { Box, Fade, Modal } from "@mui/material"
import FeedBack from "../../Assets/FeedBack.svg"
import { useEffect } from "react"
const CancelResponse=({type,zoomimagemodal,setzoomimagemodal})=>
{
    // useEffect(()=>
    // {
    //     const id=setTimeout(() => {
    //         setzoomimagemodal(false)
    //     }, 2000);
    //     return()=>clearTimeout(id)
    // },[zoomimagemodal,setzoomimagemodal])
    return(
        <Modal className="modalforcancel" style={{ margin: "auto", display: zoomimagemodal ? "block" : "none" }} open={zoomimagemodal} onClose={() => setzoomimagemodal(false)}>
        <Box sx={{ height: "100%", backgroundColor: "#FFF9F2", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Fade in={zoomimagemodal}>
            <div style={{ fontFamily: "Playfair Display" }} className="flex flex-col text-center items-center justify-center sm:p-[19px] lg:p-[90px]">
                <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[40px] lg:text-[60px] sm:leading-[54px] lg:leading-[80px] font-[600] lg:mb-[50px] sm:mb-[20px]">{type == "success" ? "Booking Cancelled" : "Sorry!"}</p>
                <p style={{ fontFamily: "Inter" }} className="sm:text-[16px] lg:text-[28px] font-[600] leading-[27px] text-[#565656] mb-[80px]">{type == "success" ? "Your booking has been successfully cancelled" : "You cannot cancel your booking 5 hours before your check-in time."}</p>
                <p style={{ fontFamily: "Inter" }} className="cursor-pointer sm:text-[16px] lg:text-[16px] font-[600] leading-[27px] underline text-[#C69130] mb-[30px]">{type == "success" ? "" : "Check cancellation policy"}</p>
            </div>
            </Fade>
        </Box>
        </Modal>
    )
}
export default CancelResponse