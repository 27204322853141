import http from "../../http"

export const SignUpUser=async(data)=>{
    return http("POST","/booking/signup/",data)
    .then((res)=>{
        return Promise.resolve(res.data)
    }).catch((err)=>
    {
        return Promise.reject(err)
    })
}

export const LoginUser=async(data)=>{
    return http("POST","/booking/login/",data)
    .then((res)=>{
        return Promise.resolve(res.data)
    }).catch((err)=>
    {
        return Promise.reject(err)
    })
}

export const VerifyOTP=async(data)=>
{
    return http("POST","/booking/verify/",data)
    .then((res)=>{
        return Promise.resolve(res.data)
    }).catch((err)=>
    {
        return Promise.reject(err)
    })
}
export const GetAllProperties=async()=>
{
    return http("GET","/booking/get_our_properties/")
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const GetPropertyDetail=async(spvid,brikid,checkin,checkout,adults,children)=>
{
    return http("GET",`/booking/brik_detail/?date_check_in=${checkin}&date_check_out=${checkout}&spv_id=${spvid}&brik_id=${brikid}&adult=${adults}&children=${children}`)
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const GetSearchProperties=async(place,checkin,checkout,children,adults,page)=>
{
    return http("GET",`/booking/booking_search/?q=${place}&date_check_in=${checkin}&date_check_out=${checkout}&children=${children}&adult=${adults}&page=${page}&perPage=4`)
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const GetRatings=async(page,perPage,sort_by_reviews,brik_id,sort_by_date)=>
{
    return http("GET",`/booking/get_booking_reviews/?page=${page}&perPage=${perPage}&sort_by_reviews=${sort_by_reviews}&sort_by_date=${sort_by_date}&brik_id=${brik_id}`)
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}
export const GetRatingsbyDate=async(page,perPage,brik_id,sort_by_date)=>
{
    return http("GET",`/booking/get_booking_reviews/?page=${page}&perPage=${perPage}&sort_by_date=${sort_by_date}&brik_id=${brik_id}`)
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}
export const GetRatingsbyRate=async(page,perPage,sort_by_reviews,brik_id)=>
{
    return http("GET",`/booking/get_booking_reviews/?page=${page}&perPage=${perPage}&sort_by_reviews=${sort_by_reviews}&brik_id=${brik_id}`)
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}
export const addBooking = async (apiBody) => {
    return http("POST", `booking/add_booking_from_portal/`, apiBody, {
        isAuthenticated: true,
    })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((er) => {
            return Promise.reject(er);
        });
};

export const GetUserDetails=async(body)=>{
    return http("GET","/get_booking_portal_user/",body,{
        isAuthenticated:true,
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const UpdateUserDetails=async({body,id,headers})=>{
    console.log(body)
    return http("POST",`/booking_portal_user/?id=${id}`,body,{
        isAuthenticated:true,
        headers
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}
export const getBookings = async (value) => {
    return http("GET", `booking/get_portal_user_bookings/?booking_status=${value}`, {}, {
        isAuthenticated: true,
    })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((er) => {
            return Promise.reject(er);
        });
};

export const getReviewBooking = async (value) => {
    return http("GET", `booking/booking_detail/?booking_id=${value}`, {}, {
        isAuthenticated: true,
    })
        .then((result) => {
            return Promise.resolve(result);
        })
        .catch((er) => {
            return Promise.reject(er);
        });
};

export const writeReview=async({body,headers})=>
{
    return http("POST",'booking/add_booking_review/',body,{
        isAuthenticated: true,
        headers
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const updateReview=async({body,headers})=>
{
    return http("PUT",'booking/add_booking_review/',body,{
        isAuthenticated: true,
        headers
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const updateguestDetails=async(body,booking_id)=>
{
    return http("PUT",`booking/modify_portal_booking/${booking_id}/`,body,{
        isAuthenticated:true
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const CancelBookingrequest = async(body,bookingid)=>
{
    return http("POST",`booking/cancel_portal_booking/${bookingid}/`,body,{
        isAuthenticated:true
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const GetRefundStatus=async(body)=>
{
    return http("POST","booking/refund_status/",body,{
        isAuthenticated:true
    })
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const GetAllCoupons=async(spv_id)=>
{
    return http("GET",`booking/get_all_coupons/?spv_id=${spv_id}`)
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}

export const ValidateCoupon=async(coupon,brikid)=>
{
    return http("GET",`booking/validate_coupon/${coupon}/${brikid}/`)
    .then((res)=>Promise.resolve(res.data))
    .catch((err)=>Promise.reject(err))
}