import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Loading from "../../Utils/Loading"
import NavButton from "../../Utils/SignButton"
import { getBookings } from "../../Redux/Actions/api"
import { toast } from "react-toastify"
import { Rating } from "@mui/material"
import booking_location from "../../Assets/booking_location.svg"
import dateFormat from "dateformat"
import Fade from "react-reveal/Fade"

const Booking = () => {
    const data = [
        { "title": "All", "value": "all" },
        { "title": "Past Bookings", "value": "past" },
        { "title": "Upcoming Bookings", "value": "upcoming" },
        { "title": "Cancelled Bookings", "value": "cancel" }
    ]
    const smalldata = [
        { "title": "All", "value": "all" },
        { "title": "Past", "value": "past" },
        { "title": "Upcoming", "value": "upcoming" },
        { "title": "Cancelled", "value": "cancel" }
    ]
    const [active, setActive] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [bookings, setBookings] = useState([])
    const [value, setValue] = useState("all")
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        getBookings(value).then((result) => {
            const { data } = result;
            console.log('booking data:', data)
            setIsLoading(false)
            setBookings(data.bookings);
        }).catch((e) => {
            setIsLoading(false)
            toast.error(
                e.response?.data?.detail ? e.response.data.detail : "Something went wrong.",
                {
                    variant: "error",
                }
            );
        });
    }, [value]);

    return (
        isLoading ? <Loading /> : 
            <div>
                <div style={{ fontFamily: "Inter" }} className="sticky top-0 z-10 lg:flex sm:hidden justify-around text-[#0C0C0C] text-[15px] font-[400] leading-[20px] bg-white sm:px-[23px] lg:px-[107px] drop-shadow">
                    {
                        data?.map((e, i) => {
                            return <Link key={i} onClick={() => { setActive(i); setValue(e.value) }} className={active === i ? "active" : "inactive"} to={e.to}>{e.title}</Link>
                        })
                    }
                </div>
                <div style={{ fontFamily: "Inter" }} className="sticky top-0 z-10 lg:hidden sm:flex justify-around text-[#0C0C0C] text-[15px] font-[400] leading-[20px] bg-white sm:px-[23px] lg:px-[107px] drop-shadow">
                {
                    smalldata?.map((e, i) => {
                        return <Link key={i} onClick={() => { setActive(i); setValue(e.value) }} className={active === i ? "active" : "inactive"} to={e.to}>{e.title}</Link>
                    })
                }
                </div>
                {
                    bookings?.length === 0 ?
                        <div style={{ boxShadow: "0px 10px 27px 0px rgba(0, 0, 0, 0.15)", fontFamily: "Inter" }} className="bg-[#FFF9F2] text-center lg:my-[40px] sm:my-[20px] sm:px-[16px] sm:py-[50px] lg:px-[100px] lg:pt-[120px] lg:pb-[100px] w-[80%] m-[auto]">
                            <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[46px] lg:text-[60px]  font-[600] sm:leading-[62px] lg:leading-[80px] mb-[35px]">No bookings yet!</p>
                            <NavButton onClick={() => { navigate(`/`)}} name="BOOK NOW" classname="bg-[#C69130] px-[25px] py-[15px] text-white sm:text-[14px] lg:text-[16px] font-[600] leading-[19px] tracking-[1.5px] hover:bg-[#976e23]" />
                        </div>
                        :
                        <div className="bg-[#FFF9F2]">
                            <div className="h-[800px] overflow-y-auto pt-[10px] pb-[90px]">
                            {
                                bookings?.map((e, i) => {
                                    return <Fade>
                                        <div id="mybookings" className="lg:w-[80%] sm:w-[96%] m-[auto] mt-[40px] cursor-pointer" onClick={() => {
                                        localStorage.setItem("booking_id", e.booking_id)
                                        navigate(`/reviewbooking/${e.booking_id}`);
                                    }}>
                                        <div>
                                            <p style={{ fontFamily: "Inter" }} className="sm:text-[13px] lg:text-[16px] font-[600]">Booking Id: {e.booking_id}</p>
                                        </div>
                                        <div style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow:"0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="bg-white p-[16px]">
                                            <div className="flex bg-white items-start gap-[20px]">
                                                <div className="md:w-[15%] sm:w-[45%] lg:h-[150px] sm:h-[100px]">
                                                    <img className="w-full h-full object-cover" src={e.brik_cover_image} />
                                                </div>
                                                <div className="lg:w-[85%] sm:w-full">
                                                    <div className="flex justify-between">
                                                        <div className="lg:w-[60%] sm:w-full mt-[10px]">
                                                            <div>
                                                                <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[18px] lg:text-[28px] text-[#0C0C0C] font-[600] sm:leading-[17px] lg:leading-[10px]">{e.brik_name}</p>
                                                            </div>
                                                            <div className="flex mt-[15px]">
                                                                <img className="h-[13px] mt-[3px]" src={booking_location}/>
                                                                <p style={{ fontFamily: "Inter" }} className="md:block sm:hidden sm:text-[14px] lg:text-[16px] text-[#656565] font-[400] leading-[20px] ml-[4px]">{e.location}</p>
                                                                <p style={{ fontFamily: "Inter" }} className="md:hidden sm:block sm:text-[14px] lg:text-[16px] text-[#656565] font-[400] leading-[20px] ml-[4px]">{e.location.slice(0,20)} ...</p>
                                                            </div>
                                                            <div className="sm:flex lg:hidden gap-[10px] items-center">
                                                                    <Rating size="small" defaultValue={e.average_review} precision={0.5} readOnly />
                                                                    <p style={{ fontFamily: "Jost" }} className="text-[13px] font-[400] text-[#656565] leading-[22px]">{e.total_review} Reviews</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ fontFamily: "Inter" }} className="lg:flex sm:hidden gap-[10px] text-[#0C0C0C] px-[11px] py-[8px]">
                                                                    <p className="font-[600] text-[22px] mb-[6px]">From ₹{e.per_night_inr}</p>
                                                                    <p className="font-[400] text-[18px] text-[#656565]">per night</p>
                                                        </div>
                                                    </div>
                                                    <div className="lg:flex sm:hidden justify-between">
                                                            <div className="w-[40%] mt-[15px]">
                                                                <div className="lg:flex sm:hidden gap-[10px] items-end">
                                                                    <Rating defaultValue={e.average_review} precision={0.5} readOnly />
                                                                    <p style={{ fontFamily: "Jost" }} className="text-[13px] font-[400] text-[#656565] leading-[22px]">{e.total_review} Reviews</p>
                                                                </div>
                                                                <div className="flex gap-[12px] mt-[15px] text-[16px] font-[600]">
                                                                    <div style={{ border: "1px solid #E5E5E5" }} className="text-[#656565] px-[5px] py-[2px]">{e.no_of_days}N/{+e.no_of_days + 1}D</div>
                                                                    <div style={{ border: "1px solid #E5E5E5" }} className="text-[#656565] px-[5px] py-[2px]">{e.adult} Adult</div>
                                                                    <div style={{ border: "1px solid #E5E5E5", color: e.booking_status === 'cancel' ? "#FF5555" : e.is_upcoming ? "#00964E" : "#7A7A7A", backgroundColor: e.booking_status === 'cancel' ? "#ffe0e0" : e.is_upcoming ? "#d1ecdf" : "#e7e7e7" }} className={`px-[5px] py-[2px]`}>{e.booking_status === 'cancel' ? "Cancelled" : e.is_upcoming ? "Upcoming":"Past Booking" }</div>
                                                                </div>
                                                            </div>
                                                            <div style={{ fontFamily: "Inter" }} className="flex gap-[20px] text-[#0C0C0C] items-center text-center">
                                                                <div style={{backgroundColor:e.is_upcoming ? '#FEEDD8' : '#E1E1E1'}} className={`leading-[28px] px-[30px] py-[10px]`}>
                                                                    <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-in date</p>
                                                                    <p className="text-[16px] font-[500]">{dateFormat(e.date_check_in, 'dd mmm yyyy')}</p>
                                                                </div>
                                                                <div style={{backgroundColor:e.is_upcoming ? '#FEEDD8' : '#E1E1E1'}} className={`leading-[28px] px-[30px] py-[10px]`}>
                                                                    <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-out date</p>
                                                                    <p className="text-[16px] font-[500]">{dateFormat(e.date_check_out, 'dd mmm yyyy')}</p>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lg:hidden sm:block">
                                                <div className="flex gap-[12px] mt-[16px] sm:text-[14px] lg:text-[16px] font-[600]">
                                                    <div style={{ border: "1px solid #E5E5E5" }} className="text-[#656565] px-[5px] py-[2px]">{e.no_of_days}N/{+e.no_of_days + 1}D</div>
                                                    <div style={{ border: "1px solid #E5E5E5" }} className="text-[#656565] px-[5px] py-[2px]">{e.adult} Adult</div>
                                                    <div style={{ border: "1px solid #E5E5E5", color: e.booking_status === 'cancel' ? "#FF5555" : e.is_upcoming ? "#00964E" : "#7A7A7A", backgroundColor: e.booking_status === 'cancel' ? "#ffe0e0" : e.is_upcoming ? "#d1ecdf" : "#e7e7e7" }} className={`px-[5px] py-[2px]`}>{e.booking_status === 'cancel' ? "Cancelled" : e.is_upcoming ? "Upcoming":"Past Booking" }</div>
                                                </div>
                                                <div style={{ fontFamily: "Inter" }} className="flex items-center gap-[10px] mt-[21px] text-[#0C0C0C] ">
                                                    <p className="font-[600] lg:text-[22px] sm:text-[16px]">From ₹{e.per_night_inr}</p>
                                                    <p className="font-[400] lg:text-[18px] sm:text-[14px] text-[#656565]">per night</p>
                                                </div>
                                                <div style={{ fontFamily: "Inter" }} className="flex gap-[20px] text-[#0C0C0C] mt-[16px] items-center text-center">
                                                    <div style={{backgroundColor:e.is_upcoming ? '#FEEDD8' : '#E1E1E1'}} className={`leading-[28px] px-[20px] py-[10px]`}>
                                                        <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-in date</p>
                                                        <p className="text-[16px] font-[500]">{dateFormat(e.date_check_in, 'dd mmm yyyy')}</p>
                                                    </div>
                                                    <div style={{backgroundColor:e.is_upcoming ? '#FEEDD8' : '#E1E1E1'}} className={`leading-[28px] px-[20px] py-[10px]`}>
                                                        <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-out date</p>
                                                        <p className="text-[16px] font-[500]">{dateFormat(e.date_check_out, 'dd mmm yyyy')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Fade>
                                })
                            }
                            </div>
                        </div>
                }
            </div>
    )
}
export default Booking