
import TitleBar from "../TitleBar"
import Summary from "./Summary"
import Fade from "react-reveal/Fade"
const Payment=()=>
{
    return(
        <div>
            <Fade>
                <TitleBar text="Payment & contact info"/>
            </Fade>
            <Fade>
                <Summary />
            </Fade>
        </div>
    )
}
export default Payment