import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Stepperactive from "../Assets/Stepperactive.png";


const StepperComp=({steps,activeStep})=>
{
  let color  
  return(
      <div className='grid grid-cols-4'>
        {
        steps?.map((e,i)=>{
          i<=activeStep?color=true:color=false
          
          return(
            <div className='flex flex-col justify-center items-center'>
            <p style={{color:color?"#C69130":"#CACACA"}} className='sm:text-[12px] lg:text-[16px] text-center h-full'>{e.name}</p>
            
            <div className='flex items-center justify-between w-full'>
              <RadioButtonUncheckedIcon sx={{color:color?"#C69130":"#CACACA", width: "15px", height: "15px" }} />
              <hr style={{borderColor:color?"#C69130":"#CACACA"}} className='flex-grow border-2 my-0 mx-2' />
            </div>
          </div>
          )
        })
        }
      </div>
        // <Stepper className='custom' activeStep={activeStep} >
        //     {steps?.map((e,i)=>
        //     {
        //         return (
        //         <Step key={i} sx={{
        //             '& .MuiStepLabel-root .Mui-completed': {
        //                 color: '#C69130', // circle color (COMPLETED)
        //               },
        //               '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
        //                 {
        //                   color: '#C69130', // Just text label (COMPLETED)
        //                 },
        //               '& .MuiStepLabel-root .Mui-active': {
        //                 color: '#C69130', // circle color (ACTIVE)
        //               },
        //               '& .MuiStepLabel-root .Mui-disabled': {
        //                 color: '#CACACA', // circle color (INACTIVE)
        //               },
        //               '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
        //                 {
        //                   color: 'common.white', // Just text label (ACTIVE)
        //                 },
        //               '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
        //                 fill: '#CCCCCC', // circle's number (ACTIVE)
        //               },
        //         }}>
        //             <StepLabel StepIconComponent={e.icon}>{e.name}</StepLabel>
        //         </Step>
        //         )
        //     })}
        // </Stepper>
    )
}
export default StepperComp