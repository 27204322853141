
import TitleBar from "../TitleBar"
import BookingDetail from "./BookingDetail"
import Fade from "react-reveal/Fade"

const ReviewBooking = () => {
    return (
        <div>
            <Fade>
                <TitleBar text="Review booking" />
            </Fade>
            <Fade>
                <BookingDetail />
            </Fade>
        </div>
    )
}
export default ReviewBooking