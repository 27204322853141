import instagram from "../Assets/Instagram.png"
import linkedin from "../Assets/Linkedin.png"
import youtube from "../Assets/Youtube.png"
import pinterest from "../Assets/Pinterest.png"
import facebook from "../Assets/FaceBook.png"
import twitter from "../Assets/Twitter.png"
const Footer=()=>
{
    const logos=[
                {"logo":facebook,"url":"https://www.facebook.com/brikitt"},
                {"logo":instagram,"url":"https://www.instagram.com/brik_itt/"},
                {"logo":twitter,"url":"https://twitter.com/brik_itt"},
                {"logo":linkedin,"url":"https://www.linkedin.com/company/brikitt/"},
                {"logo":youtube,"url":"https://www.youtube.com/channel/UC87MapL09nu9jrH_5atRdQw"}]
                
    // const handleClick = async () => {
    //     console.log('handleClick:')
    //     const apiUrl = 'https://owners.brikitt.com/api/v2/ccavRequestHandler';

    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         body: new URLSearchParams({
    //             merchant_id: '3041703',
    //             order_id: 'order_1_1',
    //             currency: 'INR',
    //             amount: '1.00',
    //             redirect_url: 'https://owners.brikitt.com/api/v1/webhooks/ccav_booking_portal_webhook/',
    //             cancel_url: 'https://brikittrentals.brikitt.com/',
    //             language: 'EN',
    //             billing_name: 'Peter',
    //             billing_address: 'Santacruz',
    //             billing_city: 'Mumbai',
    //             billing_state: 'MH',
    //             billing_zip: '400054',
    //             billing_country: 'India',
    //             billing_tel: '0229874789',
    //             billing_email: 'testing@domain.com',
    //             delivery_name: 'Sam',
    //             delivery_address: 'Vile Parle',
    //             delivery_city: 'Mumbai',
    //             delivery_state: 'Maharashtra',
    //             delivery_zip: '400038',
    //             delivery_country: 'India',
    //             delivery_tel: '0221234321',
    //             merchant_param1: 'sdsadgga',
    //             merchant_param2: 'adsfdsf',
    //             merchant_param3: 'asfdsadf',
    //             merchant_param4: 'your_merchant_param4',
    //             merchant_param5: 'your_merchant_param5',
    //             promo_code: '',
    //             customer_identifier: '',
    //         }),

    //     };

    //     try {
    //         const response = await fetch(apiUrl, requestOptions);
    //         const htmlContent = await response.text();

    //         // Open a new window
    //         const newWindow = window.open('', '_blank');

    //         // Set the document content of the new window
    //         newWindow.document.write(htmlContent);

    //         // Close the document to prevent any interference with the original page
    //         newWindow.document.close();
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    return(
        <div className="sm:block lg:flex flex-row-reverse justify-between items-center sm:px-[80px] sm:py-[23px] lg:px-[160px] lg:py-[25px] bg-[#302413]">
            
            <div className="flex sm:justify-center items-center gap-[27px]">
                {
                    logos?.map((e,i)=>
                    {
                        return(
                            <a className="cursor-pointer" href={e.url} target="blank">
                                <img src={e.logo} key={i} />
                            </a>
                        )
                    })
                }
            </div>
            <p style={{fontFamily:"Poppins"}} className="text-[12px] text-white font-[400] leading-[16px] tracking-[0.5px] sm:mt-[35px] lg:mt-[0px] sm:text-center">© 2023 BRIK itt Proptech Pvt.Ltd.</p>
        </div>
    )
}
export default Footer