import { Box, CircularProgress, Fade, Modal } from "@mui/material"
import Logo from "../Assets/Logo.png"

const Loading=()=>
{
    return(
        <Box
            className="dropin-parent"
            id="drop_in_container"
            sx={
                { width: "100%",
                height: "550px",
                boxShadow:1,
                display:"flex",
                justifyContent: "center",
                alignItems:"center"
            }
            }
            >
            <div className="image-container">
                <img className="" src={Logo} />
            </div>
            {/* <CircularProgress /> */}
        </Box>
    )
}
export default Loading