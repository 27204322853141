import NavbarAll from "../NavbarAll"
import TitleBar from "../TitleBar"
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Summary from "./Summary"
import ContactUs from "../ContactUs"
import Footer from "../Footer"
import StepperComp from "../../Utils/Stepper";
const Completion=()=>
{
    localStorage.removeItem('discount')
    const steps = [
        {"name":'Select rooms',"icon":RadioButtonUncheckedIcon},
        {"name":'Summary & services',"icon":RadioButtonUncheckedIcon},
        {"name":'Contact details',"icon":RadioButtonUncheckedIcon},
        {"name":'Complete',"icon":RadioButtonUncheckedIcon}
      ]
    return(
        <div>
            <TitleBar text="Payment & contact info"/>
            <div style={{fontFamily:"Inter"}} className="sm:px-[16px] sm:py-[20px] lg:px-[100px] lg:pt-[60px] lg:pb-[100px]">
                <StepperComp steps={steps} activeStep={3} />
                <div style={{boxShadow:"0px 10px 27px 0px rgba(0, 0, 0, 0.15)",fontFamily:"Inter"}} className="text-center sm:mt-[50px] lg:mt-[110px] bg-[#FFF9F2] sm:px-[60px] lg:px-[250px] py-[75px]">
                    <p style={{fontFamily:"Playfair Display"}} className="sm:text-[46px] lg:text-[60px] sm:leading-[60px] lg:leading-[80px] font-[600] mb-[50px]">Booking completed </p>
                    <p className="sm:text-[30px] lg:text-[32px] font-[500] leading-[27px] text-[#565656] mb-[30px]">Thank you!</p>
                    <p className="text-[16px] font-[500] leading-[27px] text-[#8F8F8F] ">Your booking details has been sent to the mail.</p>
                </div>
            </div>
        </div>
    )
}
export default Completion