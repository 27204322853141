
import { useContext, useState } from "react"
import Down from "../Assets/Down.svg"
import UserContext from "../Screens/UserContext"
import { useDispatch, useSelector } from "react-redux"
import { CalendarModalAction } from "../Redux/Actions/ModalAction"
import "../Placeselectortemp.css"

const PlaceSelectorTemp=({label,text,gap})=>
{  
    const{place,setplace}=useContext(UserContext)
    const [show , setShow] = useState(false);
    // let optionclass = "px-[10px]"; 
    // const storedata= useSelector((storedata=>storedata))
    const dispatch=useDispatch()
    // console.log(storedata)
    
    const handlechange = (e) => {
        const selectedValue = e.currentTarget.getAttribute("data-value");
        setplace(selectedValue);
        localStorage.setItem("place", selectedValue);
        dispatch(CalendarModalAction(true));
    };   
    
    window.onclick = function (event) {
        var place = document.getElementById('places');
        if (place) {
            if (!event.target.matches('.dropdown_button') && !event.target.matches('.nameofplace')) {
                place.style.display = "none";
            }
        }
    }
     return(
        
        <div style={{fontFamily:"Poppins"}} className='cursor-pointer grow-0 sm:rounded-xl lg:rounded-xl w-full bg-white px-[20px] sm:py-[8px] lg:py-[15px] relative'>
        <p className='text-[#2D2D2D] text-[16px] leading-[27px] text-black font-[600] text-transform: uppercase'>{label}</p>
        <div className="nameofplace" onClick={() => setShow(prev => !prev)}> {place}
             <button className="dropdown_button"    
         style={{ backgroundImage: `url(${Down})`, backgroundRepeat: "no-repeat", backgroundPosition: 'right center', border: "none", outline: "none", boxShadow: "none"
                }} >
                </button> </div>
                {
        show && 
      ( <div id="places" className="place">
      <li  onClick={handlechange} data-value="all"><a>ALL</a></li>
      <li  onClick={handlechange}  data-value="mukteshwar"><a>MUKTESHWAR</a></li>
      <li  onClick={handlechange}  data-value="goa"><a>GOA</a></li>
      </div>)
     }
        {/* </div> */}
    </div>
    // </div>
    )}
    export default PlaceSelectorTemp;