import { useRef } from "react"
import { GET_ALL_PROPERTIES, PROPERTY_DETAIL, RATING, SEARCH_PROPERTIES } from "../Constants"

let initialstate={
    allproperties:[],
    searchproperties:{},
    propertydetails:[],
    ratingdata:[]
}

const PropertyReducer=(state=initialstate,action)=>
{
    switch(action.type)
    {
        case GET_ALL_PROPERTIES:
            return {...state,allproperties:action.payload}
        case SEARCH_PROPERTIES:
            return {...state,searchproperties:action.payload}
        case PROPERTY_DETAIL:
            return {...state,propertydetails:action.payload}
        case RATING:
            return {...state,ratingdata:action.payload}
        default:
            return state
    }
}
export default PropertyReducer