import { useState } from "react"
import Down from "../Assets/Down.svg"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch } from "react-redux";
import { CountModalAction } from "../Redux/Actions/ModalAction";

const InputSelector=({label,text,gap,memberdisplay,setmemberdisplay})=>
{
    const dispatch=useDispatch()
    return(
        <div style={{fontFamily:"Inter"}} onClick={()=>dispatch(CountModalAction(true))} className='sm:rounded-xl lg:rounded-xl w-full grow-0 cursor-pointer bg-white px-[25px] sm:py-[8px] lg:py-[15px]'>
        <p className='text-[#2D2D2D] text-16px leading-[27px] font-[600] text-transform: uppercase '>{label}</p>
        <div className={`flex justify-between items-center gap-[${gap}] mt-[3px]`}>
            <p className='text-[#2D2D2D] text-[13px] leading-[22px] font-[500] tracking-[1.5px]'>{text}</p>
            <img className='cursor-pointer' src={Down} />
        </div>
    </div>
            
            
       
    )
}
export default InputSelector