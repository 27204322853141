const PropTypes=({url,text})=>
{
    return(
        <div style={{fontFamily:"Poppins"}} className="flex items-center gap-[11px] h-[25px]">
            <div className="">
                <img src={url} />
            </div>
            <p className="text-[#656565] text-black sm:text-[12px] lg:text-[14px] font-[400] leading-[29px]">{text.length<=10?text:text.slice(0,9)+"..."}</p>
        </div>
    )
}
export default PropTypes