import Back from "../Assets/Back.png"
import bg from "../Assets/contourbg.png"
import {useNavigate} from "react-router-dom"
import BackButton from "../Utils/BackButton"
const TitleBar=({text})=>
{
    const navigate=useNavigate()
    return(
        <div style={{background:`url(${bg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}} className="sm:px-[16px] lg:px-[100px] pt-[28px] pb-[63px]">
            {/* <div style={{fontFamily:"Inter"}} onClick={()=>navigate(-1)} className="flex items-center gap-[12px] text-white font-[500] text-[17px] leading-[29px] cursor-pointer mb-[46px]">
                <img src={Back} />
                <p>Back</p>
            </div> */}
            <BackButton color="white" url={Back}/>
            <p style={{fontFamily:"Playfair Display",verticalAlign:"center"}} className="w-full sm:text-[40px] lg:text-[76px] text-white font-[550] text-center sm:leading-[40px] lg:leading-[101px]">{text}</p>
        </div>
    )
}
export default TitleBar