import { Box, Grow, Modal } from "@mui/material"
import modalclose from "../../Assets/modalclose.png"
import deleteicon from "../../Assets/delete.svg"
import BBack from "../../Assets/BBack.png"
import { ErrorMessage, Field, Form, Formik, useFormik, useFormikContext } from "formik";
import * as yup from "yup"
import { updateguestDetails } from "../../Redux/Actions/api";
import { toast } from "react-toastify";

const schema=yup.object().shape({
    phone:yup.string()
    .matches(/^\d+$/, 'Phone number must only contain digits')
    .max(10,"Phone number must be of 10 digits")
    .min(10,"Phone number must be of 10 digits")
    .required("Phone Number is required"),

    name:yup.string()
    .matches(/^[a-z ,.'-]+$/i,'Name must not contain special characters')
    .required("Name is required"),

    email:yup.string()
    .required("Email is required")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,'Invalid email'),
    notes:yup.string()
})

const ModifyGuestDetails=({bookingdata,zoomimagemodal,setzoomimagemodal})=>
{
    const{booking_id}=bookingdata
    const{name,phone,email,instructions}=bookingdata?.booking_guest_details
    const initialValues = {
        name: name?name:"",
        phone: phone?phone:"",
        email: email?email:"",
        notes:instructions?instructions:""
    }
    const handleSubmit=(values)=>
    {
        console.log(values)
        let body={
            "name" : values?.name,
            "phone" : values?.phone,
            "email" : values?.email,
            "instructions" : values?.notes
        }
        updateguestDetails(body,booking_id).then((res)=>{
            toast.success("Details updated successfully")
            setTimeout(()=>{
                setzoomimagemodal(false)
            },2000)
        }).catch((err)=>{
            toast.error("Something went wrong")
        })
    }
    return(
        <Modal className="modalforreview" style={{ margin: "auto",display:zoomimagemodal?"block":"none" }}
        open={zoomimagemodal}
        onClose={()=>setzoomimagemodal(false)}>
            <Box sx={{height:"100%",overflow:"auto",backgroundColor:"white"}}>
            <Grow in={zoomimagemodal}>
            <div style={{fontFamily:"Inter"}} className="sm:px-[9px] sm:py-[11px] lg:px-[70px] lg:py-[50px]">
                    <div className="flex lg:justify-between items-center sm:gap-[8px] lg:gap-[0px] items-center sm:mb-[14px] lg:mb-[40px]">
                        <div onClick={()=>setzoomimagemodal(false)} className="sm:block lg:hidden">
                            <img src={BBack}/>
                        </div>
                        <div>
                            <p style={{fontFamily:"Playfair Display"}} className="sm:text-[20px] lg:text-[34px] font-[600] leading-[45px] text-[#0C0C0C]">Modify Guest Details</p>
                            
                        </div>
                        <div id="activeeffect" onClick={()=>setzoomimagemodal(false)} className="sm:hidden cursor-pointer lg:flex gap-[8px] items-center border border-[2px] border-[#C69130] px-[32px] py-[15px] bg-white text-[#46484D] text-[16px] font-[600] leading-[20px] tracking-[1.5px] hover:bg-[#C69130]">
                                <img src={modalclose}></img>
                                <p>CLOSE</p>
                        </div>
                    </div>
                    <hr className="text-[#E6E6E6] sm:mb-[25px] lg:mb-[40px]" />

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                        >
                            <Form style={{fontFamily:"Inter"}} className="sm:text-[14px] lg:text-[16px] leading-[20px] tracking-[3%] font-[400] flex flex-col gap-[20px]">
                                <div className="grid lg:grid-cols-2 sm:grid-cols-1 justify-between gap-x-[103px] gap-y-[40px]">
                                    <div>
                                        <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px]" >FULL NAME<span className="text-[#FF5656]">*</span></label><br/>
                                        <Field type="text" name="name" className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]" placeholder="Enter Full Name" required/>
                                        <ErrorMessage className='text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]' name='name' component="div"/>
                                    </div>
                                    
                                    <div>
                                        <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px]">MOBILE NUMBER<span className="text-[#FF5656]">*</span></label><br/>
                                        <Field type="text" name="phone" className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]" placeholder="Mobile number" required/>
                                        <ErrorMessage className='text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]' name='phone' component="div"/>
                                    </div>

                                    <div>
                                        <label className="text-[#282827] font-[600] sm:text-[12px] lg:text-[14px] " >EMAIL ID<span className="text-[#FF5656]">*</span></label><br/>
                                        <Field type="text" name="email" className="bg-[#F5F6FA] p-[13px] w-full mt-[7px]" placeholder="Enter Email ID" required/>
                                        <ErrorMessage className='text-[#FF0000] sm:text-[12px] lg:text-[14px] text-center font-[400] leading-[20px]' name='email' component="div"/>
                                        <p className="text-[#565656] text-[12px] font-[400] leading-[15px] mt-[9px]">We’ll send your booking information on your mobile & email.</p>
                                    </div>
                                </div>

                                <div className="bg-white sm:mt-[15px] lg:mt-[30px]">
                                    <p style={{fontFamily:"Playfair Display"}} className="mb-[20px] font-[600] sm:text-[18px] lg:text-[22px] leading-[30px] text-[#0C0C0C]">Notes</p>
                                    <p className="font-[600] sm:text-[12px] lg:text-[14px] leading-[17px] text-[#282827]">ADDITIONAL NOTES IF ANY</p>
                                    <Field as="textarea" name="notes" className="sm:text-[14px] lg:text-[16px] bg-[#F5F6FA] p-[13px] w-full mt-[7px]"  placeholder="Enter your notes"/>
                                </div>
                                <div className="flex gap-[30px] mt-[30px] sm:justify-center lg:justify-end" style={{fontFamily:"Inter"}}>
                                    <button onClick={()=>setzoomimagemodal(false)} id="activeeffect" className="hover:bg-[#976E23] hover:text-white px-[35px] py-[15px] border-2 border-[#C69130] sm:text-[14px] lg:text-[16px] text-[#C69130] font-[700] tracking-[0.8px] leading-[20px]">CANCEL</button>
                                    <button type="submit" id="activeeffect" className="hover:bg-[#976E23] px-[35px] py-[15px] text-white sm:text-[14px] lg:text-[16px] bg-[#C69130] font-[700] tracking-[0.8px] leading-[20px]">SUBMIT</button>
                                </div>

                            </Form>
                        </Formik>
                    
            </div>
            </Grow>
            </Box>
    </Modal>
    )
}
export default ModifyGuestDetails