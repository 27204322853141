import { useSelector } from "react-redux";
import PriceCard from "../Utils/PriceCard";
import { useContext, useEffect, useState } from "react";
import UserContext from "./UserContext";
import CouponCard from "../Utils/CouponCard";

const TotalPrice = ({ showCouponCard }) => {
  const storedata = useSelector((storedata) => storedata);
  const { date, count } = useContext(UserContext);
  let checkindate = new Date(date.checkin);
  let checkoutdate = new Date(date.checkout);
  const [GST, setGST] = useState("");
  const diff = checkoutdate - checkindate;
  const differenceInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  const { per_night_inr } = storedata?.propertyreducer?.propertydetails;
  const price = +localStorage.getItem("totalprice");
  const gst = +localStorage.getItem("gst");
  const cgst = +localStorage.getItem("cgst");
  const[discount,setdiscount]=useState(localStorage.getItem('discount')||0)
  const[coupon,setCoupon]=useState(localStorage.getItem('coupon')||0)
  const[couponType,setCouponType]=useState(localStorage.getItem('couponType')||'Amount')

  let totalprice = +localStorage.getItem("grandtotal");
 
  if(couponType === 'Percentage'){
    totalprice = totalprice - (totalprice*(discount/100))
  }
  else{
     totalprice = totalprice - discount
  }
  
  useEffect(() => {
    if (per_night_inr <= 1000) {
      setGST("0%");
    } else if (per_night_inr <= 7500) {
      setGST("6%");
    } else {
      setGST("9%");
    }
  }, []);

  return (
    <div style={{ fontFamily: "Inter" }} className="w-full flex flex-col gap-[20px]">
      <PriceCard title="Sub Total" price={price} font="600" />
      <hr className="my-[10px]" />
      {showCouponCard && (
        <>
          <CouponCard title="Coupons For You" font="600" discount={discount} setdiscount={setdiscount} coupon={coupon} setCoupon={setCoupon} couponType={couponType} setCouponType={setCouponType}/>
          <hr className="my-[10px]" />
        </>
      )}
      <PriceCard title="Total" price={price} font="600" />
      <PriceCard title={`GST ${GST}`} price={gst.toFixed(2)} font="400" />
      <PriceCard title={`CGST ${GST}`} price={cgst.toFixed(2)} font="400" />
      <PriceCard title="Coupon Discount" price={discount} font="400" coupontype={couponType}/>
      <hr className="my-[10px]" />
      <PriceCard title="Grand Total" price={totalprice.toFixed(2)} font="600" />
    </div>
  );
};

export default TotalPrice;
