import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Loading from "../../Utils/Loading"
import NavButton from "../../Utils/SignButton"
import { GetRefundStatus, getReviewBooking } from "../../Redux/Actions/api"
import { toast } from "react-toastify"
import { Rating } from "@mui/material"
import booking_location from "../../Assets/booking_location.svg"
import dateFormat from "dateformat"
import Amenities from "../Amenities"
import Bed from "../../Assets/Bed.png"
import Guest from "../../Assets/Guest.png"
import Forest from "../../Assets/Forest.png"
import Area from "../../Assets/Area.png"
import Get_location from "../../Assets/Get_location.svg"
import Share from "../../Assets/Share.svg"
import Contact from "../../Assets/Contact.svg"
import Download_invoice from "../../Assets/Download_invoice.svg"
import Right_vector from "../../Assets/Right_vector.svg"
import Write_review from "../../Assets/Write_review.svg"
import Right from "../../Assets/Right.svg"
import Cancel from "../../Assets/Cancel.svg"
import Rules from "../../Assets/Rules.svg"
import Modify from "../../Assets/Modify.svg"
import Cancel_policy from "../../Assets/Cancel_policy.svg"
import Hand from "../../Assets/Hand.svg"
import EditReview from "../../Assets/EditReview.svg"
import ReviewModal from "./ReviewModal"
import ReviewSuccessModal from "./ReviewSuccessModal"
import ReviewCard from "../PropertyDetail/ReviewCard"
import ReviewModalUpdate from "./ReviewModalUpdate"
import ReviewSuccessModalUpdate from "./ReviewSuccessModalforUpdate"
import ModifyGuestDetails from "./ModifyGuestDetails"
import CancelBookings from "./CancelBooking"
import CancelResponse from "./CancelledBookingResModal"



const BookingDetail = () => {
    const [active, setActive] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [booking, setBooking] = useState({})
    const[gst,setgst]=useState("")
    const {id}=useParams()
    const [value, setValue] = useState(id)
    const navigate = useNavigate()
    const[submitreview,setsubmitreview]=useState(false)
    const [openreviewmodal,setopenreviewmodal]=useState(false)
    const[opencancelbookingmodal,setopencancelbookingmodal]=useState(false)
    const[opencancelrespmodal,setopencancelrespmodal]=useState(false)
    const[opensuccessmodal,setopensuccessmodal]=useState(false)
    const[opensuccessmodalupdate,setopensuccessmodalupdate]=useState(false)
    const[modifybookingsmodal,setmodifybookingsmodal]=useState(false)
    const [truncatedtext,settruncatedtext]=useState("")
    const[type,settype]=useState("")
    const[refunddata,setrefunddata]=useState({})
    const handleopenreviewmodal=()=>
    {
        setopenreviewmodal(true)
    }
    const handleshareclick=async()=>
    {
        try {
            if(navigator.share)
            {
                await navigator.share({
                    title:"Your Booking Details",
                    text:`Booking Id: ${booking?.booking_id}\nGuest Name: ${booking?.booking_guest_details.name}\nMobile Number: ${booking?.booking_guest_details.phone}\nEmail Address: ${booking?.booking_guest_details.email}\nAdditional Notes: ${booking?.booking_guest_details.instructions || "--"}\nCheck-in Date: ${dateFormat(booking?.date_check_in, 'dd mmm yyyy')}\nCheck-out Date: ${dateFormat(booking?.date_check_out, 'dd mmm yyyy')}\nNo. of Nights/Days: ${booking?.no_of_days}N/${+booking?.no_of_days + 1}D\nAdults: ${booking?.adult}`
                })
            }
        } catch (error) {
            toast.error("Something went wrong")
        }
    }
    useEffect(() => {
        
        setIsLoading(true)
        getReviewBooking(value).then((result) => {
            const { data } = result;
            console.log('review booking data:', data)
            setIsLoading(false)
            setBooking(data);
            let totalprice=data?.brik_detail?.per_night_inr
        
                if(totalprice<=1000)
                {
                    setgst("0%")
                }
                else if(totalprice<=7500)
                {
                    setgst("6%")
                }
                else
                {
                    setgst("9%")
                }
            if(data?.booking_guest_details?.instructions)
            {
                if(data?.booking_guest_details?.instructions?.length>200)
                {
                    settruncatedtext(data?.booking_guest_details?.instructions.slice(0,200)+" ...")
                }
                else
                {
                    settruncatedtext(data?.booking_guest_details?.instructions)
                }
            }
            else
            {
                settruncatedtext("--")
            }
            if(data?.booking_status=="cancel")
            {
                let body={
                    "refund_id" : data?.refund_id,
                    "order_id" : data?.order_id
                }
                GetRefundStatus(body).then((res)=>
                {
                    console.log(res)
                    setrefunddata(res)
                }).catch((err)=>toast.error(err?.response?.data?.error))
            }
            
        }).catch((e) => {
            setIsLoading(false)
            toast.error(
                e.response?.data?.detail ? e.response.data.detail : "Something went wrong.",
                {
                    variant: "error",
                }
            );
        });

        
    }, [value,opensuccessmodal,modifybookingsmodal,opencancelrespmodal]);

    return (
        isLoading ? <Loading /> :
        <div>
            {
                booking?.booking_status=="cancel"?
                <div>
                    {
                    refunddata?.refund_status=="SUCCESS"?
                    <div className="py-[11px] sm:px-[19px] lg:px-[100px] bg-[#A4FFD3] flex justify-between lg:gap-[0px] sm:gap-[20px] items-center">
                        <p style={{fontFamily:"Inter"}} className="sm:text-[14px] lg:text-[18px] text-[#0C0C0C] italic font-[600] leading-[22px]">Your refund has been credited on {dateFormat(refunddata?.processed_at,"dd mmmm yyyy")}</p>
                        {/* <button id="activeeffect" className="cursor-pointer bg-white p-[8px]">Need help?</button> */}
                    </div>
                    :
                    <div className="py-[11px] sm:px-[19px] lg:px-[100px] bg-[#DBB778] flex justify-between lg:gap-[0px] sm:gap-[20px] items-center">
                        <p style={{fontFamily:"Inter"}} className="sm:text-[14px] lg:text-[18px] text-[#0C0C0C] italic font-[600] leading-[22px]">Your refund is in process it will take 2-3 working business days.</p>
                        <button id="activeeffect" className="cursor-pointer bg-white p-[8px]">Need help?</button>
                    </div>
                    }
                </div>:
                <></>
            }
            <div className="lg:py-[40px] sm:py-[20px] bg-[#FFF9F2]">
                <div className="lg:w-[80%] sm:w-[96%] m-[auto]">
                    <div style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="flex lg:flex-row sm:flex-col items-start bg-white gap-[20px] p-[16px]">
                        <div className="lg:w-[15%] lg:h-[180px] sm:w-[60%]">
                            <img className="w-full h-full object-cover" src={booking?.brik_detail?.brik_cover_image} />
                        </div>
                        <div className="lg:w-[85%] sm:w-full">
                            <div className="flex justify-between">
                                <div className="lg:w-[60%] sm:w-full">
                                    <div>
                                        <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[26px] lg:text-[28px] text-[#0C0C0C] font-[600] sm:leading-[32px] lg:leading-[20px]">{booking?.brik_detail?.brik_name}</p>
                                    </div>
                                    <div className="flex mt-[15px]">
                                        <img className="h-[13px] mt-[3px]" src={booking_location} />
                                        <p style={{ fontFamily: "Inter" }} className="sm:text-[14px] lg:text-[16px] text-[#656565] font-[400] leading-[20px] ml-[4px]">{booking?.brik_detail?.location}</p>
                                    </div>
                                </div>
                                <div className="lg:flex sm:hidden gap-[10px] items-start">
                                    <Rating defaultValue={booking?.brik_detail?.average_review} precision={0.5} readOnly />
                                    <p style={{ fontFamily: "Jost" }} className="text-[13px] font-[400] text-[#656565] leading-[22px]">{booking?.brik_detail?.total_review} Reviews</p>
                                </div>
                            </div>
                            <ReviewModal bookingdata={booking} setopensuccessmodalupdate={setopensuccessmodalupdate} setopensuccessmodal={setopensuccessmodal} setsubmitreview={setsubmitreview} submitreview={submitreview} booking_id={booking?.booking_id} zoomimagemodal={openreviewmodal} setzoomimagemodal={setopenreviewmodal}/>
                            {/* <ReviewModalUpdate /> */}
                            <ModifyGuestDetails bookingdata={booking} zoomimagemodal={modifybookingsmodal} setzoomimagemodal={setmodifybookingsmodal} />
                            <CancelBookings settype={settype} setopencancelbookingmodal={setopencancelrespmodal} booking_id={booking?.booking_id} order_id={booking?.order_id} zoomimagemodal={opencancelbookingmodal} setzoomimagemodal={setopencancelbookingmodal} />
                            <CancelResponse type={type} setzoomimagemodal={setopencancelrespmodal} zoomimagemodal={opencancelrespmodal} />
                            <ReviewSuccessModalUpdate setzoomimagemodal={setopensuccessmodalupdate} zoomimagemodal={opensuccessmodalupdate} />
                            <ReviewSuccessModal setzoomimagemodal={setopensuccessmodal} zoomimagemodal={opensuccessmodal} />
                            <div className="flex lg:flex-row sm:flex-col justify-between">
                                <div className="lg:w-[40%] sm:w-full mt-[20px]">
                                    <div className="flex gap-[20px]">
                                        <div style={{fontFamily:"Inter"}} className="w-[50%] flex items-center gap-[11px]">
                                            <img src={Bed} />
                                            <p className="text-[#848484] text-black text-[17px] font-[500] leading-[29px]">{booking?.brik_detail?.bedroom} Single Beds</p>
                                        </div>
                                        <div style={{fontFamily:"Inter"}} className="flex items-center gap-[11px]">
                                            <img src={Guest} />
                                            <p className="text-[#848484] text-black text-[17px] font-[500] leading-[29px]">{booking?.adult} Guests</p>
                                        </div>
                                    </div>
                                    <div className="flex gap-[20px] mt-[20px]">
                                        <div style={{fontFamily:"Inter"}} className="w-[50%] flex items-center gap-[11px]">
                                            <img src={Area} />
                                            <p className="text-[#848484] text-black text-[17px] font-[500] leading-[29px]">{booking?.brik_detail?.property_area} sqft.room</p>
                                        </div>
                                        <div style={{fontFamily:"Inter"}} className="flex items-center gap-[11px]">
                                            <img src={Forest} />
                                            <p className="text-[#848484] text-black text-[17px] font-[500] leading-[29px]">{booking?.brik_detail?.property_view}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:flex lg:hidden gap-[10px] mt-[25px] items-start">
                                    <Rating size="small" defaultValue={booking?.brik_detail?.average_review} precision={0.5} readOnly />
                                    <p style={{ fontFamily: "Jost" }} className="text-[13px] font-[400] text-[#656565] leading-[22px]">{booking?.brik_detail?.total_review} Reviews</p>
                                </div>
                                <div className="flex-col">
                                    <div className="lg:block sm:hidden">
                                    {
                                    booking?.review_allowed?.status && !booking?.is_upcoming ? (
                                        <div
                                        onClick={handleopenreviewmodal}
                                        className="text-center px-[15px] py-[12px] text-[#FFF] bg-[#2D9CDB] cursor-pointer"
                                        >
                                        <p className="text-[16px] font-[700]">WRITE A REVIEW</p>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                    }
                                    </div>
                                    <div style={{ fontFamily: "Inter", marginTop: booking?.review_allowed?.status ? "20px":"50px" }} className="lg:flex sm:hidden gap-[10px] items-center text-[#0C0C0C] px-[11px] py-[8px]">
                                        <p className="font-[600] text-[22px] mb-[-4px]">From ₹{booking?.brik_detail?.per_night_inr}</p>
                                        <p className="font-[400] text-[18px] text-[#656565]">per night</p>
                                    </div>
                                    <div style={{ fontFamily: "Inter", marginTop: booking?.review_allowed?.status ? "20px":"0px" }} className="sm:flex lg:hidden items-center gap-[10px] text-[#0C0C0C] py-[8px]">
                                        <p className="font-[600] text-[18px]">From ₹{booking?.brik_detail?.per_night_inr}</p>
                                        <p className="font-[400] text-[18px] text-[#656565]">per night</p>
                                    </div>
                                    <div className="lg:hidden sm:block">
                                        {booking?.review_allowed?.status && !booking?.is_upcoming ?(
                                        <div onClick={handleopenreviewmodal} className={`text-center px-[15px] py-[12px] text-[#FFF] bg-[#2D9CDB] cursor-pointer`}>
                                            <p className="text-[16px] font-[700]">WRITE A REVIEW</p>
                                        </div>) : (
                                        <></>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:w-[80%] sm:w-[96%] m-[auto] lg:mt-[40px] sm:mt-[30px]">
                    <div>
                        <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[24px] lg:text-[25px] font-[600]">Booking Details</p>
                    </div>
                    <div style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="bg-white sm:px-[15px] lg:px-[25px] py-[15px] mt-[20px]">
                        <div>
                            <div style={{ borderBottom: '1px solid #E6E6E6'}} className="flex justify-between items-center pb-[10px]"> 
                                <div>
                                    <p style={{ fontFamily: "Inter" }} className="sm:text-[18px] lg:text-[20px] font-[600]">Booking Id:</p>
                                    <p style={{ fontFamily: "Inter" }} className="sm:text-[14px] lg:text-[16px]">{booking?.booking_id}</p>
                                    <p style={{ fontFamily: "Inter" }} className="lg:hidden sm:block text-[14px] text-[#656565]">Booked on {dateFormat(booking?.booked_on, 'dd mmm yyyy')}</p>
                                </div>
                                <div style={{ border: "1px solid #E5E5E5", color: booking?.booking_status === 'cancel' ? "#FF5555" : booking?.is_upcoming ? "#00964E" : "#7A7A7A", backgroundColor: booking?.booking_status === 'cancel' ? "#ffe0e0" : booking?.is_upcoming ? "#d1ecdf" : "#e7e7e7" }} className={`px-[10px] py-[5px] font-[600] sm;text-[14px] lg:text-[16px]`}>{booking?.booking_status === 'cancel' ? "Cancelled" : booking?.is_upcoming ? "Upcoming" : "Past Booking"}</div>
                                <div>
                                    <p style={{ fontFamily: "Inter" }} className="lg:block sm:hidden text-[16px] text-[#656565]">Booked on {dateFormat(booking?.booked_on, 'dd mmm yyyy')}</p>
                                </div>
                            </div>
                            <div className="flex lg:flex-row sm:flex-col justify-between lg:items-center pb-[10px] mt-[20px]">
                                <div className="">
                                    <div>
                                        <p style={{ fontFamily: "Inter" }} className="sm:text-[13px] lg:text-[15px] text-[#848484]">Guest Name</p>
                                        <p style={{ fontFamily: "Inter" }} className="sm:text-[14px] lg:text-[16px] font-[500]">{booking?.booking_guest_details?.name}</p>
                                    </div>
                                    <div className="mt-[20px]">
                                        <p style={{ fontFamily: "Inter" }} className="sm:text-[13px] lg:text-[15px] text-[#848484]">Mobile Number</p>
                                        <p style={{ fontFamily: "Inter" }} className="sm:text-[14px] lg:text-[16px] font-[500]">{booking?.booking_guest_details?.phone}</p>
                                    </div>
                                    <div className="mt-[20px]">
                                        <p style={{ fontFamily: "Inter" }} className="text-[15px] text-[#848484]">Email Address</p>
                                        <p style={{ fontFamily: "Inter" }} className="text-[16px] font-[500]">{booking?.booking_guest_details?.email}</p>
                                    </div>
                                    <div className="lg:hidden sm: block mt-[20px]">
                                        <p style={{ fontFamily: "Inter" }} className="text-[15px] text-[#848484]">Additional Notes</p>
                                        <p style={{ fontFamily: "Inter" }} className="text-[16px] font-[400]">{booking?.booking_guest_details?.instructions}</p>
                                    </div>
                                </div>
                                <div className="">
                                    <div style={{ fontFamily: "Inter" }} className="grid grid-cols-2 sm:gap-[13px] lg:gap-x-[40px] lg:gap-y-[20px] text-[#0C0C0C] sm:mt-[20px] lg:mt-[0px] items-center text-center">
                                        <div className={`leading-[28px] sm:px-[15px] lg:px-[30px] py-[10px] bg-[#E1E1E1]`}>
                                            <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-in date</p>
                                            <p className="text-[16px] font-[500]">{dateFormat(booking?.date_check_in, 'dd mmm yyyy')}</p>
                                        </div>
                                        <div className={`leading-[28px] sm:px-[15px] lg:px-[30px] py-[10px] bg-[#E1E1E1]`}>
                                            <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-in Time</p>
                                            <p className="text-[16px] font-[500]">12:00 PM</p>
                                        </div>
                                        <div className={`leading-[28px] sm:px-[15px] lg:px-[30px] py-[10px] bg-[#E1E1E1]`}>
                                            <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-out date</p>
                                            <p className="text-[16px] font-[500]">{dateFormat(booking?.date_check_out, 'dd mmm yyyy')}</p>
                                        </div>
                                        <div className={`leading-[28px] sm:px-[15px] lg:px-[30px] py-[10px] bg-[#E1E1E1]`}>
                                            <p className="text-[14px] font-[400] text-[#656565] leading-[17px] tracking-[0.35px]">Check-out Time</p>
                                            <p className="text-[16px] font-[500]">11:00 AM</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-[12px] sm:mt-[20px] sm:text-[14px] lg:text-[16px] font-[600]">
                                    <div style={{ border: "1px solid #E5E5E5" }} className="text-[#656565] px-[5px] py-[2px]">{booking?.no_of_days}N/{+booking?.no_of_days + 1}D</div>
                                    <div style={{ border: "1px solid #E5E5E5" }} className="text-[#656565] px-[5px] py-[2px]">{booking?.adult} Adult</div>
                                </div>
                            </div>
                            <div className="lg:block sm:hidden mt-[20px]">
                                <p style={{ fontFamily: "Inter" }} className="text-[15px] text-[#848484]">Additional Notes</p>
                                <p style={{ fontFamily: "Inter" }} className="text-[16px] font-[400]">{booking?.booking_guest_details?.instructions}</p>
                            </div>
                            <div className="flex justify-between text-[16px] text-[#46484D] s:mt-[27px] lg:mt-[70px]">
                                <a id="activeeffect" href={`http://maps.google.com/?q=${booking?.brik_detail?.brik_name}, ${booking?.brik_detail?.location}`} style={{ border: "2px solid #C69130"}} className={`w-[23%] flex items-center leading-[28px] px-[15px] py-[20px] gap-[5px] justify-center`}>
                                    <img src={ Get_location } className="lg:w-[24px] lg:h-[24px] sm:w-[20px] sm:h-[20px]" alt="logo"/>
                                    <p className="lg:block sm:hidden text-[16px] font-[500]">Get Direction</p>
                                </a>
                                <div id="activeeffect" onClick={handleshareclick} style={{ border: "2px solid #C69130" }} className={`cursor-pointer w-[23%] flex items-center leading-[28px] px-[15px] py-[20px] gap-[5px] justify-center`}>
                                    <img src={Share} className="lg:w-[24px] lg:h-[24px] sm:w-[20px] sm:h-[20px]" alt="logo" />
                                    <p className="lg:block sm:hidden text-[16px] font-[500]">Share</p>
                                </div>
                                <a id="activeeffect" href="tel:+#########" style={{ border: "2px solid #C69130" }} className={`cursor-pointer w-[23%] flex items-center leading-[28px] px-[15px] py-[20px] gap-[5px] justify-center`}>
                                    <img src={Contact} className="lg:w-[24px] lg:h-[24px] sm:w-[20px] sm:h-[20px]" alt="logo" />
                                    <p className="lg:block sm:hidden text-[16px] font-[500]">Contact</p>
                                </a>
                                <a id="activeeffect"  href={booking?.invoice} target="blank" style={{ border: "2px solid #C69130",visibility:booking?.invoice!=""?"flex":"hidden" }} className={`w-[23%] flex items-center leading-[28px] px-[15px] py-[20px] gap-[5px] justify-center`}>
                                    <img src={Download_invoice} className="lg:w-[24px] lg:h-[24px] sm:w-[20px] sm:h-[20px]" alt="logo" />
                                    <p className="lg:block sm:hidden text-[16px] font-[500]">Download Invoice</p>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:w-[80%] sm:w-[96%] m-[auto] mt-[40px] flex lg:flex-row sm:flex-col gap-[40px]">
                    <div className="sm:w-full lg:w-[50%]">
                        <div>
                            <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[24px] lg:text-[25px] font-[600]">Payment Details</p>
                        </div>
                        <div style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="sm:px-[15px] lg:px-[25px] py-[15px] mt-[20px] bg-white">
                            <div style={{ fontFamily: "Inter" }} className="flex justify-between sm:text-[14px] lg:text-[16px] leading-[19px] tracking-[1.5px] mt-[15px]">
                                <p className={`text-[#0C0C0C] font-[600]`}>Property Charges/Night</p>
                                <p className="text-[#8F8F8F]">₹{booking?.brik_detail?.per_night_inr}</p>
                            </div>
                            <div style={{ fontFamily: "Inter" }} className="flex justify-between sm:text-[14px] lg:text-[16px] leading-[19px] tracking-[1.5px] sm:mt-[20px] lg:mt-[30px]">
                                <p className={`text-[#0C0C0C] font-[600]`}>GST ({gst})</p>
                                <p className="text-[#8F8F8F]">₹{booking?.gst_on_total_payable_inr}</p>
                            </div>
                            <div style={{ fontFamily: "Inter" }} className="flex justify-between sm:text-[14px] lg:text-[16px] leading-[19px] tracking-[1.5px] sm:mt-[20px] lg:mt-[30px]">
                                <p className={`text-[#0C0C0C] font-[600]`}>CGST ({gst})</p>
                                <p className="text-[#8F8F8F]">₹{booking?.cgst_on_total_payable_inr}</p>
                            </div>
                            <div style={{ fontFamily: "Inter" }} className="flex justify-between sm:text-[20px] lg:text-[24px] leading-[19px] tracking-[1.5px] sm:mt-[20px] lg:mt-[30px]">
                                <p className={`text-[#0C0C0C] font-[600]`}>Grand Total</p>
                                <p className="text-[#0C0C0C] text-[16px]">₹{(+booking?.grand_total).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-full lg:w-[50%]">
                        {booking?.booking_status === 'cancel' ? <></> : booking?.is_upcoming ? <>
                            <div>
                                <p style={{ fontFamily: "Playfair Display" }} className="text-[25px] font-[600]">Manage Your Booking</p>
                            </div>
                            <div onClick={()=>setopencancelbookingmodal(true)} style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="px-[21px] py-[15px] mt-[20px] bg-[#E1F0FF]">
                                <div className="flex justify-between cursor-pointer">
                                    <div className="flex gap-[15px]">
                                        <img src={Cancel} />
                                        <p className="sm:text-[14px] lg:text-[16px] font-[600] text-[#565656]">Cancel Your Booking</p>
                                    </div>
                                    <img src={Right}/>
                                </div>
                            </div>
                            <div onClick={()=>setmodifybookingsmodal(true)} style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="px-[21px] py-[15px] bg-[#E1F0FF]">
                                <div className="flex justify-between cursor-pointer">
                                    <div className="flex gap-[15px]">
                                        <img src={Modify} />
                                        <p className="sm:text-[14px] lg:text-[16px] font-[600] text-[#565656]">Modify Guest Details</p>
                                    </div>
                                    <img src={Right} />
                                </div>
                            </div>
                            <div style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="px-[21px] py-[15px] mt-[20px]">
                                <div className="flex justify-between cursor-pointer">
                                    <div className="flex gap-[15px]">
                                        <img src={Cancel_policy} />
                                        <div>
                                            <p className="sm:text-[14px] lg:text-[16px] font-[600] text-[#656565]">Cancellation Policy</p>
                                            <p className="sm:text-[13px] lg:text-[16px] text-[#FF4D4D]">Free cancellation is available till 5 hours before the check in <span className="text-[#0C0C0C] cursor-pointer text-[14px] italic font-[600]"><a target="blank" href="https://brikkit-prod-docs.s3.amazonaws.com/media/brik/cancellation_policy/ownercancellation_policy_2.pdf">read more</a></span></p>
                                        </div>
                                    </div>
                                    <img src={Right} />
                                </div>
                            </div>
                            <div style={{ fontFamily: "Inter", border: "1px solid #E5E5E5", boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="px-[21px] py-[15px] mt-[20px]">
                                <div className="flex justify-between cursor-pointer">
                                    <div className="flex gap-[15px]">
                                        <img src={Rules} />
                                        <a target="blank" href={booking.house_rule} className="sm:text-[14px] lg:text-[16px] font-[600] text-[#656565]">Property policies & rules</a>
                                    </div>
                                    <img src={Right} />
                                </div>
                            </div>
                        </> : booking?.review_allowed?.status ? <>
                                <div style={{ boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }} className="bg-[#2D9CDB] sm:mt-[0px] lg:mt-[57.5px] text-[#FFF]">
                                    <div onClick={handleopenreviewmodal} className="flex justify-between p-[15px] cursor-pointer">
                                        <div className="flex gap-[15px] ml-[15px]">
                                            <img src={ Write_review }/>
                                            <div>
                                                <p style={{ fontFamily: "Playfair Display" }} className="sm:text-[20px] lg:text-[25px] font-[600]">Write A Review</p>
                                                <p style={{ fontFamily: "Inter" }} className="text-[14px] font-[500]">Share Your Experience</p>
                                            </div>
                                        </div>
                                        <img src={ Right_vector } className="mr-[15px]"/>
                                    </div>
                                </div>
                        </> : 
                            <div className="bg-[#8F714C] px-[16px] py-[13px] lg:mt-[60px]" style={{ boxShadow: "0px 8px 25px 0px rgba(83, 89, 144, 0.07)" }}>
                                <div className="flex justify-between items-start mb-[28px]">
                                    <div className="flex gap-[10px] items-start justify-evenly">
                                        <div>
                                            <img src={Hand} />
                                        </div>
                                        <div>
                                            <p style={{fontFamily:"Playfair Display"}} className="sm:text-[20px] lg:text-[25px] text-white leading-[30px] font-[600] tracking-[1px] ">View/Edit Review</p>
                                            <p className="text-[14px] text-white leading-[30px] font-[400] ">Thank you for Sharing your Experience</p>
                                        </div>
                                    </div>
                                    <div onClick={handleopenreviewmodal} className="cursor-pointer" id="activeeffect">
                                        <img src={EditReview} />
                                    </div>
                                </div>
                                
                                <div className="h-[100px] overflow-auto">
                                    <ReviewCard description={booking?.review_allowed?.review?.review} 
                                    name={booking?.review_allowed?.review?.name} 
                                    date={booking?.review_allowed?.review?.date}
                                    rating={booking?.review_allowed?.review?.rating}
                                    imagedata={booking?.review_allowed?.review?.images}
                                    type="Review" />
                                </div>
                                
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BookingDetail