import mail from "./mail.png"
import phone from "./phone.png"
import carkey from "./Carkey.png"
import food from "./Food.png"
import spa from "./Spa.png"

let staticdata=[
    {
        "navbardata":[
            {"url":phone,"text":"+91-634-567-34 "},
            {"url":mail,"text":"Book@Hotale.co"}
        ]
    },
    {
        "navbuttons":[
            {"name":"LOGIN","classname":""}
        ]
    },
    {
        "addons":[
            {"url":carkey,"title":"Car Rental","text":"Our team will assist you for the cab services contact details & also about nearby Sightseeing options in the location.","highlighter":"The Keys to a Seamless Rental Experience"},
            {"url":food,"title":"Food Recommendations","text":`We deliver happiness. You can place your order to our service team. Multi cuisine menu is served from local delicacies & global cuisines from the restaurant. Also our team will guide you for the local restaurant & cafes.`,"highlighter":"Your Favorite Food delivery Partner"},
            {"url":spa,"title":"The Relax Spa","text":"Our team will assist you for the spa services contact details & best spa options in the location.","highlighter":"Taking relaxation to the next level"},
            {"url":carkey,"title":"Car Rental","text":"Our team will assist you for the cab services contact details & also about nearby Sightseeing options in the location.","highlighter":"The Keys to a Seamless Rental Experience"},
            {"url":food,"title":"Food Recommendations","text":`We deliver happiness. You can place your order to our service team. Multi cuisine menu is served from local delicacies & global cuisines from the restaurant. Also our team will guide you for the local restaurant & cafes.`,"highlighter":"Your Favorite Food delivery Partner"},
            {"url":spa,"title":"The Relax Spa","text":"Our team will assist you for the spa services contact details & best spa options in the location.","highlighter":"Taking relaxation to the next level"},
        ]
    }
]
    

export default staticdata